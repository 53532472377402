import React from "react";
import { Link } from "react-router-dom";


const PrivacyData = () => {
  return (
    <main className="main">
      {/* eslint-disable jsx-a11y/anchor-is-valid */}

      <section className="section mt-10 mb-100" id="account-info">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-md-3 col-lg-3 profile-sidebar ">
              <Link to="/profile" className="btn text-profile">
                Account Info
              </Link>
              <Link to="/security" className="btn text-profile ">
                Security
              </Link>
              <Link to="/privacy-data" className="btn text-profile activ">
                Privacy & Data
              </Link>
            </div>

            <div className="col-lg-6 col-md-6 user-profile">
              <h1 className="mt-20 mb-10">Privacy & Data</h1>

              <h3 className="mt-50 mb-30" style={{fontWeight:"800"}}>Privacy</h3>
              <div
                className="d-flex justify-content-between align-items-center w-100 p-1 mt-20 basic_info_section"
                style={{ height: "80px" }}
              >
                <div className="w-100">
                  <h5>Privacy Center</h5>
                  <p
                    style={{
                      fontSize: "17px",
                      color: "rgb(75, 75, 75)",
                      display: "inline-block",
                    }}
                  >
                    Take control of your privacy and learn how we protect it.
                  </p>
                </div>
                <div>
                  <i className="fa fa-chevron-right"></i>
                </div>
              </div>
              <hr className="_css-itktjL _css-fvSJzh" />

              <div
                className="d-flex justify-content-between align-items-center w-100 p-1 mt-20 basic_info_section"
                style={{ height: "80px" }}
              >
                <div className="w-100">
                <h3 className="mt-50 mb-30" style={{fontWeight:"800"}}>Third-party apps with account access</h3>
                  <p
                    style={{
                      fontSize: "17px",
                      color: "rgb(75, 75, 75)",
                      display: "inline-block",
                    }}
                  >
                    Once you allow access to third party apps, you'll see them
                    here.{" "}
                    <a href="#" style={{ color: "#000" }}>
                      Learn more
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default PrivacyData;
