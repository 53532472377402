import React, { useEffect, useState } from "react";
import { enableFeaturesApi, getProfileDataApi } from "../api/api";
import { useNavigate } from "react-router-dom";

export const UserContext = React.createContext({
  loginUser: null,
  setLoginUser: () => { },
  enableFeaturesList:null
});

export const UserProvider = ({ children }) => {
  const [loginUser, setLoginUser] = useState(null);
  const [enableFeaturesList, setEnableFeaturesList] = useState(null);
  const [userDetail, setUserDetail] = useState(() => {
    var user = localStorage.getItem("user_data");
    return user ? JSON.parse(user) : null;
  });


  const navigate = useNavigate();

 /*  useEffect(() => {
    var user = localStorage.getItem("user_data");
    if (user) {
      setUserDetail(JSON.parse(user));
    }
    }, []); */
  
  useEffect(() => {
    if (userDetail) {
      get_profile_data();
      get_enable_features_list()
    }
  }, [userDetail]);

  const get_profile_data = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
        user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await getProfileDataApi(form);
      var data = res.data;
      if (data.status == "1") {
        setLoginUser(data);
      } else {
        if (data.message == "Invalid Auth token") {
          localStorage.clear()
          navigate("/login")
        }
        console.log("Error : get profile data", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const get_enable_features_list = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type, // 0 android, 1 ios
        user_id: userDetail.user_profile_id,
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await enableFeaturesApi(form);
      var data = res.data;
      if (data.status == "1") {
        setEnableFeaturesList(data.enable_features);
      } else {
        console.log("Error : get_enable_features_list context", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <UserContext.Provider value={{ loginUser, setLoginUser,enableFeaturesList }}>
      {children}
    </UserContext.Provider>
  );
};
