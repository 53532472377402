import React from "react";
import { Link, useNavigate } from "react-router-dom";

const HowItWorks = () => {
  const navigate = useNavigate();
  const getStarted = () => {
    navigate("/login");
  };

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <section className="section mt-50" id="section2">
        <div className="container-sub">
          <div className="row d-flex box-how-it-work justify-content-between align-items-center">
            <div className="col-md-6">
              {/* <img src="/assets/imgs/page/homepage1/earner-illustra.png" alt="earner-illustra" /> */}
              <img src="/assets/imgs/bg/img2.png" alt="earner-illustra" />
            </div>
            <div className="col-md-6 content">
              <p className="">
                Here at Rove, we strive to do the best we can to provide you and
                your family with safe and reliable rides all over Kuwait.
              </p>
              <p className="">
                Whether you’re looking for a quick ride into the city, or a ride
                to a special event, we will give you the best service with the
                best rates possible. And to top it off, you will be greeted by a
                professional driver in a luxurious car every time.
              </p>
              <p className="fw-bold">PLUS WE GIVE YOU THE OPTION TO...</p>
              <ol type="1" className="content_ul">
                <li>
                  <p>Schedule a pick up for a later time.</p>
                </li>
                <li>
                  <p>Find all your past trip details in one place.</p>
                </li>
              </ol>
              <div className="row ">

              <div className="search-item mb-0 mt-3">
                <div className="box-button-download d-flex gap-3">
                  <a
                    className="btn btn-download mb-20 hover-up wow fadeInUp "
                    // className="btn btn-download mb-20 wow fadeInUp "
                    href="https://apps.apple.com/us/app/riderove/id1456118203?ls=1"
                    target="blank"
                  >
                    <div className="inner-download">
                      <div className="icon-download">
                        <i className="bi bi-apple"></i>
                      </div>
                      <div className="info-download">
                        <span className="text-download-top fw-bold">
                          Download on the
                        </span>
                        <span className="text-14-medium">Apple Store</span>
                      </div>
                    </div>
                  </a>
                  <a
                    className="btn btn-download mb-20 hover-up wow fadeInUp"
                    target="blank"
                    href="https://play.google.com/store/apps/details?id=com.riderove.app&pli=1"
                  >
                    <div className="inner-download">
                      <div className="icon-download">
                        <i className="bi bi-google-play"></i>
                      </div>
                      <div className="info-download">
                        <span className="text-download-top fw-bold">
                          Download on the
                        </span>
                        <span className="text-14-medium">Play Store</span>
                      </div>
                    </div>
                  </a>
                </div>
                {/* <button className="btn btn-primary button-grad " type="button" onClick={getStarted}>
                  {" "}
                  Get started
                </button> */}

                {/* <Link to="/login">Already have an account? Sign in</Link> */}
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section" id="whatweoffer">
        <div className="container-sub">
          <div className="text-center">
            <h2
              className="heading-44-medium  wow fadeInUp "
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              What We Offer
            </h2>
          </div>
          <div className="row mt-100 cardIconStyleCircle ">
            <div className="col-lg-4 mb-5">
              <div
                className="cardIconTitleDesc wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <div className="cardIcon">
                  <img
                    src="assets/imgs/page/about/best_rates.png"
                    alt="luxride"
                  />
                </div>
                <div className="cardTitle">
                  <h5 className="text-20-medium ">BEST RATES</h5>
                </div>
                <div className="cardDesc">
                  <p className="text-18 color-text fw-bold">
                    We strive to provide you with the best rates possible and
                    even give you the option to check your rates from Point A to
                    Point B ahead of time.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-5">
              <div
                className="cardIconTitleDesc wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <div className="cardIcon">
                  <img
                    src="assets/imgs/page/about/luxury-car.png"
                    alt="luxride"
                  />
                </div>
                <div className="cardTitle">
                  <h5 className="text-20-medium ">LUXURY CARS</h5>
                </div>
                <div className="cardDesc">
                  <p className="text-18 color-text fw-bold">
                    We are proud to be able to greet you with new and clean
                    luxury cars, ready to take you to any destination you want
                    to get to in Kuwait.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-5">
              <div
                className="cardIconTitleDesc wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <div className="cardIcon">
                  <img src="assets/imgs/page/about/safe.png" alt="luxride" />
                </div>
                <div className="cardTitle">
                  <h5 className="text-20-medium ">SAFE & RELIABLE</h5>
                </div>
                <div className="cardDesc">
                  <p className="text-18 color-text fw-bold">
                    Your saftey is our number one priority. We work hard to make
                    sure you have the most professional and well-trained drivers
                    in Kuwait.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="d-flex justify-content-evenly mt-50">
            <h3 className="mt-10">PLUS WE GIVE YOU THE OPTION TO...</h3>
            <div>
              <h5 className="mb-10">Schedule a pick up for a later time.</h5>
              <h5 className="mb-10">
                Find all your past trip details in one place.
              </h5>
            </div>
          </div> */}
        </div>
      </section>
      <section
        id="contactus"
        className="section d-flex px-3"
        style={{ minHeight: "500px" }}
      >
        <div className="container-sub mt-100">
          <div className="row d-flex box-how-it-work justify-content-between align-items-center">
            <div className="col-md-6">
              {/* <img src="/assets/imgs/page/homepage1/fleet-management.png" alt="fleet-management" /> */}
              <h1 className="fw-bold">CONTACT US</h1>
              <p className="fw-bold fs-5">
                Kindly leave us a message and we will get back to you as soon as
                possible.
              </p>
            </div>
            <div className="col-md-6 mb-5">
              <form>
                <div className="row mt-3 mb-2">
                  <div className="col-md-6 col-lg-6 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Firstname"
                    />
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Lastname"
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6 col-lg-6 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                    />
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Contact"
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-md-12 col-lg-12">
                    <textarea
                      cols="3"
                      rows="3"
                      className="form-control"
                      placeholder="Message or Description"
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-3">
                  <button className="btn btn-sm btn-primary">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HowItWorks;
