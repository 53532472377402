import React, { useState, useEffect } from "react";

function ReverseTimer() {
  const [seconds, setSeconds] = useState(599); // 10 minutes in seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timer);
          // Timer has reached zero, you can perform any action here
          console.log("Timer reached zero!");
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(timer);
  }, []);

  // Convert remaining seconds to minutes and seconds
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return (
    <>
      0{minutes}:{remainingSeconds < 10 ? "0" : ""}
      {remainingSeconds}
    </>
  );
}

export default ReverseTimer;
