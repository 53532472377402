import React from "react";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  return (
    <>
      {(location.pathname === "/" ||
        location.pathname === "/faqs" ||
        location.pathname === "/privacy-policy" ||
        location.pathname === "/terms-condition") && (
        <footer className="footer footer-white footer-bg-4">
          {/* eslint-disable jsx-a11y/anchor-is-valid */}
          <div className="footer-1 pt-50">
            <div className="container-sub">
              <div className="box-footer-top ">
                <div className="row d-flex align-items-end">
                  <div className="col-12 col-md-12 col-lg-12 text-md-start text-center mb-15 wow fadeInUp">
                    <div className="d-flex align-items-center justify-content-md-start justify-content-center">
                      <a className="mr-30" href="#">
                        <img
                          src="assets/imgs/template/logo-rove-light.png"
                          alt="Luxride"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-40">
                <div className="col-lg-4 width-20 wow fadeInUp d-flex flex-column justify-content-center">
                  <h5 className="text-18-medium  text-white fw-bold mb-20 ps-4 ">
                    Address :
                  </h5>
                  <p className="text-16-medium text-white fw-bold mb-20 ps-4 ">
                    Alqibla - block3 - building 106 - Floor 1 - office 15,
                    Kuwait City, Kuwait
                  </p>
                </div>
                <div className="col-lg-4 width-20 mb-30 wow fadeInUp d-flex flex-column justify-content-center">
                  <div className="mb-2">
                    <h5 className="text-18-medium d-inline text-white fw-bold ps-4 mb-20">
                      Email :
                    </h5>

                    <a
                      href="mailto:info@riderove.com"
                      className="text-16-medium d-inline text-white fw-bold mb-20 ps-4 "
                    >
                      info@riderove.com
                    </a>
                  </div>

                  <div className="mb-2">
                    <h5 className="text-18-medium d-inline text-white fw-bold ps-4 mb-20">
                      Phone:
                    </h5>

                    <a
                      href="tel:+965 1812888"
                      className="text-16-medium d-inline text-white fw-bold mb-20 ps-4"
                    >
                      +965 1812888
                    </a>
                  </div>

                  <div className="mb-2">
                    <h5 className="text-18-medium d-inline text-white fw-bold ps-4 mb-20">
                      Follow Us:
                    </h5>

                    <a
                      href="https://www.instagram.com/rideroveapp/?hl=en"
                      target="blank"
                      className="text-16-medium d-inline text-white fw-bold mb-20 ps-4"
                    >
                      <i className="fa fa-instagram" aria-hidden="true"></i>{" "}
                      @rideroveapp
                    </a>
                  </div>
                </div>

                <div className="col-lg-4 width-20 wow fadeInUp">
                  <h5 className="text-18-medium text-white fw-bold ps-4 mb-20">
                    Download The App
                  </h5>
                  <div className="text-start">
                    <div className="box-button-download">
                      <a
                        className="btn btn-download mb-20 hover-up wow fadeInUp"
                        href="https://apps.apple.com/us/app/riderove/id1456118203?ls=1"
                        target="blank"
                      >
                        <div className="inner-download">
                          <div className="icon-download">
                            <img
                              src="assets/imgs/template/icons/apple-icon.svg"
                              alt="luxride"
                            />
                          </div>
                          <div className="info-download">
                            <span className="text-download-top fw-bold">
                              Download on the
                            </span>
                            <span className="text-14-medium">Apple Store</span>
                          </div>
                        </div>
                      </a>
                      <a
                        className="btn btn-download mb-20 hover-up wow fadeInUp"
                        href="https://play.google.com/store/apps/details?id=com.riderove.app"
                        target="blank"
                      >
                        <div className="inner-download">
                          <div className="icon-download">
                            <img
                              src="assets/imgs/template/icons/google-icon.svg"
                              alt="luxride"
                            />
                          </div>
                          <div className="info-download">
                            <span className="text-download-top fw-bold">
                              Download on the
                            </span>
                            <span className="text-14-medium">Play Store</span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-2">
            <div className="container-sub">
              <div className="footer-bottom">
                <div className="row align-items-center">
                  <div className="col-lg-12 col-md-12 text-center ">
                    <span className="text-14 color-white mr-50">
                      © 2024 Rove All Rights Reserved
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
