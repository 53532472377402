import React, { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { emailOtpApi, otpApi } from "../../api/api";
import { isValid } from "../../utils/helper";
import Spinner from "react-bootstrap/Spinner";
import { requestNotificationPermission } from "../../utils/notification";


const ContactNumber = () => {
  const navigate = useNavigate();
  const controlref = useRef();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [deviceToken, setDeviceToken] = useState(
    ""
  );
  const { email } = location.state;
  const [dialcode, setDialcode] = useState()
  let iti = null;
  // var numbers = new RegExp("[0-9]{8}");
  // const [inputValue, setInputValue] = useState("");
  // const [inputPhoneValue, setInputPhoneValue] = useState("");

  var auth = localStorage.getItem("auth-token")
  if (auth) {
    navigate("/dashboard")
  }
  useEffect(() => {
    requestNotificationPermission()
      .then((token) => {
        setDeviceToken(token);
        console.log("Token received:", token);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    // const input = document.querySelector("#countrySelect");
    const input = controlref.current;
    iti = intlTelInput(input, {
      initialCountry: "KW",
      // showFlags:true,
      showSelectedDialCode: true,
      hiddenInput: function (telInputName) {
        return {
          phone: "phone_full",
          country: "country_code",
        };
      },
    });
    // input.value = inputPhoneValue;
    input.focus();
   var dialcode = iti.getSelectedCountryData().dialCode;
   setDialcode(dialcode)

    input.addEventListener("countrychange", function (e) {
      let dialcode = iti.getSelectedCountryData().dialCode;
      setDialcode(dialcode)
      // input.value = "+" + dialcode + " " + inputValue;
    });
  }, []);

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      var number = controlref.current.value;

      if (number.trim().length) {
        if (
          dialcode == 965 &&
          (!/[+0-9]{8}/.test(number) || number.trim().length != 8)
        ) {
          toast("Please, enter valid number", {
            position: "top-center",
            autoClose: 2000,
          });
          return false;
        }
        if (
          dialcode == 91 &&
          (!/[+0-9]{10}/.test(number) || number.trim().length != 10)
        ) {
          toast("Please, enter valid number", {
            position: "top-center",
            autoClose: 2000,
          });
          return false;
        }
        if (email) {
          try {
            const data1 = {
              mobile_number: number,
              email: email,
              device_token: deviceToken,
              device_type: 2,
            };

            var form = new FormData();
            form.append("mobile_number", data1.mobile_number);
            form.append("device_token", data1.device_token);
            form.append("device_type", data1.device_type);
            form.append("email", data1.email);
            setIsLoading(true);
            var res = await emailOtpApi(form);
            var data = res.data;
            setIsLoading(false);
            if (data.status == "1") {
              localStorage.setItem(
                "user_number",
                "+" + dialcode + " " + data1.mobile_number
              );
              localStorage.setItem("user_email", email);
              toast(data.message, {
                position: "top-center",
                autoClose: 2000,
              });
              navigate("/otp");
            } else {
              toast(data?.message, {
                position: "top-center",
                autoClose: 2000,
              });
            }
          } catch (error) {
            console.log(error);
            setIsLoading(false);
          }
        }
      } else {
        toast("Please, enter valid number", {
          position: "top-center",
          autoClose: 2000,
        });
      }
      // if (email.trim().length) {
      //   navigate("/")
      // }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePhoneChange = (e) => {
    // setInputPhoneValue(e.target.value);
    var d = e.target.value;
  };

 

  return (
    <main className="main">
      <ToastContainer />
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <section className="section mt-80 mb-100">
          <div className="container-sub">
            <div className="text-center">
              <h4
                className="heading-44-medium wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                What's your phone number ?
              </h4>
            </div>
            <div className="box-login mt-40">
              <div
                className="form-contact form-comment wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <form>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group focused">
                        <input
                          type="text"
                          id="countrySelect"
                          onChange={handlePhoneChange}
                          maxLength={10}
                          className="form-control"
                          ref={controlref}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-20">
                        <div className="d-flex justify-content-between">
                          <label className="text-14 color-text">
                            By entering your number or email and clicking on
                            "Next" you agree to our{" "}
                            <Link to="/terms-condition">Term & Conditions</Link>{" "}
                            .
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <button
                        className="btn btn-primary fw-bold w-100"
                        type="button"
                        onClick={formSubmitHandler}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </main>
  );
};

export default ContactNumber;
