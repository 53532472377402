import React from 'react'
import { useState } from 'react';

const Rating = ({height,width,initialState}) => {
    const [rate, setRate] = useState(initialState??0);
  return (
    <div>{[...Array(5)].map((item, index) => {
        const givenRating = index + 1;
        return (
          <img
            key={index}
            src={`/assets/imgs/icons/${
              givenRating < rate || givenRating === rate
                ? "star-fill"
                : "star"
            }.png`}
            onClick={() => setRate(givenRating)}
            className="rateing-img mx-1"
            alt="start"
            width={width??40}
            height={height??20}
          />
        );
      })}</div>
  )
}

export default Rating