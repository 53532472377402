import React from 'react'
import { Link } from "react-router-dom";


const Security = () => {
  return (
    <main className="main">
      {/* eslint-disable jsx-a11y/anchor-is-valid */}

      <section className="section mt-10 mb-100" id="account-info">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-md-3 col-lg-3 profile-sidebar ">
              <Link to="/profile" className="btn text-profile">
                Account Info
              </Link>
              <Link to="/security" className="btn text-profile activ">
                Security
              </Link>
              <Link to="/privacy-data" className="btn text-profile">
                Privacy & Data
              </Link>
            </div>

            <div className="col-lg-6 col-md-6 user-profile">
              <h1 className="mt-20 mb-10" >Security</h1>
              <h3 className="mt-20 mb-10">Logging in to Rove</h3>
              <div
                className="d-flex justify-content-between align-items-center w-100 p-1 mt-20 basic_info_section"
                style={{ height: "80px" }}
              >
                <div className="w-100">
                  <h5>Password</h5>
                  <p
                    style={{
                      fontSize: "17px",
                      color: "rgb(75, 75, 75)",
                      display: "inline-block",
                      marginBottom: 0
                    }}
                  >
                    &#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;
                  </p>
                  <p>Last changed 17 October 2017</p>
                </div>
                <div>
                  <i className="fa fa-chevron-right"></i>
                </div>
              </div>
              <hr className="_css-itktjL _css-fvSJzh" />
              <div
                className="d-flex justify-content-between align-items-center w-100 p-1 mt-20 basic_info_section"
                style={{ height: "80px" }}
              >
                <div className="w-100">
                  <h5>Passkeys</h5>
                  <p
                    style={{
                      fontSize: "17px",
                      color: "rgb(75, 75, 75)",
                      display: "inline-block",
                    }}
                  >
                    Passkeys are easier and more secure than passwords.
                  </p>
                </div>
                <div>
                  <i className="fa fa-chevron-right"></i>
                </div>
              </div>
              <hr className="_css-itktjL _css-fvSJzh" />
              <div
                className="d-flex justify-content-between align-items-center w-100 p-1 mt-20 basic_info_section"
                style={{ height: "80px" }}
              >
                <div className="w-100">
                  <h5>2-step verification</h5>
                  <p
                    style={{
                      fontSize: "17px",
                      color: "rgb(75, 75, 75)",
                      display: "inline-block",
                    }}
                  >
                    Add additional security to your account with 2-step verification.
                  </p>
                </div>
                <div>
                  <i className="fa fa-chevron-right"></i>
                </div>
              </div>
              <hr className="_css-itktjL _css-fvSJzh" />
              <div className="mt-60">
                <h3 className="fw-bold">Connected social apps</h3>
                <p>Once you’ve allowed social apps to sign in to your Rove account, you’ll see them here.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Security