import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

const Sidebar = ({ pathname }) => {
  const [forMeShow, setForMeShow] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const handleForMeClose = () => {
    setForMeShow(false);
  };
  const handleConfirmDelete = () => setShowConfirmDelete(false);

  return (
    <>
      <Link
        to="/settings"
        className={
          pathname == "/settings"
            ? "btn text-profile activ"
            : "btn text-profile"
        }
      >
        Settings
      </Link>
      <Link
        to="/saved-places"
        className={
          pathname == "/saved-places"
            ? "btn text-profile activ"
            : "btn text-profile"
        }
      >
        Saved Places
      </Link>
      <Link
        to="/coupons"
        className={
          pathname == "/coupons"
            ? "btn text-profile activ"
            : "btn text-profile"
        }
      >
        Coupons
      </Link>

      <Link
        to="/about"
        className={
          pathname == "/about" ? "btn text-profile activ" : "btn text-profile"
        }
      >
        About Us
      </Link>
      <Link
        to="/feedback"
        className={
          pathname == "/feedback"
            ? "btn text-profile activ"
            : "btn text-profile"
        }
      >
        Feedback
      </Link>

      <Link
        className={
          pathname == "/privacy-policy"
            ? "btn text-profile activ"
            : "btn text-profile"
        }
        to="/privacy-policy"
      >
        Privacy Policy
      </Link>
      <Link
        to="/faqs"
        className={
          pathname == "/faqs"
            ? "btn text-profile activ"
            : "btn text-profile"
        }
      >
        FAQ
      </Link>
      <button
        type="button"
        onClick={() => setForMeShow(true)}
        className={
          pathname == "/dashboard"
            ? "btn text-profile activ"
            : "btn text-profile"
        }
      >
        Delete Account
      </button>

      <Modal show={forMeShow} onHide={handleForMeClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-rove">Delete Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-2">
            <div className="">
              <h6 className="text-rove fw-bold mb-3">
                Are you sure you want to delete your account ?{" "}
              </h6>
              <h6 className="text-rove fw-bold">
                If you want to change your account info, you can easily do so in
                your profile page.{" "}
              </h6>
            </div>
          </div>
          <div className="mt-5">
            <Link className="btn btn-primary w-100 fw-bold" to={"/profile"}>
              Go To Profile
            </Link>
          </div>

          <div className="mt-3">
            <h6 className="text-danger fw-bold mt-2">WARNING</h6>
          </div>
          <div className="mt-3">
            <h6 className="text-rove fw-bold mt-2">
              If you delete your account,you won't be able to reactivate it
              later.
            </h6>
          </div>

          <div className="mt-5">
            <button className="btn btn-light w-100" onClick={() => { setForMeShow(false); setShowConfirmDelete(true);}}>
              Next
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showConfirmDelete} onHide={handleConfirmDelete} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-rove">Delete Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-2">
            <div className="">
              <h6 className="text-rove fw-bold mb-3">
                Tell us why you are leaving
              </h6>
              <form>
                <div className="row">
                  <div className="col-lg-12">
                    <textarea
                      cols="3"
                      rows="3"
                      className="form-control"
                      placeholder="Reason"
                    ></textarea>
                  </div>

                  <div className="col-lg-12 mt-3">
                    <Link
                      className="btn btn-primary fw-bold w-100"
                      to={"/"}
                    >
                      Delete My Account
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Sidebar;
