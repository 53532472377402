import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  TrafficLayer,
} from "react-google-maps";
// import MapWithAMarker from "./MapReciept";
import Spinner from "react-bootstrap/Spinner";
import { getPastRidesApi, getProfileDataApi, removeHistoryApi, nearSearchLocationApi, enableFeaturesApi, getDriverApi, fareBreakDownApi, priceCalculateApi, updateUpcomingRideApi, pickupLaterUpdateApi, getLongRentalCarApi } from '../../api/api'
import moment from 'moment-timezone';
import axios from "axios";
import Pagination from "react-js-pagination";
// import "bootstrap-less";
import $ from "jquery";
import { toBeInTheDocument } from "@testing-library/jest-dom/matchers";
// import Pagination from 'react-bootstrap/Pagination';
// require("bootstrap/less/bootstrap.less");
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal, ModalBody, ModalHeader, Offcanvas } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; import MapWithAMarker from "../common/MapDashboard";
import { TapCard, Currencies, Direction, Edges, Locale, Theme, Scope, ColorStyle } from '@tap-payments/card-web'

import DatePicker from "react-datepicker";
import { UserContext } from "../../utils/userContext";
import "react-datepicker/dist/react-datepicker.css";

// require("bootstrap/less/bootstrap.less");

const custom_style = require("../../custom_style.json");
const options = {
  mapTypeId: "terrain",
  disableDefaultUI: true,
  zoomControl: false,
  styles: custom_style,
  draggable: false,
  scrollwheel: false,
};

const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");
const {
  MarkerClusterer,
} = require("react-google-maps/lib/components/addons/MarkerClusterer");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
const {
  DrawingManager,
} = require("react-google-maps/lib/components/drawing/DrawingManager");

const google = window.google;

const MyTrips = (enableFeatures) => {
  const navigate = useNavigate();

  const [position, setPosition] = useState({
    lat: 29.3776824,
    lng: 47.9755194,
    text: "",
  });
  const [zoom, setZoom] = useState(8);
  const [pickupPosition, setPickupPosition] = useState();
  const [destinationPosition, setDestinationPosition] = useState({});
  const [routeDirections, setRouteDirections] = useState({});
  const [showPastRides, setShowPastRides] = useState(true);
  const [searchPrams, setSearchParms] = useSearchParams();

  const [userDetail, setUserDetail] = useState(() => {
    var user = localStorage.getItem("user_data");
    return user ? JSON.parse(user) : null;
  });
  const [userProfileData, setUserProfilesData] = useState('')
  const [pastRideData, setPastRideData] = useState();
  const [upcomingData, setUpcomingData] = useState();
  const [pastRideResponse, setPastRideResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPagination, setIsLoadingPagination] = useState(false);
  const [statusText, setStatusText] = useState("");
  const [filterText, setFilterText] = useState("All Trips");
  const [filterStatus, setFilterStatus] = useState(0);
  const [filterPaidStatus, setFilterPaidStatus] = useState(0);
  const [receiptNavigate, setReceiptNavigate] = useState(false);
  // const [filterPaidStatus, setFilterPaidStatus] = useState(0)
  const [noDataAvailable, setNoDataAvailable] = useState(false); // Flag for no data
  const [totalPage, setTotalPage] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pickuplater, setPickupLater] = useState(0);
  const [limitPerPage, setLimitPerPage] = useState(0);
  const [selectPayMode, setSelectPayMode] = useState(0);
  const [updateModal, setUpdateModal] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const [payInAdvanceModal, setPayInAdvanceModal] = useState(false)
  const [fareBreakDownModal, setFareBreakDownModal] = useState(false)
  const [requestID, setRequestID] = useState(null)
  const [currantPosition, setCurrantPosition] = useState();
  const [nearSearchLocation, setNearSearchLocation] = useState();
  const [getDriverDetail, setGetDriverDetail] = useState();
  const [pickupLaterUpdatedData, setPickupLaterUpdatedData] = useState();
  const [longRideData, setLongRideData] = useState();
  const [priceCalculation, setPriceCalculation] = useState();
  const [estimatedprice, setEstimatedprice] = useState("Loading...");
  const [enableFeaturesList, setEnableFeaturesList] = useState();
  const [path, setPath] = useState()
  const [requestRide, setRequestRide] = useState(false);
  const [noOfCars, setNoOfCars] = useState(false);
  const [startDate, setStartDate] = useState();
  const [paymentModal, setPaymentModal] = useState(false);
  const [carFareBreakdown, setCarFareBreakdown] = useState();
  const [longRentalCarData, setLongRentalCarData] = useState('');
  const [carSelectionId, setCarSelectionId] = useState('0');
  const [promoCode, setPromoCode] = useState('');
  const [carType, setCarType] = useState('');
  const [pickupLaterCount, setPickupLaterCount] = useState('');
  const [selectedHourid, setSelectedHourId] = useState('');
  const [rentalCarBrandType, setRentalCarBrandType] = useState('');
  const [selectedHourPrice, setSelectedHourPrice] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [nearLocationValue, setNearLocationValue] = useState('');
  const [cardDetail, setCardDetail] = useState('');
  const [payByCardModal, setPayByCardModal] = useState(false);
  const [walletDiff, setWalletDiff] = useState(0);
  const [advanceFare, SetAdvanceFare] = useState(0);

  var minDate = new Date()
  var maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 6);



  // const [noOfCarForRide, setNoOfCarForRide] = useState({
  //   1: false,
  //   2: false,
  //   3: false,fg
  //   4: false,
  // });
  // const [noOfCarForRide, setNoOfCarForRide] = useState('')
  const [noOfCarForRide, setNoOfCarForRide] = useState('1');

  const autoCompleteRef = useRef();
  const inputRef2 = useRef();
  const autoCompleteRef2 = useRef();

  const options1 = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
  };
  // var user_profile_data = props.userProfile;

  // useEffect(() => {
  //   const user = localStorage.getItem("user_data");
  //   if (user) {
  //     setUserDetail(JSON.parse(user));
  //   }
  // }, [user_profile_data]);

  // useEffect(() => {
  //   const user = localStorage.getItem("user_data");
  //   if (user) {
  //     setUserDetail(JSON.parse(user));
  //     // setUserProfilesData(props.userProfile)
  //   }
  // }, []);

  useEffect(() => {
    if (userDetail) {
      if (enableFeatures) {
        setEnableFeaturesList(enableFeatures)
      } else {
        get_enable_features_list();
      }
    }
    if (!userProfileData && enableFeaturesList) {
      get_profile_data()
    }
  }, [])

  const get_profile_data = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
        user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await getProfileDataApi(form);
      var data = res.data;
      if (data.status == "1") {
        setUserProfilesData(data);
        // SetCardDetail(data.user_card_info)
        var wallet = data.user_profile.wallet;
        var card_detail = data.user_card_info;
        if (card_detail) {
          var exp = new Date(card_detail.expire_date.slice(0, 7) + "-01");
          var M = exp.getMonth() + 1;
          var Y = exp.getFullYear();
          card_detail.expire_date = (M < 10 ? "0" : "") + M + "/" + Y;
        }

        setCardDetail(card_detail);

        // console.log(card_detail.card_brand)
        // console.log(card_detail)

        console.log(data)
      } else {

        console.log("Error : get profile data", data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(userProfileData)

  const get_enable_features_list = async (e) => {
    try {
      var data1 = {
        user_type: userDetail.user_type, // 0 android, 1 ios
        user_id: userDetail.user_profile_id,
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await enableFeaturesApi(form);
      var data = res.data;
      if (data.status == "1") {
        setEnableFeaturesList(data.enable_features);
        // findBlockUserMessage(data.enable_features);
      } else {
        console.log("Error : get_enable_features_list", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  setTimeout(function () {
    autoCompleteRef.current = new google.maps.places.Autocomplete(
      inputRef.current,
      options1
    );
    /*autoCompleteRef.current.setComponentRestrictions({
      country: ["us", "pr", "vi", "gu", "mp"],
    });*/

    autoCompleteRef2.current = new google.maps.places.Autocomplete(
      inputRef2.current,
      options1
    );


    autoCompleteRef.current.addListener("place_changed", async function (e) {
      var place = this.getPlace();
      var geometry = place.geometry;
      var lat = geometry.location.lat();
      var lng = geometry.location.lng();
      // console.log("pickuplat", lat)
      // console.log("pickuplan",lng)
      var add;
      var add = place.name + ","
      place.address_components.forEach(element => {
        add = add + element.long_name + ", "
      });
      near_search_location(lat, lng).then((data) => {
        var userLocation = data.user_location_data;
        // inputRef.current.value = userLocation.title_address;
        var pp = {
          lat: parseFloat(userLocation.latitude),
          lng: parseFloat(userLocation.longitude),
          // text: near_search_condition(data),
          add: add,
        };
        setPickupPosition(pp);
        setPosition({
          lat: parseFloat(userLocation.latitude),
          lng: parseFloat(userLocation.longitude),
        });
        // setZoom(13);
        // if (destinationPosition)   {
        //   aftersetDestinationPosition(pp, destinationPosition);
        // }
      });

    });
    // autoCompleteRef.current.addListener("place_changed", async function (e) {
    //   var place = this.getPlace();
    //   var geometry = place.geometry;
    //   var lat = geometry.location.lat();
    //   var lng = geometry.location.lng();
    //   console.log("pickuplat", lat)
    //   console.log("pickuplan",lng)
    //   var add;
    //   // var add = place.name + ","
    //   // place.address_components.forEach(element => {
    //   //   add = add + element.long_name + ", "
    //   // });
    //   near_search_location(lat, lng).then((data) => {
    //     console.log( data.user_location_data)

    //     var userLocation = data.user_location_data;
    //     // var userLocation = pickupLocation ;
    //     inputRef.current.value =  userLocation?.title_address;
    //     var pp = {
    //       lat: parseFloat(userLocation.lat),
    //       lng: parseFloat(userLocation.lng),
    //       // text: near_search_condition(data)
    //       add:userLocation.title_address,
    //       // add:  userLocation.add ,
    //     };
    //     // var pp = {
    //     //   lat: parseFloat(userLocation.latitude),
    //     //   lng: parseFloat(userLocation.longitude),
    //     //   // text: "KD " + esti_pr,
    //     //   add:userLocation.title_address,
    //     // };
    //     setPickupPosition(pp);

    //     // setZoom(13);
    //     // if (destinationPosition) {
    //     //   aftersetDestinationPosition(pp, destinationPosition);
    //     // }
    //   });

    //   // if (enableFeaturesList && enableFeaturesList.is_nextbillion_all) {
    //   //   add = await getAddressFromNextBillion(
    //   //     enableFeaturesList.nextbillion_api_key,
    //   //     lat,
    //   //     lng
    //   //   );
    //   //   add = add.places[0].address;
    //   // } else {
    //   //   add = await getAddressFromLat(lat, lng);
    //   // }
    //   // var latlng = { lat: lat, lng: lng, text: place.name, add: add };
    //   // console.log(latlng)
    //   // setPickupPosition(latlng);
    //   // setPosition({ ...position, lat, lng, text: place.name, add: add });
    // });

    autoCompleteRef2.current.addListener("place_changed", async function () {
      var place = this.getPlace();
      var geometry = place.geometry;
      var lat = geometry.location.lat();
      var lng = geometry.location.lng();
      // console.log("enableFeaturesList", enableFeaturesList);
      // console.log(lat)
      // console.log(lng)

      $('#updateRideBtn').attr('disabled', 'disabled');
      var add;
      var p_r = await price_calculation(
        pickupPosition.lat,
        pickupPosition.lng,
        lat,
        lng
      );
      var esti_pr =
        parseFloat(p_r.estimatedprice) - parseFloat(p_r.discount_price);
      esti_pr = esti_pr.toFixed(3);
      near_search_location(lat, lng).then((data) => {

        var userLocation = data.user_location_data;
        // inputRef.current.value = userLocation.title_address;
        var dp = {
          lat: parseFloat(userLocation.latitude),
          lng: parseFloat(userLocation.longitude),
          text: "KD " + esti_pr,
          add: userLocation.title_address,
        };
        // console.log(pickupPosition)
        // setDestinationPosition(dp);
        setDestinationPosition(dp);
        $('#updateRideBtn').removeAttr('disabled');
        // setZoom(13);
        // aftersetDestinationPosition(pickupPosition, dp);
      });

    });
  }, 100);

  /*  useEffect(() => {
     const rebookData = localStorage.getItem("rebookdata");
     if (rebookData) {
       var rebook_data = JSON.parse(rebookData);
       var pickup_data = rebook_data.pickup;
       var destination_data = rebook_data.destination;
       var pickup_lat = pickup_data.split(",")[0];
       var pickup_long = pickup_data.split(",")[1];
       var destination_lat = destination_data.split(",")[0];
       var destination_long = destination_data.split(",")[1];
       setPosition({
         lat: parseFloat(pickup_lat),
         lng: parseFloat(pickup_long),
       });
       setDestinationPosition({
         lat: parseFloat(destination_lat),
         lng: parseFloat(destination_long),
       });
       setPickupPosition({
         lat: parseFloat(pickup_lat),
         lng: parseFloat(pickup_long),
       });
       near_search_location(destination_lat, destination_long).then((data) => {
         var userLocation = data?.user_location_data;
         inputRef2.current.value = userLocation?.title_address;
       });
       near_search_location(pickup_lat, pickup_long).then((data) => {
         var userLocation = data?.user_location_data;
 
         inputRef.current.value = userLocation?.title_address;
         var p = {
           lat: parseFloat(userLocation.latitude),
           lng: parseFloat(userLocation.longitude),
           text: near_search_condition(data),
         };
         setPickupPosition(p);
         get_driver(pickup_lat, pickup_long);
         var dest_position = {
           lat: parseFloat(destination_lat),
           lng: parseFloat(destination_long),
         };
         if (Object.keys(dest_position).length && p) {
           price_calculation(
             pickup_lat,
             pickup_long,
             destination_lat,
             destination_long
           ).then((p_r) => {
             var esti_pr =
               parseFloat(p_r.estimatedprice) - parseFloat(p_r.discount_price);
             esti_pr = esti_pr.toFixed(3);
             setDestinationPosition({
               ...dest_position,
               text: "KD " + esti_pr,
             });
           });
           // aftersetDestinationPosition(p, dest_position);
         }
       });
       localStorage.removeItem("rebookdata");
     } else {
       fetchLocation();
     }
   }, []); */

  const get_fare_break_down = async () => {
    try {
      var data1 = {
        no_of_car: noOfCarForRide,
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        pickup_latitude: pickupPosition.lat,
        is_airport_ride: isAirportRide ? '1' : '0',
        pickup_longitude: pickupPosition.lng,
        car_selection_id: carSelectionId,
        destination_latitude: destinationPosition ? destinationPosition.lat : 0,
        destination_longitude: destinationPosition ? destinationPosition.lng : 0,
        promo_code: promoCode ? promoCode : "",
        car_type: carType ? carType : 0,
        pickup_later: pickupLaterCount ? pickupLaterCount : 1,
        surcharges_value: 0,
        is_rental_car: isRentalCar ? isRentalCar : 1,
        ride_fare: "",
        rental_hours_id: selectedHourid ? selectedHourid : 4,
      };

      let form = new FormData();
      form.append("no_of_car", data1.no_of_car);
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("pickup_latitude", data1.pickup_latitude);
      form.append("is_airport_ride", data1.is_airport_ride);
      form.append("pickup_longitude", data1.pickup_longitude);
      form.append("car_selection_id", data1.car_selection_id);
      form.append("destination_latitude", data1.destination_latitude);
      form.append("destination_longitude", data1.destination_longitude);
      form.append("promo_code", data1.promo_code);
      form.append("car_type", data1.car_type);
      form.append("pickup_later", data1.pickup_later);
      form.append("surchages_value", data1.surcharges_value);
      form.append("is_rental_car", data1.is_rental_car);
      form.append("ride_fare", data1.ride_fare);
      form.append("rental_hours_id", data1.rental_hours_id);

      var res = await fareBreakDownApi(form);
      var data = res.data;
      // console.log(data)
      if (data.status == "1") {
        setCarFareBreakdown(data.car_fare_breakdown);
      } else {
        console.log("get_fare_break_down", data);
      }
    } catch (error) {
      console.log("get_fare_break_down" + error);
    }
  };

  const price_calculation = async (p_lat, p_lng, d_lat, d_lng, c_p) => {
    try {
      var data1 = {
        is_new_app: 1,
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        pickup_latitude: p_lat ?? pickupPosition.lat,
        pickup_longitude: p_lng ?? pickupPosition.lng,
        car_selection_id: 0,
        is_get_eta: 0,
        waiting_charge: 0,
        destination_latitude: d_lat ?? destinationPosition.lat,
        destination_longitude: d_lng ?? destinationPosition.lng,
        promo_code: c_p ?? "",
        car_type: 17,
        offer_price: 0,
        pickup_later: 0,
      };


      let form = new FormData();
      form.append("is_new_app", data1.is_new_app);
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("pickup_latitude", data1.pickup_latitude);
      form.append("pickup_longitude", data1.pickup_longitude);
      form.append("car_selection_id", data1.car_selection_id);
      form.append("is_get_eta", data1.is_get_eta);
      form.append("waiting_charge", data1.waiting_charge);
      form.append("destination_latitude", data1.destination_latitude);
      form.append("destination_longitude", data1.destination_longitude);
      form.append("promo_code", data1.promo_code);
      form.append("car_type", data1.car_type);
      form.append("offer_price", data1.offer_price);
      form.append("pickup_later", data1.pickup_later);

      var res = await priceCalculateApi(form);
      var data = res.data;
      if (data.status == "1") {
        setPriceCalculation(data);
        var esti_pr =
          parseFloat(data.estimatedprice) - parseFloat(data.discount_price);
        esti_pr = esti_pr.toFixed(3);
        setEstimatedprice(esti_pr);
        return data;
      } else {
        console.log("price_calculation", data);
        setPriceCalculation();
        setEstimatedprice("Loading...");
      }
    } catch (error) {
      console.log("price_calculation", error);
    }
  };


  const aftersetDestinationPosition = async (pickup, destination) => {
    if (pickup && destination) {
      if (enableFeaturesList && enableFeaturesList.is_nextbillion_all) {
        console.log("nextbillion")
        var data = await getDirectionFromNextBillion(enableFeaturesList.nextbillion_api_key, pickup, destination);
        const route = data.routes[0].legs[0];
        const path = route.steps.map(step => ({
          lat: step.start_location.latitude,
          lng: step.start_location.longitude
        }));
        setPath(path)

      } else {
        console.log("Google directions")
        DirectionsService.route(
          {
            origin: new google.maps.LatLng(pickup),
            destination: new google.maps.LatLng(destination),
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            console.log(status);
            if (status === google.maps.DirectionsStatus.OK) {
              console.log("ok", result);
              setRouteDirections(result);
              // setRequestRide(true);
            } else {
              console.log("err", result);
            }
            // console.log(routeDirections);
          }
        );
      }

    }
  };

  const get_driver = async (lat, lng) => {
    try {
      var data1 = {
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        pickup_latitude: lat ? lat : pickupPosition.lat,
        pickup_longitude: lng ? lng : pickupPosition.lng,
      };
      let form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("pickup_latitude", data1.pickup_latitude);
      form.append("pickup_longitude", data1.pickup_longitude);

      var res = await getDriverApi(form);
      var data = res.data;
      if (data.status == "1") {
        console.log(data)
        setGetDriverDetail(data);
      } else {
        if (data.message == "Invalid Auth token") {
          localStorage.clear();
          navigate("/login");
        }
        setGetDriverDetail({ ...data, isDriverAvailable: 0 });
        console.log("get_driver", data);
      }
    } catch (error) {

      toast.error(error, {
        position: "top-center",
        autoClose: 2000,
      });
      console.log(error);
    }
  };


  const getDirectionFromNextBillion = async (key, pickup, destination) => {
    try {
      var origin = pickup.lat + "," + pickup.lng
      var des = destination.lat + "," + destination.lng
      var api = "https://api.nextbillion.io/directions/json?key=" + key + "&origin=" + origin + "&destination=" + des + "&mode=car&steps=true&overview=simplified";

      var res = await fetch(api, {
        method: "GET"
      });
      var data = await res.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  };


  // Fetch past places based on filters and update loading/no data states
  // useEffect(() => {
  //   const fetchPastPlaces = async (filter = 0, paidStatus = 0) => {
  //     setIsLoading(false);
  //     setNoDataAvailable(false); // Reset no data flag

  //     try {
  //       conzst response = await get_past_places(filter, paidStatus = 0); // Replace with your API call
  //       setPastPlaces(response.data || []); // Handle potential response data structure
  //     } catch (error) {
  //       console.error('Error fetching past places:', error);
  //     } finally {
  //       setIsLoading(false); // Clear loading state
  //       if (pastPlaces.length === 0) {
  //         setNoDataAvailable(true); // Set no data flag if no results
  //       }
  //     }
  //   };

  //   if (userDetail) { // Ensure user data is fetched before fetching past places
  //     fetchPastPlaces();
  //   }
  // }, [ userDetail]);


  useEffect(() => {
    let upcoming = searchPrams.get("upcoming");
    setShowPastRides(upcoming ? false : true);
    if (upcoming) {
      get_past_places(0, 0, 1, 1);
    }
  }, [])


  useEffect(() => {
    get_past_places(0, 0, activePage);
    get_past_places();
    get_long_rental_car_data()
  }, [userDetail, activePage]);

  // console.log(date)

  // console.log(destinationPosition)


  const TimeZone = (time, format = "Do MMM YYYY, h:mm a") => {
    return moment.utc(time).tz("Asia/Kolkata").format(format);
  };

  const saveCardHandler = () => {
    if (saveCardDetails) {
      console.log('card saved')
      // setSaveCard(true)
      // setAutoSaveCard(true)
    } else {
      console.log('Save card option is not selected');

    }
  }

  const [saveCardDetails, setSaveCardDetails] = useState(false);

  const handleSaveCardChange = (data) => {
    console.log('onChangeSaveCardLater', data);
    setSaveCardDetails(data.saveCardDetails); // Assuming the callback returns an object with saveCard property
  };


  const [pickupLocation, setPickupLocation] = useState('')
  const [destinationLocation, setdestinationLocation] = useState('')
  const [pickupUpdateTime, setPickupUpdateTime] = useState('')
  const [isRentalCar, setIsRentalCar] = useState('')
  const [fare, setFare] = useState('')
  const [rentalCarHour, setRentalCarHour] = useState('')
  const [updateRequestID, setUpdateRequestID] = useState('')
  const [localTimeZone, setLocalTimeZone] = useState('')
  const [isAirportRide, setIsAirportRide] = useState(false)
  const [updateData, setUpdateData] = useState('')

  const handleUpdateModal = (data) => {
    let pickup_date_time = moment.utc(data.pickup_date_time).tz("Asia/Kuwait").format("YYYY-MM-DD HH:mm:ss");
    let pickup_date_split = pickup_date_time.split(" ")[0];
    let pickup_time = pickup_date_time.split(" ")[1];
    
    let pickup_date_year = pickup_date_split.split("-")[0];
    let pickup_date_month = pickup_date_split.split("-")[1];
    let pickup_date_day = pickup_date_split.split("-")[2];
    
    let pickup_hour = pickup_time.split(":")[0];
    let pickup_minute = pickup_time.split(":")[1];
    let pickup_second = pickup_time.split(":")[2];
    
    let pickup_date = new Date();
    pickup_date.setDate(pickup_date_day);
    pickup_date.setMonth(pickup_date_month - 1);
    pickup_date.setYear(pickup_date_year);
    pickup_date.setHours(pickup_hour);
    pickup_date.setMinutes(pickup_minute);
    pickup_date.setSeconds(pickup_second);

    setStartDate(pickup_date);
    setUpdateModal(true);
    setPickupPosition({
        lat: data.pickup_latitude,
        lng: data.pickup_longitude,
        add: data.pickup_address
    });

    setUpdateData(data);
    setDestinationPosition({
        lat: data.destination_latitude,
        lng: data.destination_longitude,
        add: data.destination_address
    });

    setTimeout(() => {
        if (data.pickup_address) {
            inputRef.current.value = data.pickup_address;
        }
        if (data.destination_address) {
            inputRef2.current.value = data.destination_address;
        }
    }, 300);

    setNoOfCarForRide(data.multi_pickup_later_car_count);
    setNumber(data.multi_pickup_later_car_count);
    setIsRentalCar(data.is_rental_car);

    if (data.is_rental_car == "1") {
        var long_ride_fare = data.fare * data.multi_pickup_later_car_count;
        setFare(long_ride_fare);

        longRentalCarData.forEach(rental_hour => {
            if (rental_hour.id == data.rental_car_hour) {
                let multiple_car_fare = 0;
                let advance_car_fare = 0;

                if (data.multi_pickup_later_car_count > 1) {
                    multiple_car_fare = rental_hour.hours_price * data.multi_pickup_later_car_count;
                    rental_hour.multi_car_data.forEach(multi_car_data => {
                        if (multi_car_data.multi_car_type_id == data.car_selection_id) {
                            advance_car_fare = multi_car_data.advance_payment_amount * data.multi_pickup_later_car_count;
                            SetAdvanceFare(advance_car_fare);
                        }
                    });
                    setSelectedHourPrice(multiple_car_fare);
                } else {
                    SetAdvanceFare(data.car_rental_advance_payment);
                    setSelectedHourPrice(rental_hour.hours_price);
                }
            }
        });
    } else {
        setFare(data.fare);
    }

    setCarType(data.car_type);
    setRentalCarHour(data.rental_car_hour);
    setPromoCode(data.promo_code);
    setPickupLaterCount(data.pickup_later);
    setRentalCarBrandType(data.rental_car_brand_type);
    setNearLocationValue(data.near_location);
    setIsAirportRide(data.is_airport_ride == "1");
    setCarSelectionId(data.car_selection_id);
    setUpdateRequestID(data.request_id);

    let time_zone = moment.tz.guess();
    setLocalTimeZone(time_zone);
} 
  // const handleUpdateModal = (data) => {
  //   let pickup_date_time = moment.utc(data.pickup_date_time).tz("Asia/Kuwait").format("YYYY-MM-DD HH:mm:ss")
  //   // console.log(data.pickup_date_time)
  //   // console.log(data)
  //   let pickup_date_split = pickup_date_time.split(" ")[0]
  //   let pickup_time = pickup_date_time.split(" ")[1]

  //   let pickup_date_year = pickup_date_split.split("-")[0]
  //   let pickup_date_month = pickup_date_split.split("-")[1]
  //   let pickup_date_day = pickup_date_split.split("-")[2]

  //   let pickup_hour = pickup_time.split(":")[0]
  //   let pickup_minute = pickup_time.split(":")[1]
  //   let pickup_second = pickup_time.split(":")[2]

  //   let pickup_date = new Date()
  //   pickup_date.setDate(pickup_date_day)
  //   pickup_date.setMonth(pickup_date_month - 1)
  //   pickup_date.setYear(pickup_date_year)
  //   pickup_date.setHours(pickup_hour)
  //   pickup_date.setMinutes(pickup_minute)
  //   pickup_date.setSeconds(pickup_second)
    
  //   // console.log(pickup_date_time)
  //   // console.log(pickup_date)
  //   // return false;
  //   setStartDate(pickup_date)
  //   setUpdateModal(true)
  //   setPickupPosition({
  //     lat: data.pickup_latitude,
  //     lng: data.pickup_longitude,
  //     add: data.pickup_address
  //   })
  //   // inputRef.current.value =  data.pickup_address;

  //   setUpdateData(data)
  //   // console.log(rentalCarHour)
  //   setDestinationPosition({
  //     lat: data.destination_latitude,
  //     lng: data.destination_longitude,
  //     add: data.destination_address
  //   })
  //   setTimeout(() => {
  //     if (data.pickup_address) {
  //       inputRef.current.value = data.pickup_address;
  //     }
  //     if (data.destination_address) {
  //       inputRef2.current.value = data.destination_address;
  //     }
  //   }, 300)

  //   setNoOfCarForRide(data.multi_pickup_later_car_count)
  //   setNumber(data.multi_pickup_later_car_count)
  //   setIsRentalCar(data.is_rental_car)
  //   // console.log(data.is_rental_car)
  //   if (data.is_rental_car == "1") {
  //     var long_ride_fare = data.fare * data.multi_pickup_later_car_count
  //     setFare(long_ride_fare)
  //   } else {
  //     setFare(data.fare)
  //   }
  //   setCarType(data.car_type)
  //   setRentalCarHour(data.rental_car_hour)

  //   // console.log(longRentalCarData)
  //   // if (longRentalCarData.length) {
  //   //   longRentalCarData.map((rental_hour) => {
  //   //     // if (rental_hour.id == data.rental_car_hour) {
  //   //     if (rental_hour.id == selectedHourid) {
  //   //       if (data.multi_pickup_later_car_count > 1) {
  //   //         // console.log(rental_hour.multi_car_data)
  //   //         var rentalId = rental_hour.id
  //   //         var rentalHour = rental_hour.hours_price;
  //   //         var rentalValue = rental_hour.hours_value
  //   //         // var advance_car_fare = data.car_rental_advance_payment * data.multi_pickup_later_car_count
  //   //         console.log(rental_hour)
  //   //         // var multiple_car_fare = rentalHour * data.multi_pickup_later_car_count;

  //   //         // var advance_car_fare = data.car_rental_advance_payment * data.multi_pickup_later_car_count
  //   //         // console.log(multiple_rental_car_data)
  //   //         // if(data.car_selection_id == '3'){
  //   //         // console.log(rentalValue, rentalHour)
  //   //         // return false
  //   //           // console.log(rental_h)
  //   //           rental_hour?.multi_car_data.forEach((multi_car_data) => {
  //   //             if(multi_car_data.multi_car_type_id == data.car_selection_id){
  //   //               console.log("data:", multi_car_data)
  //   //               var advance_car_fare = multi_car_data.advance_payment_amount * data.multi_pickup_later_car_count
  //   //               var multiple_car_fare = multi_car_data.hours_price * data.multi_pickup_later_car_count;
  //   //               console.log('advance car:' , advance_car_fare)
  //   //               SetAdvanceFare(advance_car_fare)
  //   //               setSelectedHourPrice(multiple_car_fare)
  //   //             }
  //   //               // console.log(multi_car_data)
  //   //           })
  //   //         // }
  //   //         // else if(data.car_selection_id == '4'){
  //   //         //   rental_hour?.multi_car_data.map((multi_car_data) => {
  //   //         //     console.log(multi_car_data)
  //   //         // })
  //   //         //   console.log('asdqad')
  //   //         // }
  //   //         // return false
  //   //         setSelectedHourId(rentalId)
  //   //         // SetAdvanceFare(advance_car_fare)
  //   //         setSelectedValue(rentalValue)
  //   //         // setSelectedHourPrice(multiple_car_fare)

  //   //       } else {
  //   //         setSelectedHourId(rental_hour.id)
  //   //         SetAdvanceFare(data.car_rental_advance_payment)
  //   //         setSelectedValue(rental_hour.hours_value)
  //   //         setSelectedHourPrice(rental_hour.hours_price)
  //   //       }
  //   //     }
  //   //   })
  //   // }

  //   setPromoCode(data.promo_code)
  //   setPickupLaterCount(data.pickup_later)
  //   setRentalCarBrandType(data.rental_car_brand_type)
  //   // setIsAirportRide(data.is_airport_ride)
  //   setNearLocationValue(data.near_location)
  //   // console.log(data.is_airport_ride)
  //   if (data.is_airport_ride == "0") {
  //     setIsAirportRide(false)
  //   } else if (data.is_airport_ride == "1") {
  //     setIsAirportRide(true)
  //   }
  //   setCarSelectionId(data.car_selection_id)
  //   // console.log(data)
  //   setUpdateRequestID(data.request_id)

  //   // var formatted_pickup_date = TimeZone(pickup_date, 'YYYY-MM-DD hh:mm:ss')
  //   // console.log(formatted_pickup_date)
  //   // setPickupUpdateTime(formatted_pickup_date)
  //   var time_zone = moment.tz.guess()
  //   setLocalTimeZone(time_zone)

  // }



  useEffect(() => {
    const initialFormattedDate = moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss');
    setPickupUpdateTime(initialFormattedDate);
  }, [startDate]);

  const handleDateChange = (date) => {
    setStartDate(date);
    let formattedDate = moment(date).utc().format('YYYY-MM-DD HH:mm:ss');
    // console.log(formattedDate)
    // setStartDate(formattedDate)
    setPickupUpdateTime(formattedDate);
  };

  // const payInAdvanceHandler = () => {
  //   var userWallet = userProfileData?.user_profile.wallet;
  //   var lastRentalAmount = fare;
  //   var numberOfcars = noOfCarForRide;

  //   console.log(userWallet, lastRentalAmount, numberOfcars);
  //   var walletAmount = parseFloat(userWallet) + (parseFloat(lastRentalAmount) * numberOfcars);
  //   var finalPayment = parseFloat(selectedHourPrice) * numberOfcars;
  //   const retval = walletAmount === finalPayment ? 0 : (walletAmount < finalPayment ? -1 : 1);

  //   console.log(walletAmount, finalPayment, retval);
  //   const size = finalPayment - walletAmount;

  //   console.log(`Difference: ${size.toFixed(3)}`);
  //   console.log(size)

  //   if (retval < 0) {
  //     // showDialogForPaySpecialRate(size.toFixed(3));
  //     console.log('no money in wallet')
  //   } else {
  //     // toast.success("10.00 Kd is debited", {
  //     //   position: "top-center",
  //     //   autoClose: 2000,
  //     // });
  //     // console.log('ride_updated')
  //     // return false
  //     long_ride_update();
  //   }
  //   // setIsSpecialPriceDialogShowed(true);
  // };



  const filterSelectHandler = (filter, paid_key, filter_text) => {
    // console.log(filter,paid_key,filter_text)
    // return false
    // setFilterStatus(filter)
    // setFilterPaidStatus(paid_key)
    setFilterText(filter_text);
    setActivePage(1);
    // if(filter == "2")
    // useEffect(() =>{
    // },[])
    setTimeout(async () => {
      setIsLoading(true);
      setIsLoadingPagination(true);
      // $('#pagination').addClass('d-none')
      await get_past_places(filter, paid_key);
      setTimeout(() => {
        setIsLoadingPagination(false);
      }, 1000);
      setIsLoading(false);
    }, 300);
  };



  const pickup_later_update = async () => {

    try {
      var data1 = {
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        pickup_latitude: pickupPosition.lat,
        pickup_longitude: pickupPosition.lng,
        destination_latitude: destinationPosition.lat,
        destination_longitude: destinationPosition.lng,
        pickup_address: pickupPosition.add,
        destination_addrss: destinationPosition.add,
        isAirportRide: isAirportRide ? '1' : '0',
        updateRequestID: updateRequestID,
        // pickup_latitude: lat ? lat : pickupPosition.lat,
        // pickup_longitude: lng ? lng : pickupPosition.lng,
      };

      let form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("request_id", data1.updateRequestID);
      form.append("timezone", localTimeZone);
      form.append("pickup_date_time", pickupUpdateTime);
      // form.append("pickup_date_time", pickup);
      form.append("pickup_latitude", data1.pickup_latitude);
      form.append("pickup_longitude", data1.pickup_longitude);
      form.append("pickup_address", data1.pickup_address);
      form.append("destination_latitude", data1.destination_latitude);
      form.append("destination_longitude", data1.destination_longitude);
      form.append("destination_address", data1.destination_addrss);


      if (data1.isAirportRide == "1") {
        form.append("is_airport_ride", data1.isAirportRide)
        form.append("no_person", "0-1")
        form.append("no_luggage_bags", "1")
        form.append("pickup_later_car_count", '1')
      } else {
        form.append("is_airport_ride", data1.isAirportRide)
        form.append("no_person", "0-1")
        form.append("no_luggage_bags", "1")
        form.append("pickup_later_car_count", number)
      }
      //   if (data1. i == "1") {
      //     map.put("is_airport_ride", "1");
      //     map.put("no_person", "0-1");
      //     map.put("no_luggage_bags", "1");
      //     map.put("pickup_later_car_count", tripHistory.getMultiPickupLaterCarCount());
      //     if (CONSTANT.IS_CAR_BRAND_SELECTION_AIRPORT_RIDE) {
      //         map.put("airport_car_selection_id", tripHistory.getSelectedAirportCarId());
      //     }
      // } else {
      //     map.put("is_airport_ride", "0");
      //     map.put("no_person", "0");
      //     map.put("no_luggage_bags", "0");
      //     if (isPickupLaterRideCount()) {
      //         map.put("pickup_later_car_count", tripHistory.getMultiPickupLaterCarCount());
      //     }
      var res = await pickupLaterUpdateApi(form);
      var data = res.data;
      console.log(res.data)
      if (data.status == "1") {
        get_past_places(0, 0, 1, 1)
        setPickupLaterUpdatedData(data);
        toast.success("Updated Ride details successfully!", {
          position: "top-center",
          autoClose: 2000,
        });
        setUpdateModal(false)
      } else {
        toast.error(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
        console.log("pickup later error", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const long_ride_update = async () => {

    try {
      var data1 = {
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        pickup_latitude: pickupPosition.lat,
        pickup_longitude: pickupPosition.lng,
        destination_latitude: destinationPosition.lat,
        destination_longitude: destinationPosition.lng,
        pickup_address: pickupPosition.add,
        destination_addrss: destinationPosition.add,
        isAirportRide: isAirportRide ? '1' : '0',
        updateRequestID: updateRequestID,
        is_rental_car: isRentalCar ? isRentalCar : 1,
        rental_hours_id: selectedHourid ? selectedHourid : 4,
        rentalCarBrandType: rentalCarBrandType ? rentalCarBrandType : 0,
        nearLocationValue: nearLocationValue ? nearLocationValue : ''
        // pickup_latitude: lat ? lat : pickupPosition.lat,
        // pickup_longitude: lng ? lng : pickupPosition.lng,
      };

      let form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("request_id", data1.updateRequestID);
      form.append("timezone", localTimeZone);
      // form.append("pickup_date_time", pickupUpdateTime);
      form.append("pickup_date_time", pickupUpdateTime);
      form.append("pickup_latitude", data1.pickup_latitude);
      form.append("pickup_longitude", data1.pickup_longitude);
      form.append("pickup_address", data1.pickup_address);
      form.append("destination_latitude", data1.destination_latitude);
      form.append("destination_longitude", data1.destination_longitude);
      form.append("destination_address", data1.destination_addrss);
      form.append("rental_car_brand_type", data1.rentalCarBrandType);
      form.append("rental_car_hour", data1.rental_hours_id);
      form.append("near_location", data1.nearLocationValue);


      if (data1.isAirportRide == "1") {
        form.append("is_airport_ride", data1.isAirportRide)
        form.append("no_person", "0-1")
        form.append("no_luggage_bags", "1")
        form.append("pickup_later_car_count", '1')
      } else {
        form.append("is_airport_ride", data1.isAirportRide)
        form.append("no_person", "0-1")
        form.append("no_luggage_bags", "1")
        form.append("pickup_later_car_count", number)
      }

      var res = await updateUpcomingRideApi(form);
      var data = res.data;
      console.log(res.data)
      if (data.status == "1") {
        get_past_places(0, 0, 1, 1)
        setLongRideData(data);
        toast.success("Updated Ride details successfully!", {
          position: "top-center",
          autoClose: 2000,
        });
        setUpdateModal(false)
      } else {
        toast.error(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
        console.log("long ride issue", data);
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const KuwaitTimeZone = (time, format = "Do MMM YYYY, h:mm:ss a ") => {
    return moment.utc(time).tz("Asia/Kuwait").format(format);
  };

  const updateModalHandler = () => {
    setUpdateModal(false);
  };
  const cancelModalHandler = () => {
    setCancelModal(false)
  }
  const paymentModalHandler = () => {
    setPaymentModal(false)
  }
  // const showPayInAdvanceModal = () => {
  //   setPayInAdvanceModal(false)
  // }
  const fareModal = () => {
    setFareBreakDownModal(false)
  }

  const handleCardModal = () => {
    setPayByCardModal(false)
  }

  const handleClick = (carNumber) => {
    setNoOfCarForRide((prevNumber) => ({
      1: carNumber === 1,
      2: carNumber === 2,
      3: carNumber === 3,
      4: carNumber === 4,
    }));
  };

  const [number, setNumber] = useState();

  const increase = () => {
    setNumber(prevNumber => (prevNumber < 4 ? parseInt(prevNumber) + 1 : prevNumber));
  };

  // if (number == 1) {
  //   // document.getElementsByClassName('fa-minus').addClass('disabled')
  //   $('.fa-minus').attr('disabled')
  // }
  const decrease = () => {
    setNumber(prevNumber => (prevNumber > 1 ? parseInt(prevNumber) - 1 : prevNumber));
  };


  const updateUpcomingHandler = (req_ID) => {

    if (isRentalCar == "0") {
      pickup_later_update(req_ID)
    }
    if (isRentalCar == "1") {
      if (selectedValue != rentalCarHour) {
        if (userProfileData && userProfileData.user_profile && userProfileData.user_profile.wallet !== undefined) {
    
          if (longRentalCarData.length) {
            longRentalCarData.forEach(rental_hour => {
              if (rental_hour.id == selectedHourid) {
                console.log("hour, selected hour id:", updateData, selectedHourid);
    
                let multiple_car_fare = 0;
                let advance_car_fare = 0;
    
                if (updateData.multi_pickup_later_car_count > 1) {
                  multiple_car_fare = updateData.car_rental_advance_payment * updateData.multi_pickup_later_car_count;
                  // console.log("old advance key", updateData.car_rental_advance_payment);
    
                  rental_hour?.multi_car_data.forEach(multi_car_data => {
                    if (multi_car_data.multi_car_type_id == updateData.car_selection_id) {
                      // console.log("data:", multi_car_data);
                      advance_car_fare = multi_car_data.advance_payment_amount * updateData.multi_pickup_later_car_count;
                      // console.log('advance car:', advance_car_fare);
                      SetAdvanceFare(advance_car_fare);
                      console.log(advance_car_fare)                     
                    }
                  });

                  console.log(rental_hour.id , rental_hour.hours_value ,  multiple_car_fare)
                  
                  setSelectedHourId(rental_hour.id);
                  setSelectedValue(rental_hour.hours_value);
                  setSelectedHourPrice(multiple_car_fare);
    
                } else {
                  setSelectedHourId(rental_hour.id);
                  SetAdvanceFare(updateData.car_rental_advance_payment);
                  setSelectedValue(rental_hour.hours_value);
                  setSelectedHourPrice(rental_hour.hours_price);
                }
              }
            });
          }
          
          const userWallet = parseFloat(userProfileData.user_profile.wallet);
          const lastRentalAmount = parseFloat(selectedHourPrice);
          const finalPayment = parseFloat(advanceFare);
          console.log(userWallet , lastRentalAmount , finalPayment)

          const walletAmount = userWallet + lastRentalAmount;
          const retval = walletAmount === finalPayment ? 0 : (walletAmount < finalPayment ? -1 : 1);
          const size = finalPayment - walletAmount;

          console.log("wallet , finalamt , retval:" , walletAmount , finalPayment , retval)

          if (retval < 0) {
            setPaymentModal(true);
            setWalletDiff(size.toFixed(3));
            console.log('no money in wallet');
          } else if(retval == " "){
            long_ride_update(req_ID);
            console.log(userProfileData)
            console.log('...');
          }
    
          setPayInAdvanceModal(true);
    
        } else {
          console.log('User profile or wallet data is missing');
          // console.log(userProfileData.user_profile.wallet)
          // console.log(userWallet)
          // Uncomment the following line if you want to call long_ride_update when wallet data is missing
          // long_ride_update(req_ID);
        }
      }
    }
    
    // return false
  }

  const get_past_places = async (
    filter_status = 0,
    filter_paid_status = 0,
    page = 1,
    pickup_later = 0
  ) => {
    try {
      const data1 = {
        user_type: userDetail?.user_type || "", // 0 android, 1 ios
        customer_id: userDetail?.user_profile_id || "",
      };

      const form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("customer_id", data1.customer_id);
      form.append("filter", filter_status);
      form.append("pickup_later", pickup_later);
      form.append("paid_key", filter_paid_status);
      form.append("page", page);

      setIsLoading(true);
      setIsLoadingPagination(true);
      const res = await getPastRidesApi(form);
      const data = res.data;
      if (data.status == "1") {
        // let total_page_count =  data.total_page_count
        const updatedRequests = data.requests.map(async (req) => {
          const imageUrl = req.map_image;
          const driverImage = req.driver_image;
          let updatedReq = { ...req };

          // try {
          //   await axios.get(req?.map_image || req?.driver_image);
          // } catch (error) {
          //   if (error.response && error.response.status === 404) {
          //     let updatedUrl = imageUrl.replace("https://rovemain.rovesolution.com/", "https://www.riderove.com/dev_rove/");
          //     // updatedUrl = updatedUrl.replace("https://roveimage.s3.me-south-1.amazonaws.com/images/","https://www.riderove.com/dev_rove/");
          //     let updatedDriver = driverImage.replace("https://roveimage.s3.me-south-1.amazonaws.com/images/", "https://www.riderove.com/dev_rove/uploads/");
          //     updatedReq.driver_image = updatedDriver
          //     updatedReq.map_image = updatedUrl;
          //   }
          // }
          console.log(req.status);
          if (req.status != "1" && req.status != "3" && req.status != "") {
            var url = req?.map_image;
            const response = await fetch(url, { method: "get" });
            if (response && response.status === 404) {
              let updatedUrl = imageUrl.replace(
                "https://rovemain.rovesolution.com/",
                "https://www.riderove.com/dev_rove/"
              );
              // updatedUrl = updatedUrl.replace("https://roveimage.s3.me-south-1.amazonaws.com/images/","https://www.riderove.com/dev_rove/");
              let updatedDriver = driverImage.replace(
                "https://roveimage.s3.me-south-1.amazonaws.com/images/",
                "https://www.riderove.com/dev_rove/uploads/"
              );
              updatedReq.driver_image = updatedDriver;
              updatedReq.map_image = updatedUrl;
            }
          } else if (req.status == "") {
            var url = req?.map_image;
            let updatedUrl = url.replace(
              "https://rovemain.rovesolution.com/",
              "https://www.riderove.com/dev_rove/"
            );
            updatedReq.map_image = updatedUrl;

            var multi_car_image = req.multi_car_image;
            multi_car_image = multi_car_image.replace(
              "https://rovemain.rovesolution.com/",
              "https://www.riderove.com/dev_rove/"
            );
            updatedReq.multi_car_image = multi_car_image;

            // console.log(updatedReq);
          }

          var originalDate = req.created_at;
          // console.log("originalDate",originalDate)
          originalDate = TimeZone(originalDate);
          // console.log("convert date",originalDate)
          updatedReq.orignalDate = originalDate;



          var upcoming_pickup_time = req.pickup_date_time
          upcoming_pickup_time = KuwaitTimeZone(upcoming_pickup_time, 'Do MMM YYYY, h:mm a')
          updatedReq.upcoming_pickup_time = upcoming_pickup_time
          // console.log(upcoming_pickup_time)
          // setPickupUpdateTime(upcoming_pickup_time)

          var update_upcoming_time = req.pickup_date_time
          // update_upcoming_time = KuwaitTimeZone(update_upcoming_time)
          // var startingDate = new Date(update_upcoming_time)
          // console.log(startingDate)
          // setStartDate(startingDate)
          // setSta(update_upcoming_time)

          var status = updatedReq.status;
          if (status == "1" && updatedReq.is_arrived == "1") {
            updatedReq.status_text = "Arrived";
            $("#btn-payment-sucess-" + updatedReq.request_id).removeClass(
              "btn-success"
            );
            $("#btn-payment-sucess-" + updatedReq.request_id).addClass(
              "status_accept"
            );
          } else if (status == "1") {
            updatedReq.status_text = "Accepted";
            $("#btn-payment-sucess-" + updatedReq.request_id).removeClass(
              "btn-success"
            );
            $("#btn-payment-sucess-" + updatedReq.request_id).addClass(
              "status_accept"
            );
          } else if (status == "3") {
            updatedReq.status_text = "Started";
            $("#btn-payment-sucess-" + updatedReq.request_id).removeClass(
              "btn-success"
            );
            $("#btn-payment-sucess-" + updatedReq.request_id).addClass(
              "status_accept"
            );
          } else if (status == "4") {
            updatedReq.status_text = "Finished";
          } else if (status == "2") {
            updatedReq.status_text = "Cancelled";
            $("#btn-payment-sucess-" + updatedReq.request_id).removeClass(
              "btn-success"
            );
            $("#btn-payment-sucess-" + updatedReq.request_id).addClass(
              "status_cancel"
            );
          } else if (status == "0" || status == "5") {
            updatedReq.status_text = "Unattended";
          }

          if (updatedReq.pickup_later == "0") {
            if (updatedReq.transaction_status != null) {
              if (
                updatedReq.transaction_status == "PENDING" ||
                updatedReq.transaction_status == "0"
              ) {
                if (status == "0" || status == "1" || status == "3") {
                  // $('#btn-payment-due-' + updatedReq.request_id).addClass('d-none');
                } else {
                  // console.log(updatedReq.request_id ,"-" , status , '-' , updatedReq.transaction_status)
                  updatedReq.status_due = "Payment Due";
                  setTimeout(() => {
                    $("#btn-payment-due-" + updatedReq.request_id).removeClass(
                      "d-none"
                    );
                    $("#btn-payment-due-" + updatedReq.request_id).addClass(
                      "btn-danger"
                    );
                    $("#btn-payment-sucess-" + updatedReq.request_id).addClass(
                      "d-none"
                    );
                  }, 300);
                }
              } else {
                if (
                  status == "2" ||
                  status == "0" ||
                  status == "1" ||
                  status == "3"
                ) {
                  // $('#btn-payment-due-' + updatedReq.request_id).addClass('d-none')
                } else {
                  updatedReq.status_text = "Paid";
                }
              }
            } else {
              if (status == "0" || status == "1" || status == "3") {
                // $('#btn-payment-due-' + updatedReq.request_id).addClass('d-none')
              } else {
                updatedReq.status_due = "Payment Due";
                $("#btn-payment-due-" + updatedReq.request_id).removeClass(
                  "d-none"
                );
                $("#btn-payment-due-" + updatedReq.request_id).addClass(
                  "btn-danger"
                );
              }
            }
          } else {
            if (
              updatedReq.transaction_status == "PENDING" ||
              updatedReq.transaction_status == "0"
            ) {
              if (status == "0" || status == "1" || status == "3") {
                // $('#btn-payment-due-' + updatedReq.request_id).addClass('d-none')
              } else {
                updatedReq.status_due = "Payment Due";
                $("#btn-payment-due-" + updatedReq.request_id).removeClass(
                  "d-none"
                );
                $("#btn-payment-due-" + updatedReq.request_id).addClass(
                  "btn-danger"
                );
              }
            } else {
              if (
                status == "2" ||
                status == "0" ||
                status == "1" ||
                status == "3"
              ) {
                // $('#btn-payment-due-' + updatedReq.request_id).addClass('d-none')
              } else {
                updatedReq.status_text = "Paid";
              }
            }
          }
          let items = [];
          let activePage = 1;

          // let total_page_count = pastRideResponse?.total_page_count
          // setTotalPage(total_page_count)
          setTotalPage(data.total_page_count);
          setLimitPerPage(data.requests.length);
          // console.log(totalPage)
          return updatedReq;
        });

        // Wait for all promises to complete before updating data
        // let total_page_count =
        // if(pickup_later == 1){
        //   setUpcomingData(data)
        // }
        // console.log(upcomingData)

        // if (pickup_later) {
        //   const pickupRequest = data.requests.map(async (req) => {
        //     let updatedReq = { ...req };

        //     var ride_pickup_time = req.pickup_date_time
        //     ride_pickup_time = KuwaitTimeZone(ride_pickup_time)
        //     // console.log("convert date",originalDate)
        //     updatedReq.ride_pickup_time = ride_pickup_time;
        //     console.log(ride_pickup_time)

        //   })
        //   // var req_pickup = data.requests
        //   setUpcomingData(data.requests);
        // }
        // else{

        // }
        // console.log(updatedRequests)
        Promise.all(updatedRequests)
          .then((updatedData) => {
            data.requests = updatedData;
            if (!pickup_later) {
              setPastRideData(data.requests);
              setPastRideResponse(data);
            } else {
              console.log("upcoming----");
              console.log(data.requests);
              setUpcomingData(data.requests);
            }
            // setPastRideData(data.requests)
          })
          .catch((error) => {
            console.error("Error updating image paths:", error);
          });
      } else {
        if (data.message == "no data available") {
          setPastRideData([]);
          setTotalPage(0);
        }
        console.log("Error", data);
      }
      setTimeout(() => {
        setIsLoadingPagination(false);
      }, 1000);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handlePaginationChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const showPastRideHandler = () => {
    setShowPastRides(true);
    get_past_places(0, 0, 1, 0);
  };

  const showUpcomingRideHandler = () => {
    setShowPastRides(false);
    get_past_places(0, 0, 1, 1);
  };

  const handleCancelBtn = (requestID) => {
    setCancelModal(true);
    setRequestID(requestID);
  };

  const handlelongRentalHours = () => {
    var selected_hours_id = $('#selected_hour_id option:selected').attr('data-id')
    var select_hour_price = $('#selected_hour_id option:selected').attr('price')
    var select_value = $('#selected_hour_id option:selected').attr('value')
    setSelectedHourId(selected_hours_id)
    setSelectedValue(select_value)
    if (isRentalCar == "1") {
      var fare_long_ride = noOfCarForRide * select_hour_price
      setSelectedHourPrice(fare_long_ride)
    } else {
      setSelectedHourPrice(select_hour_price)
    }

  }

  const cancelUpcomingRideHandler = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
        user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
      };

      // document.getAttr(data_req)
      // console.log(Element.getAttr("data_req_id"))
      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("ride_request_id", requestID);
      // console.log(requestID)

      var res = await removeHistoryApi(form);
      var data = res.data;
      if (data.status == "1") {
        console.log("removed succesfully");
        toast.success("Cancelled Ride Successfully", {
          position: "top-center",
          autoClose: 1000,
        });
        setCancelModal(false)
        get_past_places(0, 0, 1, 1)
      } else {
        toast.success("Error while cancelling ride", {
          position: "top-center",
          autoClose: 1000,
        });
        console.log("Error : error while removing ride", data);
      }
    } catch (error) {
      console.log(error);
    }
  };



  const get_long_rental_car_data = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
        user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await getLongRentalCarApi(form);
      var data = res.data;
      // console.log(data)
      if (data.status == "1") {
        setLongRentalCarData(data.rental_car_hours);
      } else {
        console.log("Error :", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fareBreakDownModalHandler = () => {
    setFareBreakDownModal(true)
    get_fare_break_down()
  }


  if (totalPage == 0) {
    $("#pagination").addClass("d-none");
  }

  const dateInputRef = useRef(null);


  const dateOpenHandler = (e) => {
    // dateInputRef.current.showPicker();
    $('#date').removeClass('d-none')
  }

  const RebookHandler = (e, data) => {
    // setReceiptNavigate(false)
    var rebookData = {
      pickup: data.pickup_latitude + "," + data.pickup_longitude,
      destination: data.destination_latitude + "," + data.destination_longitude,
    };
    e.stopPropagation();
    navigate("/dashboard");
    // console.log(data.pickup_latitude , data.pickup_longitude)
    // console.log(data.destination_latitude , data.destination_longitude)
    localStorage.setItem("rebookdata", JSON.stringify(rebookData));
  };

  const inputRef = useRef();

  const pickupAddressChange = (e) => {
    if (e.target.value == "") {
      // setPickupBlank(true)
      setPickupPosition();
      setRouteDirections();
      setZoom(13);
      setPosition({
        lat: currantPosition?.lat,
        lng: currantPosition?.lng,
        text: "",
      });
    }
  };

  const destinationAddressChange = (e) => {
    if (e.target.value == "") {
      setDestinationPosition({});
      setRouteDirections();
      setPath()
      setPosition({
        lat: currantPosition?.lat,
        lng: currantPosition?.lng,
        text: "",
      });
      setZoom(13);
      setRequestRide(false);
    }
  };

  // console.log(pickupLocation)

  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                var lat = position.coords.latitude;
                var long = position.coords.longitude;
                if (pickupPosition == '') {
                  near_search_location(lat, long).then((data) => {
                    var userLocation = data.user_location_data;
                    inputRef.current.value = userLocation.title_address;
                    setPickupPosition({
                      lat: parseFloat(userLocation.latitude),
                      lng: parseFloat(userLocation.longitude),
                      // text: userLocation.address.suburb,
                      text: near_search_condition(data)
                        /* "Closest ride to you " +
                        userLocation.time_duration +
                        " Min" */,
                      add: userLocation?.title_address,
                    });
                    setPosition({
                      lat: parseFloat(userLocation.latitude),
                      lng: parseFloat(userLocation.longitude),
                      // text: userLocation.address.suburb,
                      // add: userLocation.display_name,
                    });
                    setCurrantPosition({
                      lat: parseFloat(userLocation.latitude),
                      lng: parseFloat(userLocation.longitude),
                      text: userLocation?.title_address,
                      add: userLocation?.title_address,
                    });
                  });
                }
              },
              (error) => {
                console.error("Error getting geolocation:", error);
              },
              { enableHighAccuracy: true, timeout: 1000, maximumAge: 1000 }
            );
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                var lat = position.coords.latitude;
                var long = position.coords.longitude;
                near_search_location(lat, long).then((data) => {
                  var userLocation = data?.user_location_data;

                  inputRef.current.value = userLocation?.title_address;

                  setPickupPosition({
                    lat: parseFloat(userLocation.latitude),
                    lng: parseFloat(userLocation.longitude),
                    // text: userLocation.address.suburb,
                    text: near_search_condition(data),
                    /* "Closest ride to you " +
                      userLocation.time_duration +
                      " Min" */
                    add: userLocation?.title_address,
                  });
                  setPosition({
                    lat: parseFloat(userLocation.latitude),
                    lng: parseFloat(userLocation.longitude),
                    // text: userLocation.address.suburb,
                    // add: userLocation.display_name,
                  });
                  setCurrantPosition({
                    lat: parseFloat(userLocation.latitude),
                    lng: parseFloat(userLocation.longitude),
                    text: userLocation?.title_address,
                    add: userLocation?.title_address,
                  });
                });
                // var url = "http://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lang + "&sensor=true&key=AIzaSyD7ajC4AJpRr3_Yz8e48FsIxHVqvP1RNkQ";
                // var url =
                //   "https://geocode.maps.co/reverse?lat=" +
                //   lat +
                //   "&lon=" +
                //   long +
                //   "&api_key=6617797c87997345174567fer0b83a3";
                // "&api_key=AIzaSyDskRkPZZjm3j9Trw55LGhJEe4AOA1I_fg";
                // fetch(url)
                //   .then((result) => {
                //     if (result.ok) {
                //       console.log(result);
                //       return result.json();
                //     } else {
                //       // alert('something went wrong while fetching location....')
                //       var res = {};
                //       res.display_name = "";
                //       return res;
                //     }
                //   })
                //   .then((result) => {
                //     if (result !== undefined) {
                //       setPickupPosition({
                //         lat: parseFloat(result.lat),
                //         lng: parseFloat(result.lon),
                //         // text: result.address.suburb,
                //         text: result.display_name,
                //         add: result.display_name,
                //       });
                //       setPosition({
                //         lat: parseFloat(result.lat),
                //         lng: parseFloat(result.lon),
                //         text: result.address.suburb,
                //         add: result.display_name,
                //       });
                //       setCurrantPosition({
                //         lat: parseFloat(result.lat),
                //         lng: parseFloat(result.lon),
                //         text: result.address.suburb,
                //         add: result.display_name,
                //       });
                //       // setFormData({
                //       //   ...formData,
                //       //   pick_up: result.display_name,
                //       // });
                //     }
                //   })
                //   .catch((err) => console.log(err));
              },
              (error) => {
                console.error("Error getting geolocation:", error);
              },
              { enableHighAccuracy: true, timeout: 1000, maximumAge: 1000 }
            );
            alert('Location permisson issue "prompt"....');
          } else if (result.state === "denied") {
            alert('Location permisson  "denied"....');
          }
          /* result.onchange = function () {
            console.log(result.state);
          }; */
        });
    } else {
      alert("Sorry location not available!");
    }
  };

  const near_search_location = async (lat, lng) => {
    try {
      var data1 = {
        isFromDestination: 0,
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        // latitude: pickupPosition ? pickupPosition.lat : position.lat,
        // longitude:pickupPosition ?  pickupPosition.lng : position.lng,
        latitude: lat ? lat : pickupPosition.lat,
        longitude: lng ? lng : pickupPosition.lng,
        title_adress: "",
        car_selection_id: 0,
      };
      let form = new FormData();
      form.append("isFromDestination", data1.isFromDestination);
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("latitude", data1.latitude);
      form.append("title_adress", data1.title_adress);
      form.append("longitude", data1.longitude);
      form.append("car_selection_id", data1.car_selection_id);

      var res = await nearSearchLocationApi(form);
      var data = res.data;
      if (data.status == "1") {
        setNearSearchLocation(data);
        return data;
        // pickupPosition.text =
        //   data?.user_location_data?.time_duration + " minutes";
      } else {
        setNearSearchLocation(data);
        console.log("near_search_location", data);
        return data;
      }
    } catch (error) {
      console.log("Error near_search_location", error);
    }
  };



  const handlePickupDrag = (event) => {
    var lat = event.latLng.lat();
    var lng = event.latLng.lng();

    near_search_location(lat, lng).then((data) => {
      var userLocation = data?.user_location_data;

      inputRef.current.value = userLocation?.title_address;
      var p = {
        lat: parseFloat(userLocation.latitude),
        lng: parseFloat(userLocation.longitude),
        // text: userLocation.address.suburb,
        text: near_search_condition(data)
          /* data.status == "1"
            ? "Closest ride to you " + userLocation.time_duration + " Min"
            : "" */,
        add: userLocation?.title_address,
      };
      setPickupPosition(p);
      get_driver()
      if (Object.keys(destinationPosition).length && p) {
        price_calculation(
          pickupPosition.lat,
          pickupPosition.lng,
          lat,
          lng
        ).then((p_r) => {
          var esti_pr =
            parseFloat(p_r.estimatedprice) - parseFloat(p_r.discount_price);
          esti_pr = esti_pr.toFixed(3);
          setDestinationPosition({
            ...destinationPosition,
            text: "KD " + esti_pr,
          });
        });
        aftersetDestinationPosition(p, destinationPosition);
      }
    });

    if (pickupPosition && Object.keys(destinationPosition).length) {
      // near_search_location();
      // price_calculation();
    }
    try {
      // const geocoder = new window.google.maps.Geocoder();
      // const latlng = {
      //   lat: parseFloat(lat),
      //   lng: parseFloat(lng),
      // };
      /*  geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            console.log(results[0]);
            var add = results[0].formatted_address;
            inputRef.current.value = add;
            var p = { lat: lat, lng: lng, text: add, add: add };
            setPickupPosition(p);
            if (destinationPosition && p) {
              aftersetDestinationPosition(p, destinationPosition);
            }
          } else {
            console.log("No results found");
          }
        } else {
          console.log("Geocoder failed due to: " + status);
        }
      }); */
    } catch (error) {
      console.log(error);
    }
  };
  const near_search_condition = (data) => {
    if (data.status == "1") {
      if (data.isDisableArea == "1") {
        return "Rove doesn't operate here";
      } else if (data.is_user_waiting_ride_available == "1") {
        return "Waiting";
      } else if (data.is_vip_waiting_ride == "1") {
        return "Waiting";
      } else {
        return (
          "Closest ride to you " +
          data?.user_location_data.time_duration +
          " Min"
        );
      }
    } else if (data.status == "0") {
      if (data.isDisableArea == "1") {
        return "Rove doesn't operate here";
      } else if (data.is_user_waiting_ride_available == "1") {
        return "Waiting";
      } else if (data.is_vip_waiting_ride == "1") {
        return "Waiting";
      } else if (
        !(parseInt(data.driver_login_count) > 0) &&
        data.near_by_car_count == "0"
      ) {
        // console.log("hello",!(parseInt(data.driver_login_count) > 0))
        return "Rove doesn't operate here";
      } else if (
        !(parseInt(data.driver_login_count) > 0) &&
        data.free_driver_count == "0"
      ) {
        return "All drivers are busy";
      } else {
        return "Driver not available";
      }
    }
  };


  const DirectionsService = new google.maps.DirectionsService();
  const viewReciept = (data) => {
    // setReceiptNavigate(true)
    // if(receiptNavigate == true){
    navigate("/ride-reciept/" + data.request_id);
    // }
  };

  // console.log(upcomingData)

  return (
    <>
      <ToastContainer />
      <main className="main">
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <section className="section mt-40 mb-100 " id="my-trip">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-md-1 col-lg-1 "></div>
              <div className="col-md-2 col-lg-2 mb-50">
                <button
                  onClick={showPastRideHandler}
                  className={`btn text-profile ${showPastRides ? "activ" : ""}`}
                >
                  Past Rides
                </button>
                <button
                  onClick={showUpcomingRideHandler}
                  className={`btn text-profile ${!showPastRides ? "activ" : ""
                    }`}
                >
                  Upcoming Rides
                </button>
              </div>
              <div className="col-md-6 col-lg-6 ">
                {showPastRides ? (
                  <div className="row">
                    <div className="col-md-12 d-flex justify-content-between mb-30 ">
                      <h2 style={{ fontWeight: "600" }}>Past Rides</h2>
                      <div>
                        <div className="dropdown-afterlogin">
                          <Link
                            className="btn btn-link-globe dashbord-selectme me-2 justify-content-center align-items-center"
                            to="/my-trips"
                          >
                            <img
                              src="/assets/imgs/ride_icons/filter-icon.png"
                              alt="filter-icon"
                            />

                            <span className="ms-3 me-1">{filterText}</span>
                            <i
                              className="fa fa-chevron-down"
                              style={{ fontSize: "12px", marginLeft: "2px" }}
                            ></i>
                          </Link>
                          <div className="dropdown-content trip-history">
                            <Link
                              onClick={() => {
                                filterSelectHandler(0, 0, "All Trips");
                              }}
                            >
                              All Trips
                            </Link>
                            <Link
                              onClick={() => {
                                filterSelectHandler(4, 0, "Completed");
                              }}
                            >
                              Completed
                            </Link>
                            <Link
                              onClick={() => {
                                filterSelectHandler(2, 0, "Cancelled");
                              }}
                            >
                              Cancelled
                            </Link>
                            <Link
                              onClick={() => {
                                filterSelectHandler(0, 1, "Paid");
                              }}
                            >
                              Paid
                            </Link>
                            <Link
                              onClick={() => {
                                filterSelectHandler(0, 2, "Unpaid");
                              }}
                            >
                              Unpaid
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isLoading ? (
                      <div className="d-flex justify-content-center align-items-center mt-5 ">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    ) : pastRideData?.length > 0 ? (
                      pastRideData.map((data, index) => (
                        <>
                          <div
                            className="_css-imdnMt mb-5"
                            key={index}
                            data-reqid={data.request_id}
                            onClick={() => {
                              viewReciept(data);
                            }}
                          >
                            <img className="map_image" src={data.map_image} />

                            <div className="_css-cAseJQ d-flex justify-content-between">
                              <div className=" ">
                                <button
                                  className={`btn btn-rebook-trip btn-primary py-2 `}
                                  onClick={(e) => RebookHandler(e, data)}
                                >
                                  <i className="bi bi-arrow-clockwise "></i>
                                  {/* <i className="fa-solid fa-arrow-rotate-right" style={{ color: 'white' }}></i> */}
                                  ReBook
                                </button>
                              </div>
                              <div
                                className="d-flex w-50"
                                onClick={() => {
                                  viewReciept(data);
                                }}
                              >
                                <img
                                  src={data.driver_image}
                                  className="dropbtn"
                                  style={{ height: "55px", width: "55px" }}
                                  alt="person"
                                />
                                <div className="mx-3">
                                  <h6 className="fw-bold">
                                    {data.driver_name}
                                  </h6>
                                  <p className="fw-bold">
                                    {data.driver_car_name}
                                  </p>
                                </div>
                              </div>

                              <div className="d-flex justify-content-end w-100 mx-3">
                                <div className=" order-2 order-md-1 order-lg-1">
                                  <h6 className="fw-bold">
                                    {data.orignalDate}
                                  </h6>
                                  <p className="fw-bold">KD {data.fare}</p>
                                </div>
                                {/* <div className="order-1 order-md-2  order-lg-3"> */}
                                <button
                                  className={`btn btn-payment-due py-1 d-none`}
                                  id={`btn-payment-due-` + data.request_id}
                                >
                                  {data.status_due}
                                </button>
                                <button
                                  className={`btn btn-success btn-status-trip border-none py-1`}
                                  id={`btn-payment-sucess-` + data.request_id}
                                >
                                  {data.status_text}
                                </button>
                                {/* </div> */}
                              </div>
                            </div>
                          </div>

                          <div
                            onClick={viewReciept}
                            className="_css-imdnMt mb-5"
                          >
                            {/* {showPastRides ? (
                          <div className="_css-cAseJQ d-flex justify-content-between">
                            <div className="d-flex w-50">
                              <img
                                src="/assets/imgs/ride_icons/Rider-image.jpg"
                                className="dropbtn"
                                style={{ height: "55px", width: "55px" }}
                                alt="person"
                              />
                              <div className="mx-3">
                                <h6 className="fw-bold">{data.driver_name}</h6>
                                <p className="fw-bold">Range Rover Sport</p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end w-50 mx-3">
                              <div className="order-2 order-md-1 order-lg-1">
                                <h6>Today 04:04 PM</h6>
                                <p>KD 18.606</p>
                              </div>
                              <div className="order-1 order-md-2 order-lg-2">
                                <button className="btn btn-danger py-1 ms-3 disabled">
                                  Cancelled
                                </button>
                              </div>
                            </div>
                          </div>) : <div></div>} */}
                          </div>
                        </>
                      ))
                    ) : (
                      <h2 className="text-center">No data found.!</h2>
                    )}
                    {/* {console.log(totalPage , pastRideData.length)} */}
                    {isLoadingPagination && totalPage ? (
                      ""
                    ) : (
                      <div
                        style={{ display: "block", width: 700 }}
                        className="d-flex justify-content-end"
                        id="pagination"
                      >
                        <Pagination
                          activePage={activePage}
                          className={activePage ? "active" : ""}
                          itemsCountPerPage={limitPerPage}
                          totalItemsCount={totalPage * limitPerPage}
                          pageRangeDisplayed={5}
                          itemclassName="page-item"
                          linkclassName="page-link"
                          onChange={handlePaginationChange}
                        />
                      </div>
                    )}
                  </div>
                ) : upcomingData?.length > 0 ? (
                  upcomingData.map((data, index) => (
                    <div className="row" key={data.request_id}>
                      {/* <div className="col-md-12 d-flex justify-content-between mb-30">
                        <h2 style={{ fontWeight: "600" }}>Upcoming Rides</h2>
                      </div> */}
                      <div className="_css-imdnMt mb-5">
                        <div
                          className="_css-cAseJQ  "
                          data_req_id={data.request_id}
                        >
                          <div className=" col-12 ">
                            <div className="d-flex mx-3 justify-content-between">
                              <h6 className="fw-bold">{data.upcoming_pickup_time} </h6>
                              {/* <h6 className="fw-bold text-rove">{data.destination_address ? data.fare : 'No Estimated price'}{"  "}KD</h6> */}
                              <h6 className="fw-bold text-rove">{data.destination_address ? data.fare : (data.is_rental_car == "1" ? (data.fare * data.multi_pickup_later_car_count) : data.is_airport_ride == "1" ? 'No Estimated price' : 'No Estimated price')}{"  "}KD</h6>
                            </div>
                          </div>
                          {/* <hr /> */}
                          <div className="row mt-3 ">
                            <div className="mx-3 col-12 d-flex mb-3">
                              <div className="col-sm-1 col-md-1">
                                <span>
                                  <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    data-baseweb="icon"
                                  >
                                    <title>search</title>
                                    <path
                                      fillRule="evenodd"
                                      clip-rule="evenodd"
                                      d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm5-2a5 5 0 1 1-10 0 5 5 0 0 1 10 0Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </span>
                              </div>
                              <div className="col-sm-11-col-md-11">
                                <p className="mb-0 text-secondary">Pickup</p>
                                <h6 className="fw-bold text-rove  mb-0">
                                  {data.pickup_address
                                    ? data.pickup_address
                                    : ""}
                                </h6>
                              </div>
                            </div>
                            <div className="mx-3 col-12 d-flex mb-3">
                              <div className="col-sm-1 col-md-1">
                                <span>
                                  <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    data-baseweb="icon"
                                  >
                                    <title>search</title>
                                    <path
                                      fillRule="evenodd"
                                      clip-rule="evenodd"
                                      d="M14 10h-4v4h4v-4ZM7 7v10h10V7H7Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </span>
                              </div>
                              <div className="col-sm-11-col-md-11">
                                <p className="mb-0 text-secondary">
                                  Destination
                                </p>
                                <h6 className="fw-bold text-rove  mb-0">
                                  {data.destination_address
                                    ? data.destination_address
                                    : "Destination not available."}
                                </h6>
                              </div>
                            </div>
                          </div>
                          <hr />
                          {data.is_rental_car == 1 ? (
                            <>
                              <div className="d-flex ms-4  justify-content-start ">
                                <div className="col-md-12  d-flex justify-content-between">
                                  <h6 className="fw-bold d-flex align-items-center text-rove">
                                    Long Ride Duration
                                  </h6>
                                  <div className="col-md-6">
                                    <h6 className="text-rove d-flex text-rove fw-bold align-items-center">
                                      {data.rental_car_hour} hours
                                    </h6>
                                  </div>
                                </div>
                                {/* <h6 className="fw-bold text-center">2</h6>   */}
                              </div>
                              <hr />
                            </>
                          ) : (
                            ""
                          )}
                          {data.is_rental_car == 0 || data.multi_pickup_later_car_count != 1 ? (
                            <>
                              <div className="d-flex justify-content-start ms-4 ">
                                <div className=" col-md-7 d-flex justify-content-between">
                                  <h6 className="fw-bold d-flex align-items-center text-rove">
                                    Number of Cars
                                  </h6>
                                  <div className="vertical-line"></div>
                                  <div className="d-flex align-items-center">
                                    <i
                                      className="fa-solid fa-car-rear "
                                      style={{ color: "#1f3c4b" }}
                                    ></i>
                                    <h6 className="text-rove fw-bold fs-5  mx-2">
                                      {data.multi_pickup_later_car_count}
                                    </h6>
                                    {/* <img
                                      src="/assets/imgs/ride_icons/car_type_economy.png"
                                      className="dropbtn"
                                      style={{ height: "50px", width: "60px" }}
                                      alt="person"
                                    />   {data.multi_pickup_later_car_count} */}
                                  </div>
                                </div>
                                {/* <h6 className="fw-bold text-center">2</h6>   */}
                              </div>
                              <hr />
                            </>
                          ) : (
                            ""
                          )}
                          {data.is_rental_car == 1 ? (
                            <>
                              <div className="d-flex ms-4 justify-content-start ">
                                <div className=" col-md-12 d-flex justify-content-between">
                                  <h6 className="fw-bold d-flex align-items-center text-rove">
                                    Selected Cars
                                  </h6>
                                  {/* <div className="vertical-line1"></div> */}
                                  <div className="d-flex col-md-6">
                                    {/* <img
                                      src="/assets/imgs/ride_icons/car_type_economy.png"
                                      className="dropbtn"
                                      style={{ height: "50px", width: "60px" }}
                                      alt="person"
                                    /> */}
                                    <img
                                      src={data.multi_car_image}
                                      className="dropbtn"
                                      style={{ height: "50px", width: "60px" }}
                                      alt="person"
                                    />
                                    <p className="text-rove fw-bold">
                                      {data.selected_carType_name
                                        ? data.selected_carType_name
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                                {/* <h6 className="fw-bold text-center">2</h6>   */}
                              </div>
                              <hr />
                            </>
                          ) : (
                            ""
                          )}
                          <div className="row ">
                            <div className="col-md-12">
                              <div className="d-flex justify-content-end">
                                <button
                                  className={`btn btn-primary py-1 ms-3 `}
                                  onClick={() => handleUpdateModal(data)}
                                >
                                  Update
                                </button>
                                <button
                                  className={`btn  btn-cancel-trip py-1 ms-3`}
                                  onClick={() =>
                                    handleCancelBtn(data.request_id)
                                  }
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="_css-imdnMt mb-5"
                      // onClick={() => {
                      //   navigate("/dashboard");
                      // }}
                      >
                        {/* <div
                    role="img"
                    aria-label="No upcoming trips"
                    className="_css-iPuekZ"
                  ></div> */}
                        {/* 
                        <MapWithAMarker
                          containerElement={<div style={{ height: `30vh` }} />}
                          mapElement={<div style={{ height: `100%` }} />}
                          position={position}
                          pickupPosition={pickupPosition}
                          destinationPosition={destinationPosition}
                          routeDirections={routeDirections}
                        /> */}

                        {/* <div className="_css-cAseJQ d-flex justify-content-between">
                          <div className="d-flex w-50">
                            <img
                              src="/assets/imgs/ride_icons/car_type_economy.png"
                              className="dropbtn"
                              style={{ height: "50px", width: "60px" }}
                              alt="person"
                            />
                            <div className="mx-3">
                              <h6 className="fw-bold">Selected car</h6>
                              <p className="fw-bold">Economy</p>
                            </div>
                            <div className="mx-3">
                              <h6 className="fw-bold">Long ride duration</h6>
                              <p className="fw-bold">4 hours</p>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end w-50 mx-3">
                            <div className="order-2 order-md-1 order-lg-1">
                              <h6>Today 04:04 PM</h6>
                              <p>KD 18.060</p>
                            </div>
                            <div className="d-flex flex-column gap-1 order-1 order-md-2 order-lg-2">
                              <button
                                className={`btn btn-secondary py-1 ms-3 disabled`}
                              >
                                Update
                              </button>
                              <button
                                className={`btn  btn-danger py-1 ms-3 disabled`}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">There is no futures rides,book your ride</div>
                )}
              </div>
              <div className="col-md-3 col-lg-3 px-5 trip-last-section">
                <img
                  // src="/assets/imgs/icons/my-trip-2.svg"
                  src="/assets/imgs/bg/cars2.jpg"
                  alt="my-trip-2"
                  className="mb-3"
                />
                <h6>Get a ride in minutes</h6>
                <p>
                  Book an Rove from a web browser, no app install necessary.
                </p>
                <div className="mt-20 w-70">
                  <Link
                    className="btn btn-primary d-flex justify-content-center align-items-center gap-3"
                    to="/dashboard"
                  >
                    Request a Ride
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>


      {/* 
      <Modal show={payInAdvanceModal} onHide={showPayInAdvanceModal} centered>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold text-rove">Long Rides</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" ">Payment in Advance?</div>
        </Modal.Body>
        <Modal.Footer className="">
          <Button className="col-md-3  btn-cancel-trip" onClick={showPayInAdvanceModal}>
            Reject
          </Button>
          <Button className="col-md-3" onClick={payInAdvanceHandler} >
            Accept
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal show={cancelModal} onHide={cancelModalHandler} centered>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold text-rove">Rove</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" ">Are you sure you want to cancel your ride?</div>
        </Modal.Body>
        <Modal.Footer className="">
          <Button className="col-md-3 " onClick={cancelModalHandler}>
            No
          </Button>
          <Button className="col-md-3" onClick={cancelUpcomingRideHandler}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={paymentModal} onHide={paymentModalHandler} centered>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Top up your wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="row d-flex justify-content-between mb-3 p-3 "
            style={{
              background: "rgb(233 192 196 / 49%)",
              borderRadius: "5px",
            }}
          >
            <div className="col-md-12  ">
              {/* <img
                  src="/assets/imgs/icons/wallet@2x.png"
                  alt="wallet@2x"
                  width={25}
                /> */}
              <p
                className=" ms-2 fw-bold fs-6 mb-0"
                style={{ color: "#e3666" }}
              >
                Please top up you wallet by KD {walletDiff} to process with
                this booking.
              </p>
            </div>
          </div>
          <div className="my-3">
            <h6 className="text-rove my-3 fw-bold">Select Payment Method</h6>
            <div
              className={`row d-flex justify-content-between mb-3 p-2 ${selectPayMode == 0 ? "select" : ""
                }`}
              onClick={() => setSelectPayMode(0)}
            >
              <div className="col-md-6 d-flex align-items-center">
                <img
                  src={
                    cardDetail && cardDetail.card_brand == "VISA"
                      ? "/assets/imgs/icons/visa@2x.png"
                      : "/assets/imgs/icons/ic_mastercard-2.png"
                  }
                  // alt="ic_mastercard-2@2x"
                  width={30}
                />
                <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                  {cardDetail
                    ? `#### ${cardDetail.card_number}`
                    : "Add Credit Card"}
                </p>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-end">
                <p className=" me-2 fw-bold fs-6 mb-0">
                  Expires in : {cardDetail && cardDetail.expire_date}
                </p>
              </div>
            </div>
            <div
              className={`row d-flex justify-content-between mb-3 p-2 ${selectPayMode == 1 ? "select" : ""
                }`}
              onClick={() => setSelectPayMode(1)}
            >
              <div className="col-md-12 d-flex align-items-center">
                <p className="ms-2 fw-bold fs-6 mb-0">KNET</p>
              </div>
            </div>
            {/* <div className={`row d-flex justify-content-between mb-3 p-2 ${selelectPayMode==2?"select":""}`}  onClick={()=>setSelectPayMode(2)}>
                <div className="col-md-12 d-flex align-items-center">
                  <p className="ms-2 fw-bold fs-6 mb-0">
                    Apple Pay
                  </p>
               </div>
            </div> */}
          </div>
          {/* <div className="my-3">
            <h6 className="fw-bold text-rove my-2">
              Select Payment method
            </h6>
            <div className="col-md-12 mt-3">
              <div className="bg-light top_up_wallet_div d-flex justify-content-between align-items-center">
                <input type="radio" id="creditCard" name="paymentMethod" value="CreditCard" />
                <label htmlFor="creditCard" className="fw-bold">Add Credit Card</label>
              </div>
              <div className="mt-3 top_up_wallet_div d-flex align-items-center">
                <input type="radio" id="knet" name="paymentMethod" value="KNET" />
                <label htmlFor="knet" className="fw-bold">KNET</label>
              </div>
            </div>
          </div> */}

        </Modal.Body>
        <Modal.Footer className="col-md-12">
          <button
            className="btn btn-primary  mx-3 w-100"
          >
            Proceed to Pay {"  "}
            KD {walletDiff}
          </button>
          <button
            className="btn btn-primary btn-cancel-trip mx-3 w-100"
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={fareBreakDownModal} onHide={fareModal} >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold ">Fare Breakdown</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="col-md-12"> */}
          {/* <hr /> */}
          {carFareBreakdown?.length > 0 ? carFareBreakdown.map((data) => (
            <>
              <div className="d-flex justify-content-between">
                <label className="fw-bold">{data.title}</label>
                <span className="text-rove fw-bold">KD {data.price}</span>
              </div>
              <hr />
            </>
          )) : ''}
          {/* <hr /> */}
          {/* <div className="d-flex justify-content-between">
            <label className="fw-bold">Fare per 10 mins after 4 hours</label>
            <span className="text-rove fw-bold">KD 1.500</span>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <label className="fw-bold">Waiting Charge after 10 mins</label>
            <span className="text-rove fw-bold">KD 2.000</span>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <label className="fw-bold">Cancellation fee</label>
            <span className="text-rove fw-bold">KD 2.000</span>
          </div> */}
          {/* </div> */}
        </Modal.Body>
      </Modal>
      {/* 
      <Modal show={payByCardModal} onHide={handleCardModal} cente>
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold">Enter Card Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TapCard
              scope={Scope.TOKEN}
              operator={{
                publicKey: 'pk_test_QKYn2pTCRjaEVZ7MbIcXHGW6'
              }}
              transaction={{
                reference: 'transaction_xxx',
                paymentAgreement: {
                  id: '',
                  contract: {
                    id: ''
                  }
                }
              }}
              order={{
                id: '',
                amount: 1,
                currency: Currencies.KWD,
                description: 'description',
                reference: '',
                metadata: { key: 'value' }
              }}
              invoice={{
                id: 'invoice_xxx'
              }}
              customer={{
                id: 'cus_TS01A1120241425Ju991206409',
                // id: userProfileData?.user_profile.tap_customer_id ?userProfileData?.user_profile.tap_customer_id : '' ,
                name: [
                  {
                    lang: Locale.EN,
                    first: userProfileData?.user_profile.name_eng ? userProfileData?.user_profile.name_eng.split(' ')[0] : '',
                    last: userProfileData?.user_profile.name_eng ? userProfileData?.user_profile.name_eng.split(' ')[1] : '',
                    middle: ''
                  }
                ],
                nameOnCard:  userProfileData?.user_profile.name_eng,
                editable: true,
                contact: {
                  email: userProfileData?.user_profile.user_email,
                  phone: {
                    countryCode: userProfileData?.user_profile.country_code,
                    number: userProfileData?.user_profile.mobileno
                  }
                }
              }}
              merchant={{
                id: ''
              }}
              interface={{
                locale: Locale.EN,
                cardDirection: Direction.DYNAMIC,
                edges: Edges.CURVED,
                theme: Theme.DYNAMIC,
                powered: true,
                colorStyle: ColorStyle.COLORED,
                loader: true
              }}
              features={{
                acceptanceBadge: true,
                customerCards: {
                  saveCard: true,
                  autoSaveCard: true,
                }
              }}
              fieldsVisibility={{
                card: {
                  cardHolder: true
                }
              }}
              acceptance={{
                supportedSchemes: ['AMEX', 'VISA', 'MASTERCARD', 'AMERICAN_EXPRESS'],
                supportedFundSource: ['CREDIT', 'DEBIT'],
                supportedPaymentAuthentications: ['3DS']
              }}
              post={{
                url: "https://tap.company"
              }}
              onReady={() => console.log('onReady')}
              onFocus={() => console.log('onFocus')}
              onBinIdentification={(data) => console.log('onBinIdentification', data)}
              onValidInput={(data) => console.log('onValidInputChange', data)}
              onError={(data) => console.log('onError', data)}
              // onChangeSaveCardLater={(data) => console.log('onChangeSaveCardLater', data)}
              onChangeSaveCardLater={handleSaveCardChange}
              onSuccess={(data) => console.log('onSuccess', data)}
            />
            <button onClick={saveCardHandler}>Save</button>
          </Modal.Body>
        </Modal> */}



      <Offcanvas show={updateModal} onHide={updateModalHandler} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{isRentalCar == 0 ? 'Pickup Later' : 'Long Rides'}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body >
          <div
            className="search-item search-from mb-3 "
            id="location-pickup"
          >
            <label>
              <input
                type="text"
                className="form-control"
                id="Location1"
                placeholder="Pickup location"
                ref={inputRef}
                // defaultValue={pickupLocation}
                // value={pickupLocation}
                // value={pickupPosition.text}
                name="pickup"
                onChange={pickupAddressChange}
              />
            </label>
          </div>
          <div
            className={`search-item search-from ${noOfCars ? "d-none" : ""
              }`}
            id="location-destination"
          >
            <label>
              <input
                type="text"
                className="form-control"
                id="Location2"
                placeholder="Dropoff location"
                ref={inputRef2}
                name="destination"
                onChange={destinationAddressChange}
              />
            </label>
          </div>
          <hr />

          <div className="form-group mt-3" >
            {/* <i class="fa-regular fa-calendar ms-3"></i> */}
            {/* <label htmlFor="date" className="fw-bold text-rove mx-3" onClick={dateOpenHandler}>25 Jun 2024 05:00 Am</label>
              <input 
                type="date"
                name="date" 
                id="date"
                className=" w-100"
                /> */}
            <DatePicker
              showIcon
              icon="fa fa-calendar"
              toggleCalendarOnIconClick
              // isClearable
              selected={startDate}
              minDate={minDate}
              maxDate={maxDate}
              withPortal
              className="form-control"
              showTimeSelect
              timeFormat="p"
              timeIntervals={10}
              // timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy h:mm aa"
              showTimeInput
              placeholderText="Enter date & time"
              onChange={handleDateChange}
            />
          </div>
          {isRentalCar == 1 ?
            <>
              <hr />
              <label className="text-rove fw-bold col-md-6">Select Hours
                <i class="fa-regular ms-2 fa-clock" ></i>
              </label>
              {/* <select defaultValue={rentalCarHour ? rentalCarHour : 4} className="select col-md-6"> */}
              <select className="select col-md-6" id="selected_hour_id" onChange={handlelongRentalHours}>
                {longRentalCarData.length > 0 ? longRentalCarData.map((data) => (
                  <option price={data.hours_price} value={data.hours_value} selected={rentalCarHour == data.hours_value} key={data.id} data-id={data.id}>{data.hours_name}</option>
                )) : <div>Loading..</div>}
              </select>

              <hr />
              <label className="fw-bold col-md-6">Total Price</label>
              <span className="col-md-6 text-rove  fw-bold">KD {selectedHourPrice}</span>
              <i class="fa-solid fa-circle-info mx-2" onClick={fareBreakDownModalHandler} style={{ color: '#1f3c4b' }}></i>
            </> : ''
          }

          {/* <DateTimePicker
            label="Controlled picker"
            value={value}
            onChange={(newValue) => setValue(newValue)}
          /> */}

          {isRentalCar == 0 ?
            <div className="form-group mt-1" >
              {/* <div className="pickup-later pickup-button">
                <h6 className="text-rove fw-bold">Selected Cars: </h6>
                <div className="row noofcars">
                  <div className="col-md-3 col-lg-3 w-15">
                    {" "}
                    <div
                      className={`d-flex align-items-center justify-content-center ${noOfCarForRide[1] ? "select" : ""
                        }`}
                      onClick={() => handleClick(1)}
                    >
                      1
                    </div>{" "}
                  </div>
                  <div className="col-md-3 col-lg-3 w-25">
                    <div
                      className={`d-flex align-items-center justify-content-center ${noOfCarForRide[2] ? "select" : ""
                        }`}
                      onClick={() => handleClick(2)}
                    >
                      2
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3 w-25">
                    <div
                      className={`d-flex align-items-center justify-content-center ${noOfCarForRide[3] ? "select" : ""
                        }`}
                      onClick={() => handleClick(3)}
                    >
                      3
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3 w-25">
                    <div
                      className={`d-flex align-items-center justify-content-center ${noOfCarForRide[4] ? "select" : ""
                        }`}
                      onClick={() => handleClick(4)}
                    >
                      4
                    </div>
                  </div>
                </div>
              </div> */}
              <hr />
              <i class="fa-solid fa-car-rear  ms-3"></i>
              <label htmlFor="date" className="fw-bold text-rove mx-3">Selected Cars</label>
              <i class="fa-solid fa-minus mx-3 p-2" style={{ color: '#ffffff', backgroundColor: '#1f3c4b' }} onClick={decrease}></i>
              <span className="fw-bold text-rove">
                {number}
              </span>
              <i class="fa-solid fa-plus mx-3 p-2" style={{ color: '#ffffff', backgroundColor: '#1f3c4b' }} onClick={increase}></i>
              <button className="" hidden></button>
            </div>
            : ''
          }

          <hr />
          <div>
            <button className="btn btn-cancel-trip w-45 mx-2 " onClick={updateModalHandler}>Cancel</button>
            <button className="btn btn-primary w-45 mx-2" id="updateRideBtn" onClick={() => updateUpcomingHandler(requestID)}> Update</button>
          </div>
          <div>
          </div>

          {/* <div className="mt-3">
            {position && (
              <MapWithAMarker
                containerElement={<div style={{ height: `80vh` }} />}
                mapElement={<div style={{ height: `50%` }} />}
                zoom={zoom}
                pickupPosition={pickupPosition}
                position={position}
                destinationPosition={destinationPosition}
                handlePickupDrag={handlePickupDrag}
                routeDirections={routeDirections}
                driverPositions={
                  getDriverDetail ? getDriverDetail.drivers : null
                }
                path={path}
              />
            )}
          </div> */}
        </Offcanvas.Body>
      </Offcanvas>
      {/* <Modal show={updateModal} onHide={updateModalHandler} centered>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold text-rove" >Pickup Later</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="search-item search-from "
            id="location-pickup"
          >
            <label>
            </label>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default MyTrips;
