import api from ".";
import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth-token");
  config.headers.Auth = token;
  return config;
});

export const otpApi = (data) => {
    return axios.post(api.otp, data );
};
  
export const emailOtpApi = (data) => {
    return axios.post(api.emailOtp, data );
};
  
export const checkEmailApi = (data) => {
    return axios.post(api.checkEmail, data );
};
  
export const loginApi = (data) => {
    return axios.post(api.login, data );
};
  
export const logoutApi = (data) => {
    return axios.post(api.logout, data );
};
  
export const callOtpApi = (data) => {
    return axios.post(api.call_otp, data );
};
  
export const getProfileDataApi = (data) => {
    return axios.post(api.get_profile_data, data );
};
  
export const enableFeaturesApi = (data) => {
    return axios.post(api.enable_features, data );
};
  
export const updateProfileDataApi = (data) => {
    return axios.post(api.update_profile_data, data );
};

export const getFavPlaceApi = (data) => {
    return axios.post(api.get_fav_place, data );
};
  
export const addFavPlaceApi = (data) => {
    return axios.post(api.add_fav_place, data );
};

export const deleteFavPlaceApi = (data) => {
    return axios.post(api.delete_fav_place, data );
};

export const updateFavPlaceApi = (data) => {
    return axios.post(api.update_fav_place, data );
}

export const nearSearchLocationApi = (data) => {
    return axios.post(api.near_search_location, data );
};
  
export const getDriverApi = (data) => {
    return axios.post(api.get_driver, data );
};
  
export const priceCalculateApi = (data) => {
    return axios.post(api.price_calculate, data );
};
  
export const fareBreakDownApi = (data) => {
    return axios.post(api.get_fare_break_down, data );
};
  
export const driverRequestApi = (data) => {
    return axios.post(api.driver_request, data );
};
  
export const getRequestDriverApi = (data) => {
    return axios.post(api.get_request_driver, data );
};
  
export const cancleRideRequestApi = (data) => {
    return axios.post(api.cancle_riderequest, data );
};

export const getCartypeApi = (data) => {
    return axios.post(api.car_types, data);
}
export const emailVerifyApi = (data) => {
    return axios.post(api.email_verify, data );
};

export const phoneOtpApi = (data) => {
    return axios.post(api.phone_send_otp, data );
};
export const getCouponApi = (data) => {
    return axios.post(api.getCoupon, data );
};

export const userLocationRequestApi = (data) => {
    return axios.post(api.user_location_request, data );
};

export const validateCouponApi = (data) => {
    return axios.post(api.validate_coupon, data );
};

export const getPastRidesApi = (data) => {
    return axios.post(api.get_past_rides, data );
};

export const ratingApi = (data) => {
    return axios.post(api.rating_api, data );
};

export const homeScreenNotificationApi = (data) => {
    return axios.post(api.home_screen_notification, data );
};
export const removeHistoryApi = (data) => {
    return axios.post(api.remove_history, data );
}
export const runningRideCoupon = (data) => {
    return axios.post(api.customer_running_ride_apply_coupon_api, data );
};

export const removeRideCoupon = (data) => {
    return axios.post(api.customer_remove_ride_apply_coupon_api, data );
};
export const updateUpcomingRideApi = (data) => {
    return axios.post(api.car_rental_update_api, data );
};

export const pickupLaterUpdateApi = (data) => {
    return axios.post(api.pickup_later_update_api, data );
};

export const getLongRentalCarApi = (data) => {
    return axios.post(api.get_rental_car, data );
};

export const addWalletTapWebAPI = (data) => {
    return axios.post(api.add_wallet_tap_web_api, data );
};

export const createCustomerIdApi = (data) => {
    return axios.post(api.create_customer_id_api, data );
};