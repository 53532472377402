import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Modal, Button } from "react-bootstrap";
import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";
import { getProfileDataApi, addWalletTapWebAPI } from "../../api/api";
import { TapCard, Currencies, Direction, Edges, Locale, Theme, Scope, ColorStyle } from '@tap-payments/card-web'

const Wallet = (props) => {
  const location = useLocation();
  const [forMeShow, setForMeShow] = useState(false);
  const [forCreditCardShow, setForCreditCardShow] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [userDetail, setUserDetail] = useState(() => {
    var user = localStorage.getItem("user_data");
    return user ? JSON.parse(user) : null;
  })
  const [userProfileData, setUserProfileData] = useState();
  const handleForMeClose = () => setForMeShow(false);
  const handleForCreditCardClose = () => setForCreditCardShow(false); 
 
  useEffect(() => {
    if (userDetail) {      
      get_profile_data();
      // get_enable_features_list()
    }
    var text_input = document.querySelector("#other_amount");
    if (location.state) {
      const { topUp } = location.state;

      if (topUp && (!text_input.value) ) {
        text_input.value = topUp;
      };
    };
  }, [userDetail]);

  const selectCreditAmountHandler = async (e) => {
    let amount = e.target.value
    document.getElementById('other_credit_amount').value = amount;
  }

  const amountCreditCardHandler = async (e) => {
    let amount = e.target.value
    document.getElementById('credit_amount_'+amount).checked = false
  }

  const walletAddTapHandler = async (e) => {

    let amount = document.getElementById('other_credit_amount').value;

    var data1 = {
      user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
      user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
    };

    var form = new FormData();
    form.append("user_type", data1.user_type);
    form.append("user_id", data1.user_id);
    form.append("amount", amount);

    var res = await addWalletTapWebAPI(form);
    var data = res.data;
    if (data.status == "1") {
      let wallet_pay_url = data.wallet_pay_url
      wallet_pay_url = wallet_pay_url.replace('https://rovemain.rovesolution.com','https://www.riderove.com/dev_rove')
      // window.open(wallet_pay_url, '_blank');
      var win = window.open(wallet_pay_url,'popup','width=600,height=600')
      var timer = setInterval(function() { 
        if(win.closed) {
          clearInterval(timer);
          get_profile_data()
          document.getElementById('other_amount').value = ''
          if( document.getElementById('amount_'+amount) ) {
            document.getElementById('amount_'+amount).checked = false
          }
        }
      }, 1000);
    } else {
      console.log("Error : add wallet tap payment", data);
    }
  }

  const selectAmountHandler = async (e) => {
    let amount = e.target.value
    document.getElementById('other_amount').value = amount;
  }

  const amountTapPaymentHandler = async (e) => {
    let amount = e.target.value
    document.getElementById('amount_'+amount).checked = false
  }

  const walletAddCreditCardHandler = () => {
    
    setForCreditCardShow(true);

    let amount = document.getElementById('other_credit_amount').value;
    
    document.getElementById('other_credit_amount').value = ''
    if( document.getElementById('credit_amount_'+amount) ) {
      document.getElementById('credit_amount_'+amount).checked = false
    }
  }

  const handleSaveCardChange = (data) => {
    console.log('onChangeSaveCardLater', data);
  };

  const get_profile_data = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
        user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await getProfileDataApi(form);
      // console.log(res)
      var data = res.data;
      if (data.status == "1") {
        setUserProfileData(data);
      } else {
        console.log("Error : get profile data", data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    if (forMeShow) {
      const input = document.querySelector("#countrySelect");
      var iti = intlTelInput(input, {
        initialCountry: "KW",
        // showFlags:true,
        showSelectedDialCode: true,
        hiddenInput: function (telInputName) {
          return {
            phone: "phone_full",
            country: "country_code",
          };
        },
      });

      // input.value = "+965" + " " + inputValue;
      input.value = inputValue;
      input.focus();
      input.addEventListener("countrychange", function (e) {
        let dialcode = iti.getSelectedCountryData().dialCode;
        // input.value = "+" + dialcode + " " + inputValue;
      });
    }
  }, [forMeShow]);

  return (
    <main className="main">
      {/* eslint-disable jsx-a11y/anchor-is-valid */}

      <section className="section mt-40 " id="tax-page">
        <div className="container-fluid ">
          <div className="row">
            <div className=" col-md-3 col-lg-3"></div>
            <div className=" col-md-5 col-lg-5">
              <h3 className="text-20-bold text-center mb-5">
                Current Balance : {userProfileData?.user_profile.wallet ?  userProfileData.user_profile.wallet : '0' } KD
              </h3>
              <div className="row">
                <div className="col-lg-12">
                  <div id="tax_profile_section">
                    <Tabs
                      defaultActiveKey="personal_tax"
                      id="tax-profile-tab"
                      className="mb-3"
                      fill
                    >
                      <Tab eventKey="personal_tax" title="KNET">
                        <div className="row">
                          <div className="col-md-12">
                            <div id="past-rides">
                              <h3 className="text-20-medium  mb-2">
                                Select your top up amount
                              </h3>

                              <form onSubmit={walletAddTapHandler}>
                                <div className="form-check my-2">
                                  <input
                                    type="radio"
                                    name="amount"
                                    className="form-check-input"
                                    id="amount_10"
                                    placeholder=""
                                    value={10}
                                    onChange={selectAmountHandler}
                                  />
                                  <label
                                    for="amount_10"
                                    className="form-check-label fw-bolder mx-2"
                                  >
                                    KD 10
                                  </label>
                                </div>
                                <div className="form-check my-2">
                                  <input
                                    type="radio"
                                    name="amount"
                                    className="form-check-input"
                                    id="amount_20"
                                    placeholder=""
                                    value={20}
                                    onChange={selectAmountHandler}
                                  />
                                  <label
                                    for="amount_20"
                                    className="form-check-label fw-bolder mx-2"
                                  >
                                    KD 20
                                  </label>
                                </div>
                                <div className="form-check my-2">
                                  <input
                                    type="radio"
                                    name="amount"
                                    className="form-check-input"
                                    id="amount_30"
                                    placeholder=""
                                    value={30}
                                    onChange={selectAmountHandler}
                                  />
                                  <label
                                    for="amount_30"
                                    className="form-check-label fw-bolder mx-2"
                                  >
                                    KD 30
                                  </label>
                                </div>
                                <div className="form-check my-2">
                                  <input
                                    type="radio"
                                    name="amount"
                                    className="form-check-input"
                                    id="amount_50"
                                    placeholder=""
                                    value={50}
                                    onChange={selectAmountHandler}
                                  />
                                  <label
                                    for="amount_50"
                                    className="form-check-label fw-bolder mx-2"
                                  >
                                    KD 50
                                  </label>
                                </div>
                                <div className="form-check my-2">
                                  <input
                                    type="radio"
                                    name="amount"
                                    className="form-check-input"
                                    id="amount_100"
                                    placeholder=""
                                    value={100}
                                    onChange={selectAmountHandler}
                                  />
                                  <label
                                    for="amount_100"
                                    className="form-check-label fw-bolder mx-2"
                                  >
                                    KD 100
                                  </label>
                                </div>
                                <div className="form-group my-3">
                                  <label for="other_amount" className="fw-bolder">
                                    Other Amount
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="other_amount"
                                    placeholder="Enter amount"
                                    onClick={amountTapPaymentHandler}
                                  />
                                </div>
                                <div className="form-group text-center w-100">
                                  <button
                                    type="button"
                                    className="btn btn-primary w-30"
                                    onClick={walletAddTapHandler}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="business_tax" title="CREDIT CARD">
                        <h3 className="text-20-medium  mb-2">
                          Select your top up amount
                        </h3>

                        <form onSubmit={walletAddCreditCardHandler}>
                          <div className="form-check my-2">
                            <input
                              type="radio"
                              name="amount"
                              className="form-check-input"
                              id="credit_amount_10"
                              placeholder=""
                              value={10}
                              onChange={selectCreditAmountHandler}
                            />
                            <label
                              for="credit_amount_10"
                              className="form-check-label fw-bolder mx-2"
                            >
                              KD 10
                            </label>
                          </div>
                          <div className="form-check my-2">
                            <input
                              type="radio"
                              name="amount"
                              className="form-check-input"
                              id="credit_amount_20"
                              placeholder=""
                              value={20}
                              onChange={selectCreditAmountHandler}
                            />
                            <label
                              for="credit_amount_20"
                              className="form-check-label fw-bolder mx-2"
                            >
                              KD 20
                            </label>
                          </div>
                          <div className="form-check my-2">
                            <input
                              type="radio"
                              name="amount"
                              className="form-check-input"
                              id="credit_amount_30"
                              placeholder=""
                              value={30}
                              onChange={selectCreditAmountHandler}
                            />
                            <label
                              for="credit_amount_30"
                              className="form-check-label fw-bolder mx-2"
                            >
                              KD 30
                            </label>
                          </div>
                          <div className="form-check my-2">
                            <input
                              type="radio"
                              name="amount"
                              className="form-check-input"
                              id="credit_amount_50"
                              placeholder=""
                              value={50}
                              onChange={selectCreditAmountHandler}
                            />
                            <label
                              for="credit_amount_50"
                              className="form-check-label fw-bolder mx-2"
                            >
                              KD 50
                            </label>
                          </div>
                          <div className="form-check my-2">
                            <input
                              type="radio"
                              name="amount"
                              className="form-check-input"
                              id="credit_amount_100"
                              placeholder=""
                              value={100}
                              onChange={selectCreditAmountHandler}
                            />
                            <label
                              for="credit_amount_100"
                              className="form-check-label fw-bolder mx-2"
                            >
                              KD 100
                            </label>
                          </div>
                          <div className="form-group my-3">
                            <label for="other_credit_amount" className="fw-bolder">
                              Other Amount
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="other_credit_amount"
                              placeholder="Enter amount"
                              onClick={amountCreditCardHandler}
                            />
                          </div>
                          <div className="form-group text-center w-100">
                            <button
                              type="button"
                              className="btn btn-primary w-30"
                              onClick={walletAddCreditCardHandler}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </Tab>
                      <Tab eventKey="voucher" title="VOUCHER">
                        <h3 className="text-20-medium text-center mb-3">
                          Enter voucher code
                        </h3>
                        <form className="" method="post">
                          <div className="d-flex justify-content-center">
                            <div className="form-group w-70">
                              <label for="name" className="fw-bolder">
                                Voucher
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Enter coupon code"
                              />
                            </div>
                          </div>

                          <div className="form-group text-center w-100">
                            <button
                              type="button"
                              className="btn btn-primary w-30"
                            >
                              Validate
                            </button>
                          </div>
                        </form>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
              <div className="form-group text-center w-100">
                <button
                  type="button"
                  className="btn btn-primary "
                  onClick={() => setForMeShow(true)}
                >
                  WALLET TRANSFER
                </button>
              </div>
            </div>
            <div className=" col-md-1 col-lg-1"></div>
            <div className="col-md-3 col-lg-3 px-5 trip-last-section">
              <img
                // src="/assets/imgs/icons/my-trip-2.svg"
                src="/assets/imgs/bg/cars2.jpg"
                alt="my-trip-2"
                className="mb-3"
              />
              <h6>Get a ride in minutes</h6>
              <p>Book an Rove from a web browser, no app install necessary.</p>
              <div className="mt-20 w-70">
                <Link
                  className="btn btn-primary d-flex justify-content-center align-items-center gap-3"
                  to="/dashboard"
                >
                  Request a Ride
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal size="lg" show={forCreditCardShow} onHide={handleForCreditCardClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enter Card Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TapCard
            scope={Scope.TOKEN}
            operator={{
              publicKey: 'pk_test_QKYn2pTCRjaEVZ7MbIcXHGW6'
            }}
            transaction={{
              reference: 'transaction_xxx',
              paymentAgreement: {
                id: '',
                contract: {
                  id: ''
                }
              }
            }}
            order={{
              id: '',
              amount: 1,
              currency: Currencies.KWD,
              description: 'description',
              reference: '',
              metadata: { key: 'value' }
            }}
            invoice={{
              id: 'invoice_xxx'
            }}
            customer={{
              // id: 'cus_TS01A1120241425Ju991206409',
              id: userProfileData?.user_profile.tap_customer_id,
              name: [
                {
                  lang: Locale.EN,
                  first: userProfileData?.user_profile.name_eng ? userProfileData?.user_profile.name_eng.split(' ')[0] : '',
                  last: userProfileData?.user_profile.name_eng ? userProfileData?.user_profile.name_eng.split(' ')[1] : '',
                  middle: ''
                }
              ],
              nameOnCard:  userProfileData?.user_profile.name_eng,
              editable: true,
              contact: {
                email: userProfileData?.user_profile.user_email,
                phone: {
                  countryCode: userProfileData?.user_profile.country_code,
                  number: userProfileData?.user_profile.mobileno
                }
              }
            }}
            merchant={{
              id: ''
            }}
            interface={{
              locale: Locale.EN,
              cardDirection: Direction.DYNAMIC,
              edges: Edges.CURVED,
              theme: Theme.DYNAMIC,
              powered: true,
              colorStyle: ColorStyle.COLORED,
              loader: true
            }}
            features={{
              acceptanceBadge: true,
              customerCards: {
                saveCard: true,
                autoSaveCard: true,
              }
            }}
            fieldsVisibility={{
              card: {
                cardHolder: true
              }
            }}
            acceptance={{
              supportedSchemes: ['AMEX', 'VISA', 'MASTERCARD', 'AMERICAN_EXPRESS'],
              supportedFundSource: ['CREDIT', 'DEBIT'],
              supportedPaymentAuthentications: ['3DS']
            }}
            post={{
              url: "https://tap.company"
            }}
            onReady={() => console.log('onReady')}
            onFocus={() => console.log('onFocus')}
            onBinIdentification={(data) => console.log('onBinIdentification', data)}
            onValidInput={(data) => console.log('onValidInputChange', data)}
            onError={(data) => console.log('onError', data)}
            // onChangeSaveCardLater={(data) => console.log('onChangeSaveCardLater', data)}
            onChangeSaveCardLater={handleSaveCardChange}
            onSuccess={(data) => console.log('onSuccess', data)}
          />
        </Modal.Body>
      </Modal>
      <Modal show={forMeShow} onHide={handleForMeClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>WALLET TRANSFER</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex rider_section">
            <input
              type="tel"
              id="countrySelect"
              maxLength={8}
              placeholder="Phone"
              // onChange={handleChange}
              className={`form-control`}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary w-100"
            variant="primary"
            onClick={handleForMeClose}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default Wallet;
