import React from "react";
import { Link, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";

const Faqs = () => {
  const location = useLocation();
  return (
    <main className="main">
      <section className="section mt-40 mb-100" id="faqs">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-md-1 col-lg-1 "></div>
            <div className="col-md-2 col-lg-2 ">
              <Sidebar pathname={location.pathname}></Sidebar>
            </div>
            <div className="col-md-6 col-lg-6 col-12 mb-50 px-5">
            <div className="">
          <h2 className="faq-title fw-bold m">FREQUENTLY ASKED QUESTIONS</h2>
        </div>
        <div className="d-flex flex-column gap-2">
          <div className="col-12 mb-3">
            <h6>How to book a ride ?</h6>
            <p>
              You can enter your desired pick up & destination, then click on
              Pick up now.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>How does an account get blocked or suspended ?</h6>
            <p>
              A user's account or device can be automatically blocked by our
              system if it violates any of our terms and conditions, which all
              users accept by signing up for a Rove account.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Blocked with cancellation ?</h6>
            <p>
              When booking a ride, a user has the option to cancel the ride. If
              a cancellation occurs too often, the system will display a
              warning. If this is continued, the account will be blocked.
              Constant canceling causes a lot of confusion, and wasted time.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Negative balance in the wallet ?</h6>
            <p>
              If a ride was not paid in full or was not paid, the account would
              have a negative balance that is owed. An account would be
              temporarily blocked until this amount is settled.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>How can I reach Rove if I have a question or issue ?</h6>
            <p>
              We want every Rove trip to be hassle-free. So we’re here to help
              with any kind of inquiries through: In-app Click on Menu, then,
              Tap Contact Us, At last, Write your question or issue.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>How do I edit my personal details ?</h6>
            <p>
              You can easily update your name, mobile number, email address, and
              password associated with your account in the Rove app. Go to Menu
              Settings and tap on the info you want to edit.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>What if I didn’t receive a verification code ?</h6>
            <p>
              To make sure you sign up with your real number, we’ll send you a
              verification code via SMS which you need to enter to sign up If
              the SMS with the code does not arrive, you can request a call from
              the same screen. If you still can’t receive a verification code,
              go to http://riderove.com and click on Contact Us. Please provide
              the registered number for the account, and a Care agent will look
              into your issue as soon as possible. EMAIL: info@riderove.com
              PHONE NO: +965 1812888
            </p>
          </div>
        </div>
            </div>
            <div className="col-md-3 col-lg-3 px-5 trip-last-section">
              <img
                // src="/assets/imgs/icons/my-trip-2.svg"
                src="/assets/imgs/bg/cars2.jpg"
                alt="my-trip-2"
                className="mb-3"
              />
              <h6>Get a ride in minutes</h6>
              <p>Book an Rove from a web browser, no app install necessary.</p>
              <div className="mt-20 w-70">
                <Link
                  className="btn btn-primary d-flex justify-content-center align-items-center gap-3"
                  to="/dashboard"
                >
                  Request a Ride
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </main>
  );
};

export default Faqs;
