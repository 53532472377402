import React from "react";

const TermsAndCondition = () => {
  return (
    <main className="main">
      <section className="container py-5" id="tandc">
        <div className="">
          <h2 className="faq-title fw-bold m">TERMS & CONDITIONS</h2>
        </div>
        <div className="d-flex flex-column gap-2">
          <div className="col-12 mb-3">
            <h6>1. Contractual Relationship</h6>
            <p>
              These terms of use ( the <span className="fw-bold">“Terms”</span>)
              govern the access and use by you of software, applications,
              websites, call center, technology platform, content, products, and
              services (collectively and separately the{" "}
              <span className="fw-bold">“Platform” or “Rove Platform”</span>)
              developed by or made available by Rove Car Rental Company WLL, a
              limited liability company established in Kuwait, with commercial
              license No2019/1913/م(<span className="fw-bold">”Rove”</span>).
            </p>
            <p>
              Your access and use of the Platform constitutes an agreement to be
              bound by these Terms, which establishes a contractual relationship
              between you and Rove. If you do not agree to these Terms, you may
              not access or use the Platform. These Terms are subject to change
              by Rove, in Rove’s sole discretion. Changes to the Terms shall be
              effective upon Rove posting of such updated Terms in the Platform.
              Your continued access to or use of this Platform after such
              posting will constitute your acceptance of and agreement to such
              changes.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>2. The Platform</h6>
            <p>
              The Platform enables its users to book, arrange and schedule
              transportation services, including chauffeur and vehicle leasing
              services performed by Rove (the{" "}
              <span className="fw-bold">“Services”</span>).
            </p>
            <p>
              The Platform shall enable the user to see all information of the
              driver such as the driver's photo and name, vehicle type and
              driver's phone number once the vehicle request is confirmed on the
              Platform. The trip fees shall be estimated if the user sets the
              destination.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>3. License to the Platform</h6>
            <p>
              Subject to your compliance with these Terms, Rove grants you a
              limited, non-exclusive, nonsub-licensable, revocable,
              non-transferrable license to access and use the Platform on your
              personal device solely in relation to your use of the Services.
              Any rights not expressly granted herein are strictly reserved by
              Rove.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>4. Ownership</h6>
            <p>
              The Platform, all copyright and intellectual property rights
              therein, are and shall remain Rove’s property. Nothing in these
              Terms nor your use of the Platform convey or grant to you any
              license or right: (<span className="fw-bold">i</span>) in or
              related to the Platform except as expressly provided in these
              Terms or (<span className="fw-bold">ii</span>) to use or reference
              in any manner Rove’s company names, logos, product and service
              names, trademarks or services marks or those of Rove’s licensors.{" "}
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>5. User Profile</h6>
            <p>
              Users are required to register for a personal user account (
              <span className="fw-bold">“Account”</span>) to access and use the
              Platform for the purpose of the Services. To register for an
              Account, you must be at least 21 years of age. To successfully
              complete the registration process, Rove requires users to submit
              personal information, including but not limited to name, address,
              mobile phone number. You agree to provide and maintain accurate,
              truthful, complete, and up-to-date information in your Account.
              Failure to provide, and thereafter maintain, the accuracy of
              information provided in your Account may affect your ability to
              access and use the Platform or may lead to Rove’s termination of
              these Terms with you. You are solely responsible for maintaining
              the secrecy of your Account login details (including username and
              password) at all times. You acknowledge and agree that you are
              responsible for all activities that occur under your Account.
              Unless otherwise permitted by Rove in writing, you may only
              possess one Account.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>6. User Rules of Conduct</h6>
            <p>
              You agree to comply with all applicable laws of Kuwait as well as
              these Terms to continue your use of the Platform. The Platform is
              not available for use by persons under the age of 21. You may not
              share your Account login details with third parties, nor allow the
              access to and use of your Account by persons under the age of 21.
              You may not allow persons under the age of 21 to receive the
              Services, unless they are accompanied by their legal guardian. You
              may not assign or otherwise transfer your Account to any other
              person or entity. You agree and vow to only use the Platform for
              lawful purposes. You agree, in your use of the Platform, to not
              cause nuisance, annoyance, inconvenience, or property damage
              whether to other users, or any other party. Rove holds the right
              to suspend your Account in the event of misuse.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>7. Restrictions</h6>
            <p>
              By using the Rove Platform, you acknowledge and agree that you may
              not:
            </p>
            <p>
              <span className="fw-bold">i.</span>&nbsp; remove or alter in any
              way any copyrighted material, trademark or other intellectual
              property notices from/to the Platform;
            </p>
            <p>
              <span className="fw-bold">ii.</span>&nbsp; reproduce, modify,
              prepare derivative works, distribute, license, sublicense, lease,
              sell, resell, transfer, publicly display, publicly perform,
              transmit, stream, broadcast or otherwise exploit the Platform
              except as expressly permitted by Rove;
            </p>
            <p>
              <span className="fw-bold">iii.</span>&nbsp; decompile, reverse
              engineer or disassemble the Platform -in part or in full- except
              as may be expressly permitted by Rove;
            </p>
            <p>
              <span className="fw-bold">iv.</span>&nbsp; link to, mirror or
              frame any portion of the Platform;
            </p>
            <p>
              <span className="fw-bold">v.</span>&nbsp; cause or launch any
              programs or scripts for scraping, indexing, surveying, or
              otherwise data mining any portion of the Platform or unduly
              burdening or hindering the operation and/or functionality of any
              aspect of the Platform;
            </p>
            <p>
              <span className="fw-bold">vi.</span>&nbsp; attempt to gain
              unauthorized access to or impair any aspect of the Platform or its
              related systems or networks;
            </p>

            <p>
              <span className="fw-bold">vii.</span>&nbsp; use the Platform for
              non-personal commercial purposes without Rove’s explicit written
              approval;
            </p>

            <p>
              <span className="fw-bold">viii.</span>&nbsp; use the Platform if
              you are under the age of 21;
            </p>

            <p>
              <span className="fw-bold">ix.</span>&nbsp; use the Platform to
              obtain Services for pets and other animals;
            </p>

            <p>
              <span className="fw-bold">x.</span>&nbsp; use the Platform to
              obtain Services for any unlawful use under the laws of Kuwait,
              including but not limited to transportation, possession,
              trafficking, solicitation of or sale of narcotics or other illegal
              goods or substances. Prohibited substances include illegal drugs,
              alcohol or any other substance deemed illegal under the laws of
              Kuwait;
            </p>
            <p>
              <span className="fw-bold">xi.</span>&nbsp; encourage others to use
              the Platform to obtain Services for unlawful use.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>8. User’s Responsibility during the use of Services</h6>
            <p>During the use of the Services:</p>
            <p>
              <span className="fw-bold">i.</span>&nbsp; You shall not smoke or
              behave in any manner prohibited by law and general decorum within
              the vehicle.
            </p>
            <p>
              <span className="fw-bold">ii.</span>&nbsp; reproduce, modify,
              prepare derivative works, distribute, license, sublicense, lease,
              sell, resell, transfer, publicly display, publicly perform,
              transmit, stream, broadcast or otherwise exploit the Platform
              except as expressly permitted by Rove;
            </p>
            <p>
              <span className="fw-bold">iii.</span>&nbsp; You shall not allow
              any animals in the vehicle.
            </p>
            <p>
              <span className="fw-bold">iv.</span>&nbsp; You shall abide by all
              the applicable traffic laws and safety regulations such as
              fastening the seat belt or not accompanying a child in the front
              seat. You shall pay any traffic fines resulting from your
              violation of such traffic laws and safety regulations.
            </p>
            <p>
              <span className="fw-bold">v.</span>&nbsp; You shall not carry any
              prohibited or illegal item under the laws of Kuwait and shall
              compensate Rove and solely bear all liability for any material or
              moral damages incurred by Rove.
            </p>
            <p>
              <span className="fw-bold">vi.</span>&nbsp; You shall indemnify
              Rove and fully pay, on behalf of Rove, for any claims or legal
              actions filed against Rove by any other person on account of your
              breach of the terms set out herein.
            </p>

            <p>
              <span className="fw-bold">vii.</span>&nbsp; You shall not cause
              any annoyance to the driver or cause any risk to the driver or to
              the vehicle or to any third party. The driver may end the trip at
              any time in the event that he faces any annoyance from you or if
              there is any risk to the driver or to the vehicle or to any third
              party.
            </p>

            <p>
              <span className="fw-bold">viii.</span>&nbsp; You shall comply with
              all applicable laws and regulations and shall only use the
              Services for lawful purposes.
            </p>

            <p>
              <span className="fw-bold">ix.</span>&nbsp; You shall be
              responsible for the cost of repair and/or cleaning of the vehicle
              in excess of normal “wear and tear”.
            </p>

            <p>
              <span className="fw-bold">x.</span>&nbsp; You shall be responsible
              for your belongings inside the vehicle such as their loss or
              damage including but not limited to damage or loss caused by theft
              or snatching or because the belongings are left in the vehicle or
              unattended. Rove shall bear no responsibility on the same.
            </p>
            <p>
              <span className="fw-bold">xi.</span>&nbsp; You shall not make a
              request for the Services for a number of passengers that is more
              than the allowed number according to the provisions of the traffic
              laws and relevant regulations. In this case, the driver is
              entitled to cancel the trip and such cancelled trip shall be
              subject to cancellation charges of 2.000 KD.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>9. Rove's Responsibility</h6>
            <p>
              <span className="fw-bold">i.</span>&nbsp; In case of a broken-down
              vehicle, Rove shall make all reasonable endeavors to arrange for
              an alternative vehicle for you to resume the trip, but the
              alternative vehicle may not be the same type.
            </p>
            <p>
              <span className="fw-bold">ii.</span>&nbsp; Rove shall accommodate
              only the number of passengers as allowed in the traffic laws and
              relevant regulations.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6 className="fw-bold">10. Third Party Content</h6>
            <p>
              For promotional or non-promotional purposes, the Platform may
              contain links to other websites belonging to or operated by third
              parties. By providing hyperlinks to external pages, Rove shall not
              be considered as to own, control, endorse, recommend or guarantee
              the content, services or products of third-party websites in any
              way. Your use of such links and websites and access to third party
              pages shall be at your own risk. Rove will not be liable for any
              damages or losses (whether direct or indirect/consequential)
              suffered, directly or indirectly, as a result of your use of or
              access to such links and websites.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6 className="fw-bold">11. Personal Information</h6>
            <p>
              Our collection and use of your personal information in connection
              with the Platform and/or the use of Services is as provided in
              Rove’s Privacy Policy located at (Press here) Rove shall abide by
              the maximum limit of privacy of your personal information. By
              accepting such policy, you agree and consent to the collection,
              disclosure, transfer, access and use of your personal information
              in accordance with its provisions. You consent and authorize the
              use of your personal information by Rove, Rove’s affiliates for
              commercial or non-commercial purposes. You agree to receive
              promotional and non-promotional notifications via text messages
              (SMS), e-mails, phone calls, push notifications or in-App
              notifications.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6 className="fw-bold">12. Payment</h6>
            <p>
              You acknowledge and agree that fares and certain charges apply to
              your use of the Platform and the Services obtained via the
              Platform (the “Charges”). You agree to pay without any delay all
              Charges applicable to you and your use of the Platform and/or the
              Services obtained through the Platform immediately upon completion
              of the Services or as otherwise determined by Rove.
            </p>
            <p>
              Payments of all Charges are facilitated by Rove via one of the
              following payment methods: cash, K-Net, credit card or credit
              stored in your Account wallet (the “Wallet”). Charges paid by you
              are non-refundable, unless otherwise stated herein or otherwise
              determined by Rove.
            </p>
            <p>
              Rove may from time to time revise the Charges applicable to your
              use of the Platform and/or the Services. Rove shall be under no
              obligation to notify you of the revised Charges. It is your
              responsibility to check Rove’s Charges and payment terms on this
              page and/or the Platform prior to requesting a Service. Your
              continued use of the Platform constitutes your acceptance of such
              revised Charges.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6 className="fw-bold">13. Fares and Charges</h6>
            <p>Charges are calculated in the following manner:</p>
            <ol>
              <li>
                <p>
                  The minimum charge of a trip shall be 4.500 KD and shall be
                  for a duration of 20 minutes. <br />
                  For every additional 10 minutes following the first 20
                  minutes, the charges shall be 2.250 KD.
                </p>
              </li>
              <li>
                <p>
                  Waiting charges of 2.000 KD shall apply following the first 10
                  minutes prior to commencing the trip.
                </p>
              </li>
              <li>
                <p>
                  Waiting charges during the trip shall be 2.25 KD for every 10
                  minutes.
                </p>
              </li>
              <li>
                <p>
                  Charge for cancelling the trip any time after 10 minutes of
                  booking the trip shall be 2 KD and cancelling within 10
                  minutes of booking the trip shall be free of charge.
                </p>
              </li>
              <li>
                <p>
                  4 KD shall be added to pre-booked trips booked no less than 1
                  hour in advance.
                </p>
              </li>
              <li>
                <p>
                  Cancellation charge of a pre-booked trip shall be 4 KD and
                  cancellation within 10 minutes of booking the pre-booked trip
                  shall be free of charge.
                </p>
              </li>
              <li>
                <p>
                  Additional charges will be calculated based on certain
                  geographical areas and shall be indicated in the Platform
                  prior to booking the trip. The additional charges will be
                  added to the charges referenced above in clauses 1,2,3,4,5,6,7
                  and of this clause 13.
                </p>
              </li>
              <li>
                <p>
                  additional charges will be calculated based of 1 KD (only 1
                  Kuwaiti dinar) is charged for each Ride from 12:30 am until
                  6:00 am.
                </p>
              </li>
            </ol>
          </div>
          <div className="col-12 mb-3">
            <h6>14. Refund Policy</h6>
            <ol>
              <li>
                <p>
                  In the event that the Charges are increased by mistake from
                  Rove, they are refunded according to the customer's desire,
                  via the payment method used to book the trip.
                </p>
              </li>
              <li>
                <p>
                  In the event that there is a balance in the wallet less than
                  or equal to 50 KD (only fifty Kuwaiti dinars or less) and it
                  has not been used for a full year, the customer approves of
                  our terms and conditions. This amount will be in his wallet
                  and can be used at anything, but cannot be refunded back to
                  his bank account.
                </p>
              </li>
            </ol>
          </div>
          <div className="col-12 mb-3">
            <h6>15. Termination</h6>
            <p>
              These Terms constitute your agreement with Rove and shall be
              effective upon registering for an Account and remain effective in
              perpetuity until such Account is de-activated, suspended or
              otherwise non-existent.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>16. Disclaimer</h6>
            <p>
              By using the Rove Platform, you acknowledge and agree that the
              Platform is provided to you “as is” without any representations or
              warranties, express or implied, except as determined in these
              Terms. Rove makes no representation or guarantee that your use of
              the Platform will, in any way, be uninterrupted, reliable, secure
              or otherwise error free.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>17. Indemnity</h6>
            <p>
              You agree to indemnify and hold Rove and its officers, directors,
              employees, affiliates and agents harmless from any and all direct
              or indirect/consequential damages, claims, demands, losses,
              liabilities, and expenses including legal expenses, arising
              directly or indirectly out of or in connection with:(i) your use
              of the Platform and/or the Services obtained under the Platform
              and (ii) your breach of or failure to comply with any of these
              Terms, (iii) your violation of the rights of any third party and
              (iv) your breach or failure to comply with any applicable laws and
              regulations
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>18. Limitation of Liability</h6>
            <p>
              To the maximum extent permitted under the law, in no event shall
              Rove be liable to you or any third party for any consequential,
              indirect, incidental, special, exemplary, punitive or enhanced
              damages or losses, including lost profits, arising out of, or
              related to, or in connection with any breach of these Terms. The
              foregoing shall remain true regardless of (a) whether such damages
              were foreseeable, and (b) whether or not Rove was advised of the
              possibility of such damages in advance. In all instances, Rove’s
              sole and maximum aggregate liability for any reason shall be
              limited to the actual amount paid by you to Rove. To the maximum
              extent permitted by the law, Rove shall not be liable and shall
              not indemnify you materially or morally for any accident injury
              resulting from third party fault.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>19. Notices</h6>
            <p>
              (a) To You: We may provide any notice to you under these Terms by:
              (i) sending a message to the email address provided or (ii) by
              posting to the Platform. Notices shall be effective from time of
              posting or publishing. It is your responsibility to keep your
              e-mail address current and up-to-date.
            </p>
            <p>
              (b) To Us: To give us notice under these Terms, you must contact
              us by sending an e-mail to info@riderove.com .We may update our
              contact details for notices to us from time to time by posting a
              notice on the Platform.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>20. Severability</h6>
            <p>
              If any provision of these Terms is invalid, illegal, void or
              unenforceable, in whole or in part, then that provision will be
              deemed severed from these Terms and will not affect the validity
              or enforceability of the remaining provisions of these Terms. In
              that event, the partiesshall replace the illegal, invalid or
              unenforceable provision or part thereof with a provision or part
              thereof that is legal, valid and enforceable and that has, to the
              greatest extent possible, a similar effect as the illegal, invalid
              or unenforceable provision or part thereof, given the contents and
              purpose of these Terms.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>21. Assignment</h6>
            <p>
              You may not assign or transfer any of your rights or delegate any
              of your obligations under these Terms without Rove’s prior written
              consent. Any purported assignment or delegation in violation of
              these Terms is null and void. No assignment or delegation relieves
              you of any of your obligations under these Terms. You agree that
              Rove may assign or transfer these Terms and all rights and
              obligations therein whether in whole or in part to: (i) a
              subsidiary, affiliate or agent; (ii) an acquirer of Rove’s equity,
              business or assets; or (iii) a successor by merger.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>22. Governing Law and Jurisdiction</h6>
            <p>
              Except as otherwise set forth in these Terms, these Terms shall be
              exclusively governed by and construed in accordance with the laws
              of Kuwait. Any dispute, conflict, claim or controversy arising out
              of or broadly in connection with or relating to the Platform or
              these Terms, including those relating to its validity, its
              construction or its enforceability shall be finally settled in the
              courts of Kuwait.
            </p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default TermsAndCondition;
