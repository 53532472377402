import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  withGoogleMap,
  GoogleMap,
} from "react-google-maps";
import { Modal, Button } from "react-bootstrap";
import Sidebar from "../common/Sidebar";
import MapSavedPlaces from "./MapSavedPlaces";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getFavPlaceApi,addFavPlaceApi,  deleteFavPlaceApi, updateFavPlaceApi } from "../../api/api"
import axios from "axios";
// import PreLoader from "../common/PreLoader";
import Spinner from "react-bootstrap/Spinner";


const custom_style = require("../../custom_style.json");

const options = {
  mapTypeId: "terrain",
  draggable: false,
  scrollwheel: false,
  disableDefaultUI: true,
  zoomControl: false,
  styles: custom_style,
};

const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");
const {
  MarkerClusterer,
} = require("react-google-maps/lib/components/addons/MarkerClusterer");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
const {
  DrawingManager,
} = require("react-google-maps/lib/components/drawing/DrawingManager");

const google = window.google;

const SavedPlaces = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const [formData, setFormData] = useState({
    pick_up: "",
    destination: "",
  });
  const [position, setPosition] = useState({
    lat: 29.3776824,
    lng: 47.9755194,
    text: "",
  });
  const Position = {
    lat: 29.3033426,
    lng: 47.9605811,
  }
  const [zoom, setZoom] = useState(12);
  const [pickupPosition, setPickupPosition] = useState(Position);
  const [destinationPosition, setDestinationPosition] = useState({});
  const [routeDirections, setRouteDirections] = useState({});
  const [forMeShow, setForMeShow] = useState(false);
  const [favouriteID, setFavouriteId] = useState(0);
  const [favouriteName, setFavouriteName] = useState("");
  const [favPlace, setFavPlace] = useState()
  const [userDetail, setUserDetail] = useState()
  const [userProfileData, setUserProfileData] = useState({});
  const [addressType, setAddressType] = useState({ 1: true, 2: false, 3: false });
  const [addPlace, setAddPlace] = useState();
  const [isLoading,setIsLoading] = useState(false)

  const options1 = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
    componentRestrictions: { country: "KW" },
  };
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const [show, setShow] = useState(false);
  const handleForMeClose = () => setForMeShow(false);
  const handleClose = () => {
    setShow(false);
  };
  
  const addPlaceHandler = (e) => {
    e.preventDefault()
    add_fav_place();
    setAddPlace({});

  }

  const handleOpenModal = (data) => {
    setForMeShow(true)
    setFavouriteId(data.favorite_id)
    setFavouriteName(data.name)

  }
  const DirectionsService = new google.maps.DirectionsService();

  const MapWithAMarker = withGoogleMap(({ location }) => (
    <GoogleMap
      defaultZoom={zoom}
      defaultCenter={location}
      options={options}
    >
      <>

        {location &&
          location.text !== undefined &&
          location.text != "" ? (
          <MarkerWithLabel
            position={location}
            cursor="hand"
            icon="/assets/imgs/icons/pickupmarker.png"
            labelAnchor={new google.maps.Point(-10, 40)}
            labelStyle={{
              backgroundColor: "#fff",
              fontSize: "10px",
              fontWeight: "700",
              padding: "5px",
            }}
          >
            <div>
              {location ? location.text : ""}
              &nbsp;&nbsp;
              <i className="fa fa-chevron-right"></i>
            </div>
          </MarkerWithLabel>
        ) : (
          ""
        )}
      </>

      {/* <TrafficLayer autoUpdate /> */}
    </GoogleMap>
  ));

  const aftersetDestinationPosition = (pickup, destination) => {
    if (pickup && destination) {
      DirectionsService.route(
        {
          origin: new google.maps.LatLng(pickup),
          destination: new google.maps.LatLng(destination),
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          console.log(status);
          if (status === google.maps.DirectionsStatus.OK) {
            console.log("ok", result);
            setRouteDirections(result);
            // setRequestRide(true);
          } else {
            console.log("err", result);
          }
          // console.log(routeDirections);
        }
      );
    }
  };


  useEffect(() => {
    const user = localStorage.getItem("user_data");
    if (user) {
      setUserDetail(JSON.parse(user));
    }
  }, []);

  // useEffect(() => {
  //   get_profile_data();
  // }, []);

  useEffect(() => {
    get_saved_places()
  }, [userDetail])

  const get_saved_places = async () => {
    try {
      // console.log(userDetail)
      // return false 
      const data1 = {
        user_type: userDetail?.user_type || '', // 0 android, 1 ios
        customer_id: userDetail?.user_profile_id || '',
      };

      const form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("customer_id", data1.customer_id);

      const res = await getFavPlaceApi(form);
      const data = res.data;
      if (data.status == "1") {
        setIsLoading(false)

        const updatedRequests = data.requests.map(async (req) => {
          const imageUrl = req.image_url;
          let updatedReq = { ...req };

          try {
            await axios.get(imageUrl); // Attempt to fetch the image
          } catch (error) {
            if (error.response && error.response.status === 404) {
              const updatedUrl = imageUrl.replace("https://rovemain.rovesolution.com/", "https://www.riderove.com/dev_rove/");
              updatedReq.image_url = updatedUrl;
            }
          } 
          // var url = req.image_url;
          // const response = await fetch(url, { method: 'get' });

          // if (response && response.status === 404) {
          //   const updatedUrl = imageUrl.replace("https://rovemain.rovesolution.com/", "https://www.riderove.com/dev_rove/");
          //   console.log(updatedUrl)
          //   updatedReq.image_url = updatedUrl;
          // }

          return updatedReq;
        }); 
        Promise.all(updatedRequests).then((updatedData) => {
          data.requests = updatedData;
          setFavPlace(data);
        });
        // data.requests.map((req)=>{
        //   let config = {
        //     method: 'get',
        //     maxBodyLength: Infinity,
        //     url: req.image_url,
        //     headers: { }  
        //   };
          
        //   axios.request(config)
        //   .then((response) => {
        //   })
        //   .catch((error) => {   
        //     if(error.response && error.response.status === 404){
        //       let image = req.image_url.replace("https://rovemain.rovesolution.com/","https://www.riderove.com/dev_rove/")
        //       req.image_url = image
        //     }
        //   });
        // })
        // setFavPlace(data)
        // console.log(data)dd
        // return false
      } else {
        setIsLoading(true)
        console.log("Error", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const add_fav_place = async () => {
    try {
      var address_type = 'Home';
      if(addressType[1]){
        address_type = 'Home';
      }
      if(addressType[2]){
        address_type = 'Work';
      }
      if(addressType[3]){
        address_type = document.getElementById('name1').value;
      }

      var latitute = document.getElementById('place_lat').value;
      var longitude = document.getElementById('place_long').value;
      var customAddress =  document.getElementById('place_address').value;

      console.log(latitute)
      const form = new FormData();
      form.append("user_type", userDetail.user_type || '');
      form.append("User_Id", userDetail?.user_profile_id || '');
      form.append("address_type",address_type)
      form.append("Name",address_type)
      form.append("Latitude",latitute)
      form.append('Longitude',longitude)
      form.append('Address',customAddress)
      form.append('title',customAddress)
   
      const res = await addFavPlaceApi(form);
      
      if (res.data.status == "1") {
        toast("Saved Place Successfully!", {
          position: "top-center",
          autoClose: 2000,
        });
        setIsLoading(true)

        handleClose()
        get_saved_places(); 
      } else {
        toast(res.data.message, {
          position: "top-center",
          autoClose: 2000,
        });
        setIsLoading(true)
        console.log("Error while adding saved places:", res.data.message || "Unknown error");
      }
    } catch (error) {
      toast(error.message, {
        position: "top-center",
        autoClose: 2000,
      });
      console.log("Error adding saved places:", error.message);
    }
  };

  const update_saved_places = async () => {
    try {

      const form = new FormData();
      form.append("user_type", userDetail.user_type || '');
      form.append("user_id", userDetail.user_profile_id || '');

      form.append("favourite_title", favouriteName);
      form.append("favourite_id", favouriteID);

      const res = await updateFavPlaceApi(form);

      if (res.data.status == "1") {
        setIsLoading(true)
        toast("Place Title Updated Successfully!", {
          position: "top-center",
          autoClose: 2000,
        });
        get_saved_places();
        setForMeShow(false); // Close the modal
      } else {
        toast(res.data.message, {
          position: "top-center",
          autoClose: 2000,
        });
        console.log("Error updating saved places:", res.data.message || "Unknown error");
      }
    } catch (error) {
      setIsLoading(true)
      toast(error.message, {
        position: "top-center",
        autoClose: 2000,
      });
      console.log("Error updating saved places:", error.message);
    }
  };

  const delete_saved_places = async () => {
    try {

      const data1 = {
        user_type: userDetail?.user_type || '', // 0 android, 1 ios
        customer_id: userDetail?.user_profile_id || '',
      };

      const form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.customer_id);
      form.append("Favourite_Id", favouriteID)


      const res = await deleteFavPlaceApi(form);
      const data = res.data;
      if (data.status == "1") {
        toast("Saved Place Deleted Successfully..", {
          position: "top-center",
          autoClose: 2000,
      });
      
        get_saved_places();
        setForMeShow(false);
        console.log('delete Successfully')
      } else {
        toast("Error While Deleting Saved Place..", {
          position: "top-center",
          autoClose: 2000,
        });
        console.log("Error", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(favPlace)

  var dp = {
    lat: 29.3033426,
    lng: 47.9605811,
    text: "Emam Al Hassan Bin Ali St",
  };
  var pp = { lat: 29.3695727, lng: 47.9690211, text: "Liberation Tower" };

  useEffect(() => {
    var pickup = localStorage.getItem("pickupPosition");
    var destination = localStorage.getItem("destinationPosition");
    var pp = { lat: 29.2574643, lng: 47.9404039, text: "Liberation Tower" };
    // var pp = JSON.parse(pickup);
    setPickupPosition(pp);
    setPosition(pp);
    setZoom(12);

    var dp = {
      lat: 29.3033426,
      lng: 47.9605811,
      text: "Emam Al Hassan Bin Ali St",
    };
    // var dp = JSON.parse(destination);
    setDestinationPosition(dp);
    aftersetDestinationPosition(pp, dp);
  }, []);

  const pickupAddressChange = (e) => {
    if (e.target.value == "") {
      setAddPlace();
    }
  };

  setTimeout(function () {
    autoCompleteRef.current = new google.maps.places.Autocomplete(
      inputRef.current,
      options1
    );

    /*autoCompleteRef.current.setComponentRestrictions({
      country: ["us", "pr", "vi", "gu", "mp"],
    });*/

    autoCompleteRef.current.addListener("place_changed", async function (e) {
      var place = this.getPlace();
      var geometry = place.geometry;
      var lat = geometry.location.lat();
      var lng = geometry.location.lng();
      var address = place.address_components;
      // console.log(place)
      // place.address_components.map(((data) => {
      //     data.long_name
      // }))
      let custom_address = place.name
      address.map((data)=>(
        custom_address += ', '+ data.long_name
      ))
      document.getElementById('place_address').value = custom_address
      document.getElementById('place_lat').value = lat
      document.getElementById('place_long').value = lng
      console.log(place.name)


      // console.log(place.address_components)
      return false;
      // console.log(lat)
      var latlng = { lat: lat, lng: lng, text: place.name };
      setAddPlace(latlng);
    });
  }, 100);

  // console.log(lat)
  // console.log(favPlace)

  return (
    <>
      <main className="main">
        <ToastContainer />

        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <section className="section mt-40 mb-100" id="saved-palces">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-md-1 col-lg-1 "></div>
              <div className="col-md-2 col-lg-2 mb-50">
                <Sidebar pathname={location.pathname}></Sidebar>
              </div>
              <div className="col-md-6 col-lg-6 px-5">
                <div className="row">
                  <div className="col-md-12 d-flex justify-content-between mb-30">
                    <h2 style={{ fontWeight: "600" }}>My Places</h2>
                    <i className="bi bi-plus" onClick={() => setShow(true)}></i>
                  </div>

        
                  {              
                    isLoading ? (
                      <div className="d-flex justify-content-center align-items-center mt-5 ">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    ) : 
                  favPlace?.requests.length > 0 ? favPlace?.requests.map((data) => (
                    <div className="_css-imdnMt mb-5">
                      {/* <MapSavedPlaces
                      containerElement={<div style={{ height: `30vh` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                      
                      location={dp}
                    /> */}
                      <img
                        style={{ height: '200px' }}
                        src={data.image_url} />

                      <div className="_css-cAseJQ d-flex justify-content-between">
                        <div className="d-flex w-70">
                          <div className="mx-3">
                            <h6 className="fw-bold">{data.name}</h6>
                            <p className="fw-bold">
                              {data.address}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end w-30 mx-3">
                          <div>
                            <button
                              className="btn py-1 ms-3 "
                              onClick={() => { handleOpenModal(data) }}
                            >
                              <i className="bi bi-three-dots-vertical"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )) : ''}
                </div>
              </div>  
              <div className="col-md-3 col-lg-3 px-5 trip-last-section">
                <img
                  // src="/assets/imgs/icons/my-trip-2.svg"
                  src="/assets/imgs/bg/cars2.jpg"
                  alt="my-trip-2"
                  className="mb-3"
                />
                <h6>Get a ride in minutes</h6>
                <p>
                  Book an Rove from a web browser, no app install necessary.
                </p>
                <div className="mt-20 w-70">
                  <Link
                    className="btn btn-primary d-flex justify-content-center align-items-center gap-3"
                    to="/dashboard"
                  >
                    Request a Ride
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal show={forMeShow} onHide={handleForMeClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Update place title</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="" method="post">
              <input
                type="hidden"
                id="favourite_id"
                name="favourite_id"
                value={favouriteID}
              />
              <div className="form-group">
                <label for="name" className="fw-bolder mb-10">
                  Title
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={favouriteName}
                  onChange={(e) => setFavouriteName(e.target.value)}
                  placeholder="Enter your title"
                />
              </div>
              <div className="form-group text-center w-100">
                <button
                  type="button"
                  className="btn btn-primary w-30"
                  style={{ padding: "0.3rem 0.75rem" }}
                  onClick={update_saved_places}
                >
                  Rename
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger w-30 mx-3"
                  onClick={delete_saved_places}
                >
                  Delete
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Add New Place</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <form id='add_place_form'>
              <input type="hidden" name="lat" id="place_lat"/>
              <input type="hidden" name="long" id="place_long"/>
              <input type="hidden" name="address" id="place_address"/>
                <div className="search-item search-from mb-3" id="location-pickup">
                  <label>
                    <input
                      type="text"
                      className="form-control"
                      id="Location1"
                      placeholder="Enter location"
                      ref={inputRef}
                      // name="pickup"
                      onChange={pickupAddressChange}
                    />
                  </label>
                </div>
                <div className="row addfavplace">
                  <div className="col-md-3 col-lg-3 h-25 w-25">
                    {" "}
                    <div
                      className={`d-flex  align-items-center justify-content-center ${addressType[1] ? "select" : ""
                        }`}
                      onClick={() =>
                        setAddressType({
                          1: true,
                          2: false,
                          3: false,
                        })
                      }
                    >
                      <i class="bi bi-house"></i>
                      Home
                    </div>{" "}
                  </div>
                  <div className="col-md-3 col-lg-3  h-25 w-25">
                    <div
                      className={`d-flex align-items-center justify-content-center ${addressType[2] ? "select" : ""
                        }`}
                      onClick={() =>
                        setAddressType({
                          1: false,
                          2: true,
                          3: false,
                        })
                      }
                    >
                      <i class="bi bi-briefcase"></i>
                      Work
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3 w-25">
                    <div
                      className={`d-flex align-items-center justify-content-center ${addressType[3] ? "select" : ""
                        }`}
                      onClick={() =>
                        setAddressType({
                          1: false,
                          2: false,
                          3: true,
                        })
                      }
                    >
                    <i class="bi bi-star"></i>
                      Other
                    </div>
                  </div>
                </div>
            {addressType[3] &&   <div className="form-group mb-3 mt-3">
                  <input
                    style={{ background: "#eee" }}
                    type="text"
                    className="form-control"
                    id="name1"
                    placeholder="Enter your title"
                  />
                </div>  
              }
              <div className="my-3">
                <button type="button" className="btn btn-primary" onClick={addPlaceHandler}>Add Place</button>
              </div>

              <div className="mt-20">
                <MapWithAMarker
                  containerElement={<div style={{ height: `50vh` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  location={addPlace ? addPlace : Position}
                />
              </div>
            </form>

          </Offcanvas.Body>
        </Offcanvas>
      </main>
    </>
  );
};

export default SavedPlaces;
