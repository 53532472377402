import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const useChangeBackButtonPath = (newPath) => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // window.history.pushState({},"",newPath)
    const handlePopState = (event) => {
      event.preventDefault();
      if (location.pathname !== newPath) {
        navigate(newPath, { replace: true });
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate, location.pathname, newPath]);
};

export default useChangeBackButtonPath;
