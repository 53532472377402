import React from "react";
import { Link, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";

const PrivacyPolicy = () => {
  const location = useLocation();
  return (
    <main className="main">
      <section className="section mt-40 mb-100" id="tandc">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-md-1 col-lg-1 "></div>
            <div className="col-md-2 col-lg-2 " >
              <Sidebar pathname={location.pathname}></Sidebar>
            </div>
            <div className="col-md-6 col-lg-6 col-12 px-5 mb-50">
            <div className="">
          <h2 className="faq-title fw-bold m">Privacy Policy</h2>
        </div>
        <div className="d-flex flex-column gap-2">
          <div className="col-12 mb-3">
            <p>Last modified: 20 November 2021</p>
            <p>
              RoveCar Rental Company WLL and/or its affiliates (“Rove”, "us",
              "we", or "our") collects information about you when you use our
              Platform (as defined in the Terms and Conditions), mobile
              application, website, call-center and other products and services
              (collectively the "Service") and via other communications you have
              with us.When you access and/or use the Service, you trust us with
              your personal information and therefore, this privacy policysets
              out our policies regarding the collection, use, transfer, process,
              accessand disclosure of personal data when you use or access our
              Services and the choices you have associated with that data.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Scope and Application</h6>
            <p>
              This privacy policy (the{" "}
              <span className="fw-bold">“Privacy Policy"</span>) applies to
              persons anywhere in the world who access or use our Service and/or
              our Platform ( collectively the{" "}
              <span className="fw-bold">“Users”</span>) as well as to drivers,
              partner transportation companies, or any other persons who use
              ourPlatform under license.
            </p>
            <p>
              We use your data to provide and improve the Service. Each time you
              access or use the Service or provide us with information, by doing
              soyou are accepting and consenting to the practices described in
              this Privacy Policy. Unless otherwise defined in this Privacy
              Policy, terms used in this Privacy Policy have the same meanings
              as in our Terms and Conditions. This Privacy Policy shall be in
              continuation to the Terms and Conditions and your acceptance
              thereof shall mean your acceptance and consent to the terms of
              this Privacy Policy.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>
              Definitions <br />
              Rove App
            </h6>
            <p>
              Rove App means the mobile application through which we provide the
              Service.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Cookies</h6>
            <p>
              Cookies are small pieces of data stored on your device (computer
              or mobile device).
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Types of data collected</h6>
            <p>
              We collect several different types of information for various
              purposes to provide and improve the Service to you.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Personal Data</h6>
            <p>
              When you use or access our Service, we collectcertain personally
              identifiable information that can be used to contact or identify
              you (the “Personal Data”)which may include, but is not limited to
              the following:
            </p>
            <ul style={{ listStyle: "disc" }}>
              <li>
                <p>First name and last name</p>
              </li>
              <li>
                <p>Email address</p>
              </li>
              <li>
                <p>Phone number</p>
              </li>
              <li>
                <p>Postal Address, State, Province, ZIP/Postal code, City</p>
              </li>
              <li>
                <p>Profile Picture</p>
              </li>
              <li>
                <p>Payment method</p>
              </li>
              <li>
                <p>Financial and credit/debit card information</p>
              </li>
              <li>
                <p>Bank account number</p>
              </li>
              <li>
                <p>Civil ID number</p>
              </li>
              <li>
                <p>Cookies and Usage Data</p>
              </li>
              <li>
                <p>Birth Date</p>
              </li>
              <li>
                <p>Occupation</p>
              </li>
            </ul>
            <p>
              Please note that we do not knowingly collect, keep or maintain
              Personal Data from children under the age of 18 through the
              Service, as we require that all Users represent to us that they
              are at least 18 years old.
            </p>
            <p>
              We may use your Personal Data to contact you with newsletters,
              marketing or promotional materials and other information that may
              be of interest to you. You may opt out from receiving any, or all,
              of these communications from us by following the unsubscribe link
              or instructions provided in any email we send.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Usage Data</h6>
            <p>
              We may also collect information that your mobile device sends
              whenever you visit our Service or when you access the Services by
              or through a browser ({" "}
              <span className="fw-bold">"Usage Data"</span>).Usage Data is data
              collected automatically either generated by the use of the Service
              or from the Service infrastructure itself.
            </p>

            <p>
              {" "}
              When you access the Service by or through a mobile device, the
              Usage Data may include information such as the type of mobile
              device you use, your mobile device unique ID, the IP address, IMEI
              number, MAC address of the device’s wireless network interface,
              mobile number, your mobile operating system, the type of mobile
              browser you use, time zone setting, device location, IP address,
              SMS data, transaction information, age/birth date, browsing
              history information, searching history information, and
              registration history information.
            </p>

            <p>
              The Usage Data may include information such as your computer's
              Internet Protocol address (e.g. IP address), browser type, browser
              version, the pages of our Service that you visit, the time and
              date of your visit, the time spent on those pages, unique device
              identifiers and other diagnostic data.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Location Data</h6>
            <p>
              We also use GPS technology to determine your current location. We
              may use and store information about your location if you give us
              permission to do so (“Location Data”). Some of our
              location-enabled Services require your Personal Data for the
              feature to work. If you wish to use the particular feature, you
              will be asked to consent to your Personal Data being used for this
              purpose.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Information from other sources</h6>
            <p>
              We may also receive information from other sources and combine
              that with Personal Data we receive from you or collect through our
              Services. For example:
            </p>
            <ul style={{ listStyle: "disc" }}>
              <li>
                <p>
                  If you choose to link, create, or log in to your Rove account
                  with a payment provider or social media service (e.g.,
                  Facebook), or if you engage with a separate app or website
                  that uses our API (or whose API we use), we may receive
                  information about you or your connections from that site or
                  app.
                </p>
              </li>
              <li>
                <p>
                  When you request on demand services, our drivers may provide
                  us with a user rating after providing services to you.
                </p>
              </li>
              <li>
                <p>
                  If your employer uses one of our enterprise solutions, we may
                  receive information about you from your employer.
                </p>
              </li>
              <li>
                <p>
                  If you also interact with our Service in another capacity, for
                  instance as a driver, we may combine or associate that
                  information with Personal Data we have collected from you in
                  your capacity as a User or rider.
                </p>
              </li>
            </ul>
          </div>
          <div className="col-12 mb-3">
            <h6>Use of Collected Data</h6>
            <p>We use theaforementioned collected data for various purposes:</p>
            <ul style={{ listStyle: "disc" }}>
              <li>
                <p>
                  To provide and maintain our Serviceincluding but not limited
                  to facilitate payments, send receipts, provide products and
                  services you request (and send related information), develop
                  new features, provide customer support to Users and drivers,
                  develop safety features, authenticate Users, and send product
                  updates and administrative messages
                </p>
              </li>
              <li>
                <p>To notify you about changes to our Service</p>
              </li>
              <li>
                <p>
                  To evaluate User’s trends and preferences for internal
                  statistical and analytical purposes, to market and develop
                  Service’s operations
                </p>
              </li>
              <li>
                <p>To monitor the usage of our Service</p>
              </li>
              <li>
                <p>To detect, prevent and address technical issues</p>
              </li>
              <li>
                <p>To detect and prevent fraud and abuse of our Service</p>
              </li>
              <li>
                <p>For troubleshooting and bug fixes</p>
              </li>
              <li>
                <p>
                  Send or facilitate communications (i) between you and a
                  driver, such as estimated times of arrival (ETAs), or (ii)
                  between you and a contact of yours at your direction in
                  connection with your use of certain features, such as
                  referrals or invites;
                </p>
              </li>
              <li>
                <p>
                  To provide you with news, special offers and general
                  information about other goods, services and events which we
                  offer
                </p>
              </li>
              <li>
                <p>
                  To allow you to participate in interactive features of our
                  Service
                </p>
              </li>
              <li>
                <p>To keep our Service safe and secure.</p>
              </li>
              <li>
                <p>
                  To prevent activity we determine to be potentially illegal;
                </p>
              </li>
              <li>
                <p>
                  To enforce this Privacy Policy and/or our Terms and Conditions
                  or any other purpose referenced herein or therein.
                </p>
              </li>
            </ul>
          </div>
          <div className="col-12 mb-3">
            <h6 className="fw-bold">Device Permission</h6>
            <p>
              Most mobile device platforms (iOS, Android, etc.) have defined
              certain types of device data that apps cannot access without the
              device owner’s permission, and these platforms have different
              methods for how that permission can be obtained. iOS devices
              notify users the first time the RoveApp requests permission to
              access certain types of data and gives users the option to grant
              or refuse permission. Android devices notify users of the
              permissions that the RoveApp seeks before their first use of the
              app and use of the app constitutes a grant of such permission.
            </p>
          </div>

          <div className="col-12 mb-3">
            <h3 className="fw-bold">Retention and Transfer of Data</h3>
            <p>
              We will retain and use your Personal Data and Usage Data only for
              as long as is necessary for the purposes set out in this Privacy
              Policy. We will retain and use your Personal Data and Usage Data
              to the extent necessary to provide the Service and comply with our
              legal obligations (for example, if we are required to retain your
              data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies. We are committed to
              protect your Personal Data and Usage Data.
            </p>
            <p>
              You agree that we have the right to retain and transfer the
              Personal Data and Usage Data described in this Privacy Policy to
              and from and process and store it) AWS RDS ( By submitting your
              Personal Data and allowing access to Usage Data, you agree and
              consent to this transfer, storing or processing of Such data. We
              will take all steps reasonably necessary to ensure your data is
              treated securely and in accordance with this Privacy Policy.
            </p>
            <p>
              We are not responsible for the accuracy of the information you
              provide,and will modify or update your Personal Data in our
              databases upon your request. If required by law or at your
              request, we will erase or archive from active use your Personal
              Data and/or Usage Data. By accessing or using the Service, you do
              hereby represent and warrant that you understand that all
              information submitted by you through the Service or otherwise to
              us may be used by us in accordance with applicable laws, this
              Privacy Policy and our Terms and Conditions.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6 className="fw-bold">Sharing of Data</h6>
            <p>
              We may share Personal Data and/or Usage Data which we collect
              about you as described in this Privacy Policy or as described at
              the time of collection or sharing, including as follows:
            </p>

            <p>We may share your Personal Data and/or Usage Data</p>
            <ul style={{ listStyle: "disc" }}>
              <li>
                <p>
                  {" "}
                  With drivers to enable them to provide the Service you
                  request. For example, we share your name and pickup and/or
                  drop-off locations with drivers
                </p>
              </li>
              <li>
                <p>
                  With third parties to provide you a service you requested
                  through a partnership or promotional offering made by a third
                  party or us
                </p>
              </li>
              <li>
                <p>
                  With third parties with whom you choose to let us share your
                  Personal Dataand/or Usage Data, for example other apps or
                  websites that integrate with our API or Services, or those
                  with an API or Service with which we integrate
                </p>
              </li>
              <li>
                <p>
                  With your employer (or similar entity) and any necessary third
                  parties engaged by us or your employer
                </p>
              </li>
              <li>
                <p>With our subsidiaries and affiliated entities</p>
              </li>
              <li>
                <p>
                  With vendors, consultants, marketing and advertising partners,
                  and other service providers who need access to such Personal
                  Dataand/or Usage Data to carry out work on our behalf or to
                  perform any contract we enter into with them
                </p>
              </li>
              <li>
                <p>
                  If we otherwise notify you and you consent to the sharing;
                  With third parties in an aggregated and/or anonymized form
                  which cannot reasonably be used to identify you
                </p>
              </li>
              <li>
                <p>
                  With governmental authorities, law enforcement officials and
                  ministries in the State of Kuwait, including but not limited
                  to the Ministry of Interior, the Communication and Information
                  Technology Regulatory Authority, the Central Agency for
                  Information and Technology.
                </p>
              </li>
            </ul>
          </div>
          <div className="col-12 mb-3">
            <h6 className="fw-bold">Disclosure of Data</h6>
            <h6 className="fw-bold">Business Transaction</h6>
            <p>
              If we are involved in a merger, acquisition, or asset sale,your
              Personal Data and/or Usage Data may be transferred. We will
              provide notice before your Personal Data and/or Usage Data are
              transferred and becomes subject to a different Privacy Policy.
            </p>
            <h6 className="fw-bold">Disclosure for Law Enforcement</h6>
            <p>
              Under certain circumstances, we may be required to disclose your
              Personal Data and/or Usage Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court,
              public prosecution or a government agency).
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Legal Requirements</h6>
            <ul style={{ listStyle: "disc" }}>
              <li>
                <p>To comply with a legal obligation</p>
              </li>
              <li>
                <p>To protect and defend the rights or property of Rove</p>
              </li>
              <li>
                <p>
                  To prevent or investigate possible wrongdoing in connection
                  with the Service
                </p>
              </li>
              <li>
                <p>
                  To protect the personal safety of users of the Service or the
                  public
                </p>
              </li>
              <li>
                <p>To protect against our legal liability</p>
              </li>
            </ul>
          </div>
          <div className="col-12 mb-3">
            <h6>Security of Data</h6>
            <p>
              The security of your data is important to us,but remember that no
              method of transmission over the Internet, or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data and/or Usage Data,
              we cannot guarantee its absolute security and you do so at your
              own risk. To the extent permissible and applicable by law,
            </p>
            <p>
              we expressly disclaim any liability that may arise should any
              other individual obtain your Personal Data and/or Usage Data.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Ratings</h6>
            <p>
              After every trip, users and driversare able to rate each other on
              a scale from 1 to 5. An average of those ratings is associated
              with a user’s or driver’s account and is displayed to other users
              and
            </p>
            <p>
              drivers for whom they provide or receive services. For example,
              user ratings are available to drivers from whom they request
              transportation, and driver ratings are available to their users.
            </p>
            <p>
              This 2-way system holds everyone accountable for their behavior.
              Accountability helps create a respectful, safe environment for
              drivers and users. Users and drivers can see their average rating
              in the main menu of their Rove App.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Service Providers</h6>
            <p>
              We may employ third party companies and individuals to facilitate
              our Service ("Service Providers"), to provide the Service on our
              behalf, to perform Service-related services or to assist us in
              analyzing how our Service is used.
              <br />
              These third-party Service Providers have access to your Personal
              Data and/or Usage Data only to perform these tasks on our behalf
              and are obligated not to disclose or use it for any other purpose.
              You hereby provide your consent to the access and use of your
              Personal Data and/or Usage Data by such third party Service
              Providers.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Advertising</h6>
            <p>
              We may use third-party Service Providers to show advertisements to
              you to help support and maintain our Service.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Marketing and Opt-Out Option</h6>
            <p>
              We provide Users and drivers the opportunity to opt-out of
              receiving communications from us and our partners at the point
              where we request information about the visitor. You have the
              option to ask us not to process your Personal Data and/or Usage
              Data for marketing purposes and to remove it from our database, to
              not receive future communications or to no longer receive our
              Service.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Payments</h6>
            <p>
              We provide online payment services within the Service. In that
              case, we use third-party services for payment processing.
            </p>
            <p>
              The information collected for this purpose is provided directly to
              our third-party payment processors whose use of your Personal
              Datais governed by their privacy policy. These payment processors
              adhere to the standards set by PCI-DSS as managed by the PCI
              Security Standards
            </p>
            <p>
              Council, which is a joint effort of brands like Visa, Mastercard,
              American Express, Discover and other similar services/brands.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Children's Privacy</h6>
            <p>
              Our Service does not address anyone under the age of 18({" "}
              <span className="fw-bold"> "Children"</span>).
            </p>
            <p>
              We do not knowingly collect Personal Data from anyone under the
              age of 18. If you are a parent or guardian and you are aware that
              your child has provided us with Personal Data, please contact us.
              If we become aware that we have collected Personal Data from
              Children without verification of parental consent, we take steps
              to remove that information from our servers.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Cookies</h6>
            <p>
              We may use cookies allow us to automate access and the data entry
              functions to tailor our services to your preferences or interests
              or customize promotions or marketing. In addition, cookies allow
              us to track your use to determine those areas, which are useful or
              popular and those that are not thereby enabling us to improve and
              update our service effectively.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Changes to Privacy Policy .</h6>
            <p>
              Our Privacy Policy may change from time to time. We may change the
              terms of this Privacy Policy from time to time in order to reflect
              our practices and provide greater protection for your information.
              Any change to this Privacy Policy will be clearly displayed in the
              Rove App. You may choose not to accept those changes by deleting
              your account. Your continued use of the Service following the
              posting of any changes to this Privacy Policy means you accept
              such changes. We encourage you to periodically review this Privacy
              Policy for the latest information on our privacy practice.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Notice to Rove customers</h6>
            <p>
              If you are accessing or using the Service or other products or
              services provided by us, this section applies to you.
            </p>
            <p>
              You have read and understood the terms of this Privacy Policy and
              are aware of your rights, liabilities and privileges as explained
              under this Privacy Policy.
            </p>
            <p>
              Each time you access or use the Service provided by Rove or
              provide us with information, by doing so you accept and consent to
              the practices described in this Privacy Policy{" "}
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Your Personal Data and/or Usage Data protection rights.</h6>
            <p>
              You have rights in connection with your Personal Data and/or Usage
              Data, including: to withdraw consent where you have given it, to
              be informed and have access to your Personal Data and/or Usage
              Data, to correct or complete inaccurate data, and in certain
              circumstances to restrict, request erasure, object to processing,
              cancel any use or request portability of your Personal Data and/or
              Usage to another organization.
            </p>
          </div>
          <div className="col-12 mb-3">
            <h6>Contact Us</h6>
            <p>
              If you have any questions about this Privacy Policyor your
              Personal Data and/or Usage Data or if you wish to update your
              Personal, please contact us by email at the below email address :
            </p>
            <a
              href="mailto:info@riderove.com"
              style={{ textDecoration: "none" }}
            >
              <p style={{ color: "#03C4EB" }}>info@riderove.com</p>{" "}
            </a>
          </div>
        </div>
            </div>
            <div className="col-md-3 col-lg-3 px-5 trip-last-section">
              <img
                // src="/assets/imgs/icons/my-trip-2.svg"
                src="/assets/imgs/bg/cars2.jpg"
                alt="my-trip-2"
                className="mb-3"
              />
              <h6>Get a ride in minutes</h6>
              <p>Book an Rove from a web browser, no app install necessary.</p>
              <div className="mt-20 w-70">
                <Link
                  className="btn btn-primary d-flex justify-content-center align-items-center gap-3"
                  to="/dashboard"
                >
                  Request a Ride
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default PrivacyPolicy;
