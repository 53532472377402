import React from "react";
import { Link, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";

const FeedbackPage = () => {
  const location = useLocation();
  return (
    <main className="main">
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <section className="section mt-50 mb-100">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-1 col-lg-1 "></div>
            <div className="col-md-2 col-lg-2 mb-50">
              <Sidebar pathname={location.pathname}></Sidebar>
            </div>
            <div className="col-md-6 col-lg-6 px-5 mb-50 ">
              <div className="text-center">
                <h2
                  className="mb-5 wow fw-bold fadeInUp"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                >
                  Feedback
                </h2>
                <p className="fw-bold fs-5 mb-0">
                  Your feedback is important and valuable to us.
                </p>
                <p className="fw-bold fs-5 mb-0">
                  Send your message through the form below.
                </p>
                <p className="fw-bold fs-5 mb-0">
                  We will be in touch as soon as possible.
                </p>
              </div>
              <div className="box-login mt-40">
                <div
                  className="form-contact form-comment wow fadeInUp"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                >
                  <form>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group focused">
                          <input
                            type="text"
                            id="name"
                            className={`form-control`}
                            placeholder="Name"
                            name="name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group focused">
                          <input
                            type="text"
                            id="email"
                            className={`form-control`}
                            placeholder="Email"
                            name="email"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <textarea
                          cols="3"
                          rows="3"
                          className="form-control"
                          placeholder="Message"
                        ></textarea>
                      </div>

                      <div className="col-lg-12 mt-3">
                        <button
                          className="btn btn-primary fw-bold w-100"
                          type="submit"
                        >
                          Send
                          {/* <svg
                        className="icon-16 ml-5"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                        />
                      </svg> */}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 px-5 trip-last-section">
                <img
                  // src="/assets/imgs/icons/my-trip-2.svg"
                  src="/assets/imgs/bg/cars2.jpg"
                  alt="my-trip-2"
                  className="mb-3"
                />
                <h6>Get a ride in minutes</h6>
                <p>
                  Book an Rove from a web browser, no app install necessary.
                </p>
                <div className="mt-20 w-70">
                  <Link
                    className="btn btn-primary d-flex justify-content-center align-items-center gap-3"
                    to="/dashboard"
                  >
                    Request a Ride
                  </Link>
                </div>
              </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default FeedbackPage;
