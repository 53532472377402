import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  TrafficLayer,
} from "react-google-maps";
import { Link, useNavigate } from "react-router-dom";
import MapWithAMarker from "../common/MapWithMarker";
import ReverseTimer from "../common/ReverseTimer";
import TimeCount from "../common/TimeCount";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import Rating from "../common/Rating";
import {
  cancleRideRequestApi,
  getProfileDataApi,
  getRequestDriverApi,
  removeRideCoupon,
  runningRideCoupon,
  userLocationRequestApi,
} from "../../api/api";
import moment from "moment-timezone";
const custom_style = require("../../custom_style.json");
const options = {
  mapTypeId: "terrain",
  disableDefaultUI: true,
  zoomControl: true,
  styles: custom_style,
};

const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");
const {
  MarkerClusterer,
} = require("react-google-maps/lib/components/addons/MarkerClusterer");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
const {
  DrawingManager,
} = require("react-google-maps/lib/components/drawing/DrawingManager");

const google = window.google;

const Reasons = [
  "Driver was late",
  "Driver behavior",
  "Driving too fast",
  "Cleanliness",
  "Professionalisam",
];
const initialReasons = {
  "Driver was late": false,
  "Driver behavior": false,
  "Driving too fast": false,
  Cleanliness: false,
  Professionalisam: false,
};

const cancel_reasons = [
  "I don’t need a ride anymore",
  "I want to change my booking details",
  "The car is not suitable",
  "I couldn’t contact the driver",
  "The driver isn’t moving",
  "The driver couldn’t find my location",
  "The driver is too far",
  "Other",
];

const initial_cancel_reasons = {
  "I don’t need a ride anymore": false,
  "I want to change my booking details": false,
  "The car is not suitable": false,
  "I couldn’t contact the driver": false,
  "The driver isn’t moving": false,
  "The driver couldn’t find my location": false,
  "The driver is too far": false,
  Other: false,
};

const RideDetail = ({ enableFeatures }) => {
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState(() => {
    var user = localStorage.getItem("user_data");
    return user ? JSON.parse(user) : null;
  });
  const [requestId, setRequestId] = useState(() => {
    var id = localStorage.getItem("request_id");
    return id ? id : null;
  });
  const [userProfileData, setUserProfileData] = useState();
  const [position, setPosition] = useState({
    lat: 29.3776824,
    lng: 47.9755194,
    text: "",
  });
  const [couponCode, setCouponCode] = useState("");
  const [zoom, setZoom] = useState(13);
  const [pickupPosition, setPickupPosition] = useState();
  const [destinationPosition, setDestinationPosition] = useState({});
  const [routeDirections, setRouteDirections] = useState({});
  const [forMeShow, setForMeShow] = useState(false);

  const [closeConfirmShow, setCloseConfirmShow] = useState(false); // modal 1 cancle ride
  const [closeResoneShow, setCloseResoneShow] = useState(false); // modal 2 cancle ride reasons
  const [selectCancelReasone, setSelectCancelReasone] = useState(
    initial_cancel_reasons
  );

  const [prefrenceShow, setPrefrenceShow] = useState(false);
  const [conversation, setConversation] = useState({
    first: true,
    second: false,
    third: false,
  });
  const [temperature, setTemperature] = useState({
    normal: true,
    cold: false,
    warm: false,
  });
  const [showPickupMarker, setShowPickupMarker] = useState(true);
  const [showDestinationMarker, setShowDestinationMarker] = useState(false);

  const [rideStart, setRideStart] = useState(false);

  const [rate, setRate] = useState(0);

  const [driverArrive, setDriverArrive] = useState(false);
  const [headingToDestination, setHeadingToDestination] = useState(false);
  const [rideFinished, setRideFinished] = useState(false);
  const [showCancel, setShowCancel] = useState(true); // button

  const [showPickupLable, setShowPickupLable] = useState(false);
  const [showWaitingCharge, setShowWaitingCharge] = useState(false);
  const [showRideTiming, setShowRideTiming] = useState(false);
  const [showTotalTiming, setShowTotalTiming] = useState(false);
  const [showRoute, setShowRoute] = useState(true);

  const [showCar, setShowCar] = useState(true);

  const [rideStatus, setRideStatus] = useState("Accepted");

  const [estimatedprice, setEstimatedprice] = useState()

  const [rateing, setRateinf] = useState(false);
  const [rateReason, setRateReason] = useState(false);
  const [selectRateReasone, setSelectRateReasone] = useState(initialReasons);
  const [driverDetail, setDriverDetail] = useState();
  const [requestDetail, setRequestDetail] = useState();
  const [accepted, setAccepted] = useState(true);
  const [arrived, setArrived] = useState(true);
  const [startRide, setStartRide] = useState(true);
  const [finishedRide, setFinishedRide] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [locationDriver, setLocationDriver] = useState();

  const getDriverRef = useRef(null);

  const handleSelectReasone = (reason) => {
    // Set the selected reason to true and others to false

    const updatedReasons = {};
    Reasons.forEach((item) => {
      updatedReasons[item] = item === reason;
    });

    //  select multiple reasones

    // const updatedReasons = { ...selectRateReasone };
    // updatedReasons[reason] = !updatedReasons[reason]; // Toggle the selected reason

    setSelectRateReasone(updatedReasons);
  };

  const handleCancelReasonChange = (reason) => {
    const updatedReasons = {};
    cancel_reasons.forEach((item) => {
      updatedReasons[item] = item === reason;
    });
    setSelectCancelReasone(updatedReasons);
  };

  const [carPosition, setCarPosition] = useState();

  const handleRatingClose = () => {
    setRateinf(false);
  };
  const handleRateReasonClose = () => {
    setRateReason(false);
  };

  const options1 = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
  };

  const DirectionsService = new google.maps.DirectionsService();
  const handleForMeClose = () => {
    setForMeShow(false);
    setCouponCode("")
  };
  const handlePrefrenceClose = () => setPrefrenceShow(false);
  const handleCloseConfirm = () => setCloseConfirmShow(false);
  const handleCloseResone = () => setCloseResoneShow(false);

  const handleCancleRide = () => {
    setCloseConfirmShow(false);
    setCloseResoneShow(true);
  };

  const handleCancleRideReasone = () => {
    var resone = Object.keys(selectCancelReasone).find(
      (key) => selectCancelReasone[key] == true
    );
    if (!resone) {
      toast("Please, select reason for cancel ride", {
        position: "top-center",
        autoClose: 1000,
      });
      return false;
    }
    setCloseResoneShow(false);
    cancle_riderequest(resone);
    setSelectCancelReasone(initial_cancel_reasons);

    // navigate("/dashboard");
  };

  useEffect(() => {
    /* if (driverDetail) {
      var r_s = parseInt(driverDetail.ride_status);
      var arriveal_time = parseInt(driverDetail.driver_arrived_time);

      if (r_s == 1 && accepted) {
        toast("Your ride has accepted", {
          position: "top-center",
          autoClose: 1000,
        });
        setAccepted(false);
      } else if (arriveal_time != 0 && arrived) {
        //   cancel
        toast("Your driver has arrived at your location", {
          position: "top-center",
          autoClose: 1000,
        });
        setArrived(false);
        setShowWaitingCharge(true);
      } else if (r_s == 3 && startRide) {
        toast("Your ride has started.\nRide, Relax & Reach", {
          position: "top-center",
          autoClose: 1000,
        });
        setShowCancel(false);
        setShowWaitingCharge(false);
        setStartRide(false);
        setShowRideTiming(true);
      } else if (r_s == 4 && finishedRide) {
        toast("Your ride is finished.\nThank you for using Rove", {
          position: "top-center",
          autoClose: 2000,
        });
        setShowWaitingCharge(false);
        setShowRideTiming(false);
        setShowTotalTiming(true);
        setFinishedRide(false);
      }
     
    } */

    if (locationDriver) {

      var f_r = locationDriver.is_finish_ride;
      var c_r = locationDriver.is_cancel_ride;
      var s_r = locationDriver.is_start_ride;
      var a_r = locationDriver.is_driver_arrived;
      var rideChange = parseInt(locationDriver.is_driver_change_byAdmin)
      if (rideChange == 1) {
        get_request_driver()
      }

      if (f_r == 1 && finishedRide) {
        // setRideStatus("Finished")
        toast("Your ride is finished.\nThank you for using Rove", {
          position: "top-center",
          autoClose: 2000,
        });
        setShowWaitingCharge(false);
        setShowRideTiming(false);
        setShowTotalTiming(true);
        setFinishedRide(false);
        setArrived(false);
        navigate("/ride-reciept/" + requestId);
      } else if (c_r == 1) {
        // setRideStatus("Canceled")
        setArrived(false);
      } else if (s_r == 1 && startRide) {
        // setRideStatus("Started")
        toast("Your ride has started.\nRide, Relax & Reach", {
          position: "top-center",
          autoClose: 1000,
        });
        setShowCancel(false);
        setShowWaitingCharge(false);
        setStartRide(false);
        setShowRideTiming(true);
        setArrived(false);
      } else if (a_r == 1 && arrived) {
        // setRideStatus("Driver Arrived")
        toast("Your driver has arrived at your location", {
          position: "top-center",
          autoClose: 1000,
        });
        setArrived(false);
        setShowWaitingCharge(true);
      }
    }
  }, [locationDriver]);

  /*  useEffect(() => {
    var pickup = localStorage.getItem("pickupPosition");
    var destination = localStorage.getItem("destinationPosition");
    var pp = { lat: 29.2574643, lng: 47.9404039, text: "Liberation Tower" };
    if (pickup) {
      pp = JSON.parse(pickup);
    }
    setPickupPosition(pp);
    setPosition(pp);
    setZoom(13);

    var dp = {
      lat: 29.3033426,
      lng: 47.9605811,
      text: "Emam Al Hassan Bin Ali St",
    };
    if (destination) {
      dp = JSON.parse(destination);
    }
    setDestinationPosition(dp);
    aftersetDestinationPosition(carPosition, pp);

    let driverAccept = setTimeout(() => {
      toast("Your ride has accepted", {
        position: "top-center",
        autoClose: 1000,
      });
    }, 1000);
    let driverArrive = setTimeout(() => {
      toast("Your driver has arrived at your location", {
        position: "top-center",
        autoClose: 2000,
      });
      setDriverArrive(true);
      setShowWaitingCharge(true);
      setPosition(pp);
      setCarPosition(pp);
      setShowRoute(false);

      // aftersetDestinationPosition(
      //   {
      //     lat: 29.2540494,
      //     lng: 47.9664417,
      //   },
      //   pp
      // );
    }, 5000);
    let headingToDestination = setTimeout(() => {
      toast("Your ride has started.Ride, Relax & Reach", {
        position: "top-center",
        autoClose: 2000,
      });
      setShowCancel(false);
      setShowRoute(true);
      setCarPosition(pp);
      aftersetDestinationPosition(pp, dp);
      setShowWaitingCharge(false);
      setShowPickupMarker(false);
      setRideStart(true);
      setShowRideTiming(true);
      setShowDestinationMarker(true);
    }, 10000);
    let nearToDestination = setTimeout(() => {
      setCarPosition({
        lat: 29.3119792,
        lng: 48.015632,
      });
      aftersetDestinationPosition(
        {
          lat: 29.3119792,
          lng: 48.015632,
        },
        dp
      );
    }, 20000);
    let nearToDestination2 = setTimeout(() => {
      setShowPickupMarker(false);
      setCarPosition({
        lat: 29.2827585,
        lng: 47.9850926,
      });
      aftersetDestinationPosition(
        {
          lat: 29.2827585,
          lng: 47.9850926,
        },
        dp
      );
      setShowDestinationMarker(true);
    }, 25000);
    let rideFinished = setTimeout(() => {
      // setShowPickupMarker(false)
      toast("Your ride is finished.Thank you for using Rove", {
        position: "top-center",
        autoClose: 2000,
      });
      setShowDestinationMarker(false);
      var destination = localStorage.getItem("destinationPosition");
      setCarPosition(JSON.parse(destination));
      // aftersetDestinationPosition(dp,dp);
      setShowCar(false);
      setRideFinished(true);
      setShowTotalTiming(true);
      setShowRideTiming(false);
      setShowRoute(false);
    }, 30000);
    let hideCar = setTimeout(() => {
      // setRateinf(true)
      navigate("/ride-reciept");
    }, 32000);
  }, []); */

  useEffect(() => {
    if (userDetail) {
      get_profile_data();
      get_request_driver();
    }
    
    
  }, [userDetail, requestId]);

  useEffect(() => {
    if (driverDetail) {
      user_location_request();
    }
    getDriverRef.current = setInterval(() => {
      if (requestId) {
        // get_request_driver();
        user_location_request();
      }
    }, 5000);

    return () => {
      clearInterval(getDriverRef.current);
      // clearTimeout(time)
    };
  },[driverDetail])

  const get_profile_data = async (e) => {
    try {
      var data1 = {
        user_type: userDetail.user_type, // 0 android, 1 ios
        user_id: userDetail.user_profile_id,
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      setIsLoading(true);
      var res = await getProfileDataApi(form);
      var data = res.data;
      setIsLoading(false);
      if (data.status == "1") {
        setUserProfileData(data);
      } else {
        console.log("Error : get profile data", data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const get_request_driver = async () => {
    try {
      let form = new FormData();
      form.append("request_id", requestId);
      form.append("user_type", userDetail.user_type);
      form.append("user_id", userDetail.user_profile_id);

      var res = await getRequestDriverApi(form);
      var data = res.data;

      if (data.status == "1") {
        setRequestDetail(data);
        setEstimatedprice(data.userdetails.estimatedprice_second)
        setDriverDetail(data.driverdetail);
        var pp = {
          lat: parseFloat(data.userdetails.pickup_latitude),
          lng: parseFloat(data.userdetails.pickup_longitude),
          text: "",
        };
        setShowPickupMarker(true);
        setPosition(pp);
        setPickupPosition(pp);

        var d_p = {
          lat: parseFloat(data.userdetails.destination_latitude),
          lng: parseFloat(data.userdetails.destination_longitude),
          text: "",
        };
        setDestinationPosition(d_p);

        var r_s = parseInt(data.driverdetail.ride_status);
        var arriveal_time = parseInt(data.driverdetail.driver_arrived_time);
       /*  if (r_s == 1) {
          setRideStatus("Accepted");
        } else if (arriveal_time != 0) {
          setRideStatus("Arrived");
        } else if (r_s == 2) {
          setRideStatus("Canceled");
          setShowCancel(false);
        } else if (r_s == 3) {
          setShowPickupMarker(false);
          setShowDestinationMarker(true);
          setRideStatus("Started");
          var d_p = {
            lat: parseFloat(data.userdetails.destination_latitude),
            lng: parseFloat(data.userdetails.destination_longitude),
            text: "",
          };
          setDestinationPosition(d_p);
        } else {
          setShowCar(false);
          setShowPickupMarker(false);
          setShowDestinationMarker(false);
          setRideStatus("Finished");
        } */
      } else {
        console.log("get_request_driver", data);
      }
      console.log(data);
    } catch (error) {
      console.log("get_request_driver", error);
    }
  };

  const aftersetDestinationPosition = (pickup, destination) => {
    if (pickup && destination) {
      DirectionsService.route(
        {
          origin: new google.maps.LatLng(pickup),
          destination: new google.maps.LatLng(destination),
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          console.log(status);
          if (status === google.maps.DirectionsStatus.OK) {
            console.log("ok", result);
            setRouteDirections(result);
            // setRequestRide(true);
          } else {
            console.log("err", result);
          }
          console.log(routeDirections);
        }
      );
    }
  };

  const cancelRideClick = () => {
    setCloseConfirmShow(true);
  };

  const user_location_request = async () => {
    try {
      var form = new FormData();
      form.append("request_id", requestId);
      form.append("user_type", 1);
      form.append("user_id", driverDetail.driver_id); // driver id
      form.append("current_user_id", userDetail.user_profile_id);
      form.append("current_user_type", userDetail.user_type);

      var res = await userLocationRequestApi(form);
      var data = res.data;
      setLocationDriver(data);
      if (data.status == "1") {
        var c_p = {
          lat: parseFloat(data.location.latitude),
          lng: parseFloat(data.location.longitude),
          text: "",
        };
        setCarPosition(c_p);
        if (data.is_finish_ride == 1) {
          setRideStatus("Finished");
          setShowCar(false)
          setShowPickupMarker(false)
          setShowDestinationMarker(false)
        } else if (data.is_cancel_ride == 1) {
          setRideStatus("Canceled");
        } else if (data.is_start_ride == 1) {
          setRideStatus("Started");
          setShowPickupMarker(false);
          setPosition(destinationPosition);
          setShowDestinationMarker(true);
        } else if (data.is_driver_arrived == 1) {
          setRideStatus("Driver Arrived");
        }
      }
      console.log("user_location_request", data);
    } catch (error) {
      console.log("user_location_request", error);
    }
  };

  const cancle_riderequest = async (resone) => {
    try {
      var form = new FormData();
      //  is_update_app=1
      form.append("user_type", userDetail.user_type);
      form.append("user_id", userDetail.user_profile_id);
      form.append("cancelride_reason", resone ?? "I don’t need a ride anymore");
      form.append("request_id", requestId);

      var res = await cancleRideRequestApi(form);
      console.log(res);
      var data = res.data;
      if (data.status == "1") {

        // navigate("/dashboard");
        toast("Your ride cancel successfully", {
          position: "top-center",
          autoClose: 2000,
        });
        // navigate("/ride-reciept/" + requestId);
        navigate("/dashboard");
        localStorage.removeItem("request_id")
      } else {
        toast(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const applyCouponRide = async () => {
    try {
      if (!couponCode.trim().length) {
        toast.error("Please enter coupon code", {
          position: "top-center",
          autoClose: 1000,
        });
        return false;
      }
      var timeZone = moment.tz.guess()
      var form = new FormData();
      form.append("user_type", userDetail.user_type);
      form.append("user_id", userDetail.user_profile_id);
      form.append("request_id", requestId);
      form.append("coupon_code", couponCode);
      form.append("timezone", timeZone);

      var res = await runningRideCoupon(form);
      var data = res.data
      if (data.estimatedprice) {
        setEstimatedprice(data.estimatedprice)
        toast.success(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
        var remove_coupon = document.querySelector(".bi.bi-x-square");
        remove_coupon.classList.remove("d-none");
        handleForMeClose();
        return false;
      }
      if (data.status == "0") {
        toast.error(data.message,{
            position: "top-center",
            autoClose: 1000,
          });
      }

    } catch (error) {
      console.log("applyCouponRide",error)
    }
  }

  const cancelCouponCodeHandle = async () => {
    try {
      setCouponCode("");
      var form = new FormData()
      form.append("user_type", userDetail.user_type);
      form.append("user_id", userDetail.user_profile_id);
      form.append("request_id", requestId);
      
      var res = await removeRideCoupon(form);
      var data = res.data;
      if (data.status == 1) {
        setEstimatedprice(data.estimatedprice);
        toast.success(data.message, {
          position: "top-center",
          autoClose: 1000,
          });
          var remove_coupon = document.querySelector(".bi.bi-x-square");
          remove_coupon.classList.add("d-none");
      } else {
        toast.error(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.log("cancelCouponCodeHandle", error);
    }
  };

  return (
    <main>
      <ToastContainer />
      {isLoading && (
        <div
          className="d-flex justify-content-center align-items-center vh-100 vw-100 z-1 position-absolute"
          style={{ backgroundColor: "rgba(255,255,255,0.6)" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <div id="map">
        {pickupPosition && carPosition && (
          <MapWithAMarker
            containerElement={<div style={{ height: `90vh` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            destinationPosition={destinationPosition}
            position={position}
            pickupPosition={pickupPosition}
            zoom={zoom}
            showCar={showCar}
            showDestinationMarker={showDestinationMarker}
            showPickupMarker={showPickupMarker}
            carPosition={carPosition}
            routeDirections={routeDirections}
            showRoute={showRoute}
            driverArrive={driverArrive}
            rideFinished={rideFinished}
          />
        )}
      </div>
      <div className="bookingAndDriverDetailMainBox mt-20">
        <div
          id="BookingDetailsMainDivHideShow"
          className="BookingDetailsMainDivShow"
        >
          <div
            className="BookingActivity"
            style={{
              backgroundColor: "rgb(255,255,255)",
              backgroundPosition: "initial initial",
              backgroundRepeat: "initial initial",
            }}
          >
            <div className="row">
              {/* <div className="col-1 col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1" /> */}
              <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <p
                  className="ec giveEllipsis fw-bold text-center mb-0"
                  style={{ color: "rgb(0, 0,0)", fontSize: 20 }}
                >
                  Ride Details
                </p>
              </div>
            </div>
            <hr />
          </div>

          <div className="my-2">
            <div className="d-flex justify-content-between">
              <div className="w-50">
                <p className="fw-bold mb-0">Estimated Price </p>
              </div>
              <div className="w-50">
                <p className="fw-bold mb-0 text-end">
                  {estimatedprice
                    ? "KD " + estimatedprice
                    : "Loading..."}
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="my-2">
            <div className="col-md-6 d-flex align-items-center">
              <img
                src="/assets/imgs/icons/ic_mastercard-2.png"
                alt="ic_mastercard-2@2x"
                width={35}
              />
              <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                #### 8888{" "}
              </p>
            </div>
          </div>
          <hr />

          <div className="driverDetails">
            <div className="row my-2">
              <div className="col-md-6">
                <img
                  width={60}
                  height={60}
                  alt="https://www.riderove.com/dev_rove/uploads/carimage/1703152152_OIP_(1).jpeg"
                  style={{ borderRadius: "50%", objectFit: "cover" }}
                  src={
                    driverDetail
                      ? driverDetail.car_image.replace(
                          "https://roveimage.s3.me-south-1.amazonaws.com/images",
                          "https://www.riderove.com/dev_rove/uploads"
                        )
                      : "/assets/imgs/ride_icons/Rider-image.jpg"
                  }
                />
              </div>
              <div className="col-md-6">
                <p className="fw-bold mb-1">
                  {driverDetail ? driverDetail.driver_name : "Loading..."}
                </p>
                <h6 className="fw-bold">
                  {driverDetail ? driverDetail.car_name_en : "Loading..."}
                </h6>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-6">
                <p className="fw-bold mb-1">Contact</p>
              </div>
              <div className="col-md-6">
                <h6 className="fw-bold">
                  {driverDetail ? driverDetail.mobile_number : "Loading..."}
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <p className="fw-bold mb-1">Ride Status</p>
              </div>
              <div className="col-md-6">
                <h6 className="fw-bold">
                  {rideStatus}
                  {/* {driverArrive
                    ? rideStart
                      ? rideFinished
                        ? "Ride finished"
                        : "Heading to destination"
                      : "Driver arrived"
                    : "Driver accept"} */}
                </h6>
              </div>
            </div>
            {showWaitingCharge && (
              <div className="row">
                <div className="col-md-6">
                  <p className="fw-bold mb-1">Waiting Charge</p>
                </div>
                <div className="col-md-6">
                  <h6 className="fw-bold">
                    KD 0.000 <ReverseTimer /> Mins
                  </h6>
                </div>
              </div>
            )}
            {showRideTiming && (
              <div className="row">
                <div className="col-md-6">
                  <p className="fw-bold mb-1">Duration of Ride</p>
                </div>
                <div className="col-md-6">
                  <h6 className="fw-bold">
                    {" "}
                    <TimeCount /> Mins{" "}
                  </h6>
                </div>
              </div>
            )}
            {showTotalTiming && (
              <div className="row">
                <div className="col-md-6">
                  <p className="fw-bold mb-1">Total Ride Time</p>
                </div>
                <div className="col-md-6">
                  <h6 className="fw-bold"> 15 Mins </h6>
                </div>
              </div>
            )}
            <div className="row my-3">
              <div
                className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xs-1"
                style={{ padding: "0 0px 0 5px" }}
              >
                <img
                  src="https://www.riderove.com/dev_rove/assets/img/ic_dot_reciept.png"
                  width={25}
                  alt="dot"
                />
              </div>
              <div className="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 col-xs-11">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xs-12">
                  <p
                    className="ec giveEllipsis fw-bold"
                    style={{ color: "rgb(0, 0,0)", marginLeft: "-10px" }}
                  >
                    {requestDetail
                      ? requestDetail.userdetails.pickup_address.split(",")[0] +
                        ", " +
                        requestDetail.userdetails.pickup_address.split(",")[1]
                      : "Loading..."}
                  </p>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xs-12">
                  <p
                    className="ec giveEllipsis fw-bold"
                    style={{ color: "rgb(0, 0,0)", marginLeft: "-10px" }}
                  >
                    {requestDetail
                      ? requestDetail.userdetails.destination_address.split(
                          ","
                        )[0] +
                        ", " +
                        requestDetail.userdetails.destination_address.split(
                          ","
                        )[1]
                      : "Loading..."}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <button className="btn btn-primary">
                <i className="bi bi-telephone fs-5"></i>
              </button>
            </div>
          </div>
          {/* <hr /> */}
          <div className="col-12 col-md-12 col-xl-12 col-lg-12 mb-3 d-flex justify-content-evenly">
            <button
              type="button"
              className="btn btn-whites "
              style={{ background: "#fff", color: "#000" }}
              onClick={() => setPrefrenceShow(true)}
            >
              Prefrence
            </button>
            <button
              type="button"
              className="btn btn-whites "
              style={{ background: "#fff", color: "#000" }}
              onClick={() => setForMeShow(true)}
            >
              Coupon
            </button>
            <i
              className="bi bi-x-square fs-3 d-none"
                        onClick={cancelCouponCodeHandle}
                      ></i>
          </div>

          {showCancel && (
            <>
              <hr />
              <div className="text-center">
                <button
                  onClick={cancelRideClick}
                  className="btn btn-cancle"
                  style={{ borderRadius: "38px", padding: "8px 57px" }}
                >
                  Cancel Ride
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      <Modal show={closeConfirmShow} onHide={handleCloseConfirm} centered>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Ride</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mx-20">
            <h6 className="fw-bold">
              Are you sure you want to cancel your ride ?
            </h6>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="d-flex justify-content-between">
            <Button
              className="btn btn-primary w-45"
              variant="primary"
              onClick={handleCloseConfirm}
            >
              No
            </Button>
            <button
              className="btn btn-primary w-45 mx-3"
              onClick={handleCancleRide}
            >
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={closeResoneShow} onHide={handleCloseResone} centered>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Ride</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row px-3">
            {cancel_reasons.map((item, inex) => (
              <div
                key={inex}
                style={{ cursor: "pointer" }}
                className={`col-12 col-md-12 d-flex align-items-center form-control ${
                  selectCancelReasone[item] ? "active" : ""
                } mb-2 pt-2 text-secondary`}
                onClick={() => {
                  handleCancelReasonChange(item);
                }}
              >
                {item}
              </div>
            ))}
          </div>
          {/* <div className="mx-20">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="resone"
                id="resone1"
              />
              <label className="form-check-label" for="resone1">
                I don't need ride anymore
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="resone"
                id="resone2"
              />
              <label className="form-check-label" for="resone2">
                I wan't to change my booking details
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="resone"
                id="resone3"
              />
              <label className="form-check-label" for="resone3">
                The car is not suitable
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="resone"
                id="resone4"
              />
              <label className="form-check-label" for="resone4">
                I couldn't contact the driver
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="resone"
                id="resone5"
              />
              <label className="form-check-label" for="resone5">
                The driver isn't moving
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="resone"
                id="resone6"
              />
              <label className="form-check-label" for="resone6">
                The driver couldn't find my location
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="resone"
                id="resone7"
              />
              <label className="form-check-label" for="resone7">
                The driver is too far
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="resone"
                id="resone8"
              />
              <label className="form-check-label" for="resone8">
                Other
              </label>
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-outline-danger "
              variant="primary"
              onClick={handleCloseResone}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary  mx-3"
              onClick={handleCancleRideReasone}
            >
              Confirm
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={forMeShow} onHide={handleForMeClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Apply Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-5">
            <div className="d-flex justify-content-center">
              <input
                type="text"
                className="form-control"
                placeholder="Enter coupon code"
                onChange={(e) => setCouponCode(e.target.value)}
                value={couponCode}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          {/* <div className="d-flex justify-content-between"> */}
            <button
              className="btn btn-outline-danger"
            onClick={handleForMeClose}
            type="button"
            style={{padding:"8px 24px"}}
            >
              Cancel
            </button>
            <button className="btn btn-primary mx-3" onClick={applyCouponRide}>
              Apply
            </button>
            <i
              className="bi bi-x-square fs-1 d-none"
                        onClick={cancelCouponCodeHandle}
                      ></i>
          {/* </div> */}
        </Modal.Footer>
      </Modal>
      <Modal show={prefrenceShow} onHide={handlePrefrenceClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Ride Preferences</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between mb-10">
            <h6 className="fw-bold">Door Assistance</h6>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
              />
            </div>
          </div>
          <div className="mx-10">
            <h6 className="fw-bold my-3">Conversation</h6>

            <div className="row">
              <div className="col-md-3 w-33">
                <div
                  className={`conversation-selection text-center me-3 ${
                    conversation.first ? "active" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setConversation({
                      first: true,
                      second: false,
                      third: false,
                    })
                  }
                >
                  Don't Mind
                </div>
              </div>
              <div className="col-md-3 w-33">
                <div
                  className={`conversation-selection text-center me-3 ${
                    conversation.second ? "active" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setConversation({
                      second: true,
                      first: false,
                      third: false,
                    })
                  }
                >
                  No Thanks
                </div>
              </div>
              <div className="col-md-3 w-33">
                <div
                  className={`conversation-selection text-center me-3 ${
                    conversation.third ? "active" : ""
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setConversation({
                      third: true,
                      second: false,
                      first: false,
                    })
                  }
                >
                  Yes Please
                </div>
              </div>
            </div>
          </div>
          <div className="mx-10">
            <h6 className="fw-bold my-3">Temperature</h6>

            <div className="row">
              <div className="col-md-3 w-33">
                <div
                  style={{ cursor: "pointer" }}
                  className={`conversation-selection text-center me-3 ${
                    temperature.normal ? "active" : ""
                  }`}
                  onClick={() =>
                    setTemperature({ normal: true, cold: false, warm: false })
                  }
                >
                  Normal
                </div>
              </div>
              <div className="col-md-3 w-33">
                <div
                  style={{ cursor: "pointer" }}
                  className={`conversation-selection text-center me-3 ${
                    temperature.cold ? "active" : ""
                  }`}
                  onClick={() =>
                    setTemperature({ cold: true, normal: false, warm: false })
                  }
                >
                  Cold
                </div>
              </div>
              <div className="col-md-3 w-33">
                <div
                  style={{ cursor: "pointer" }}
                  className={`conversation-selection text-center me-3 ${
                    temperature.warm ? "active" : ""
                  }`}
                  onClick={() =>
                    setTemperature({ warm: true, normal: false, cold: false })
                  }
                >
                  Warm
                </div>
              </div>
            </div>
          </div>
          <div className="mx-10">
            <h6 className="fw-bold my-3">Additional Note</h6>
            <div className="d-flex justify-content-center">
              <input
                type="text"
                className="form-control"
                placeholder="Write somthing ..."
                style={{ background: "#ebebeb" }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-outline-danger"
              onClick={handlePrefrenceClose}
            >
              Cancel
            </button>

            <button
              className="btn btn-primary  ms-3"
              onClick={handlePrefrenceClose}
            >
              Apply
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={rateing} onHide={handleRatingClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Rate Your Trip</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center">
            <Rating width={30} height={30}></Rating>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <button className="btn btn-light " onClick={handleRatingClose}>
            Cancel
          </button>
          <button
            className="btn btn-primary "
            onClick={() => {
              setRateinf(false);
              setRateReason(true);
            }}
          >
            Done
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={rateReason} onHide={handleRateReasonClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Rate Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex justify-content-center mb-4">
              <Rating width={30} height={30} initialState={3}></Rating>
            </div>

            <h6 className="fw-bold">Why did you rate 3 stars ?</h6>

            <div className="row my-3 px-3">
              {Reasons.map((item, inex) => (
                <div
                  key={inex}
                  style={{ cursor: "pointer" }}
                  className={`col-12 col-md-12 form-control ${
                    selectRateReasone[item] ? "active" : ""
                  } my-2 pt-3 text-secondary`}
                  onClick={() => {
                    handleSelectReasone(item);
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <button className="btn btn-light" onClick={handleRateReasonClose}>
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              setRateReason(false);
              navigate("/ride-reciept");
            }}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default RideDetail;
