import React from "react";
import Sidebar from "../components/common/Sidebar";
import { Link, useLocation } from "react-router-dom";

const AboutPage = () => {
  const location = useLocation();
  return (
    <main className="main">
      <section className="section mt-40 mb-100" id="about-us">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-md-1 col-lg-1 "></div>
            <div className=" col-md-2 col-lg-2 mb-50">
              <Sidebar pathname={location.pathname}></Sidebar>
            </div>
            <div className="col-md-6 col-lg-6 px-5 mb-50">
              <div
                // style={{ height: "80vh" }}
                className="content d-flex flex-column justify-content-between align-items-center"
              >
                <div className="">
                  <img
                    alt="Ecom"
                    src="/assets/imgs/template/logo-rove-dark.png"
                    className="mb-5"
                    width={300}
                  />
                  <p className="fs-4 mb-0 text-rove">
                    Ride, Relax & Reach with our luxurious
                  </p>
                  <p className="fs-4 mb-0 text-rove">
                    chauffeur service 24/7. You can instantly
                  </p>
                  <p className="fs-4 mb-0 text-rove">
                    book your ride with pick up now or pick up
                  </p>
                  <p className="fs-4 mb-0 text-rove">
                    Later We operate all around Kuwait.
                  </p>
                </div>
                <div className="row mt-5 mb-5 w-100">
                  <div className="col-md-6 col-lg-6 mb-2 px-3">
                    <a
                      href="https://apps.apple.com/us/app/riderove/id1456118203?action=write-review"
                      target="_blank"
                      className="btn btn-light fw-bold w-100"
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        <img
                          alt="apple"
                          className="me-3"
                          src="/assets/imgs/icons/apple-border.png"
                          width={30}
                        />
                        <span className="ms-2">Rate us on App Store</span>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-6 col-lg-6 px-3">
                    <a
                      href="https://www.instagram.com/rideroveapp/?hl=en"
                      target="_blank"
                      className="btn btn-light fw-bold w-100"
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        <img
                          alt="Ecom"
                          className="me-1"
                          src="/assets/imgs/icons/instagram-border.png"
                          width={30}
                        />
                        <span className="ms-2">Follow us on Instagram</span>
                      </div>
                    </a>
                  </div>
                </div>

                {/* <div className="row  mt-5 mb-5">
                  <div className="col-md-6 col-lg-6 mb-2 px-0">
                    <a
                      href="https://apps.apple.com/us/app/riderove/id1456118203?action=write-review"
                      target="_blank"
                      className="btn btn-light fw-bold w-100"
                    >
                      <div className="d-flex align-items-center">
                        <img
                          alt="apple"
                          className="me-3"
                          src="/assets/imgs/icons/apple-border.png"
                          width={30}
                        />
                        <span className="ms-2">Rate us on App Store</span>
                      </div>
                    </a>
                  </div>
                  <div className="col-md-4 col-lg-4">
                    <button className="btn btn-light fw-bold">
                      <div className="d-flex align-items-center">
                        <img
                          alt="Ecom"
                          className="mx-3"
                          src="/assets/imgs/icons/facebook-border.png"
                          width={30}
                        />
                        <span className="me-3">Like us on Facebook</span>
                      </div>
                    </button>
                  </div> 
                  <div className="col-md-6 col-lg-6 px-0">
                    <a
                      href="https://www.instagram.com/rideroveapp/?hl=en"
                      target="_blank"
                      className="btn btn-light fw-bold w-100"
                    >
                      <div className="d-flex align-items-center">
                        <img
                          alt="Ecom"
                          className="me-3"
                          src="/assets/imgs/icons/instagram-border.png"
                          width={30}
                        />
                        <span className="">Follow us on Instagram</span>
                      </div>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-md-3 col-lg-3 px-5 trip-last-section">
              <img
                // src="/assets/imgs/icons/my-trip-2.svg"
                src="/assets/imgs/bg/cars2.jpg"
                alt="my-trip-2"
                className="mb-3"
              />
              <h6>Get a ride in minutes</h6>
              <p>Book an Rove from a web browser, no app install necessary.</p>
              <div className="mt-20 w-70">
                <Link
                  className="btn btn-primary d-flex justify-content-center align-items-center gap-3"
                  to="/dashboard"
                >
                  Request a Ride
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AboutPage;
