import React from "react";
import { Link } from "react-router-dom";

function Register() {
  return (
    <main className="main">
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <section className="section mt-120 mb-100">
        <div className="container-sub">
          <div className="text-center">
            <h2
              className="heading-44-medium wow fadeInUp"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              Creat Account
            </h2>
            <p
              className="color-text text-14 wow fadeInUp"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              Sign in with this account across the following sites.
            </p>
          </div>
          <div className="box-login mt-70">
            <div
              className="form-contact form-comment wow fadeInUp"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <form action="#">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fullname">
                        Username
                      </label>
                      <input
                        className="form-control"
                        id="fullname"
                        type="text"
                        defaultValue=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="Email">
                        Email
                      </label>
                      <input
                        className="form-control"
                        id="Email"
                        type="text"
                        defaultValue=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                      <input
                        className="form-control"
                        id="password"
                        type="password"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="confirmpass">
                        Confirm Password
                      </label>
                      <input
                        className="form-control"
                        id="confirmpass"
                        type="password"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="phone">
                        Phone
                      </label>
                      <input
                        className="form-control"
                        id="phone"
                        type="tel"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <button className="btn btn-primary w-100" type="submit">
                      Create Account
                      <svg
                        className="icon-16 ml-5"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="col-lg-12">
                    <div className="text-or-box">
                      {" "}
                      <span className="text-or">OR</span>
                    </div>
                    <div className="mb-20">
                      <a
                        className="btn btn-login-google d-flex justify-content-center align-items-center gap-3"
                        href="#"
                      >
                        <img
                          src="assets/imgs/template/icons/google-icon-login.svg"
                          alt="google-icon-login"
                        />
                        Continue Google
                      </a>
                    </div>
                    <div className="mb-20">
                      <a
                        className="btn btn-login-apple d-flex justify-content-center align-items-center gap-3"
                        href="#"
                      >
                        <img
                          src="assets/imgs/template/icons/apple-icon-white.svg"
                          alt="apple-icon-white"
                        />
                        Continue Apple
                      </a>
                    </div>
                  </div>
                  <div className="mt-0 text-center">
                    {" "}
                    <span className="text-14-medium color-text">
                      Already a Member?{" "}
                    </span>
                    <Link className="text-14-medium color-text" to="/login">
                      Login
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Register;
