import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Dropdown from 'react-bootstrap/Dropdown';
import { getProfileDataApi, addWalletTapWebAPI } from "../../api/api";
import { TapCard, Currencies, Direction, Edges, Locale, Theme, Scope, ColorStyle } from '@tap-payments/card-web'

const tap_secret = process.env.REACT_APP_TAP_PAYMENT_LIVE == "0" ? process.env.REACT_APP_TAP_PAYMENT_SECRET_TEST : process.env.REACT_APP_TAP_PAYMENT_SECRET;
const tap_public = process.env.REACT_APP_TAP_PAYMENT_LIVE == "0" ? process.env.REACT_APP_TAP_PAYMENT_PUBLIC_TEST : process.env.REACT_APP_TAP_PAYMENT_PUBLIC;

const Payments = () => {
  const location = useLocation();
  const [userDetail, setUserDetail] = useState(() => {
    var user = localStorage.getItem("user_data");
    return user ? JSON.parse(user) : null;
  })
  const [userProfileData, setUserProfileData] = useState();
  const [forCreditCardShow, setForCreditCardShow] = useState(false);
  const handleForCreditCardClose = () => setForCreditCardShow(false); 

  
  useEffect(() => {
    if (userDetail) {      
      get_profile_data();
      // get_enable_features_list()
    }
    var text_input = document.querySelector("#other_amount");
    if (location.state) {
      const { topUp } = location.state;

      if (topUp && (!text_input.value) ) {
        text_input.value = topUp;
      };
    };
  }, [userDetail]);

  const get_profile_data = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
        user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await getProfileDataApi(form);
      // console.log(res)
      var data = res.data;
      if (data.status == "1") {
        setUserProfileData(data);
      } else {
        console.log("Error : get profile data", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editCardHandler = async (e) => {
    
  }
  
  const deleteCardHandler = async (e) => {

  }

  const cardHandler = () => {
    setForCreditCardShow(true);
  }

  const handleSaveCardChange = () => {
    console.log('save card')
  }

  return (
    <main className="main">
      <section className="section mt-40 " id="tax-page">
        <div className="container">
          <div className="row">
            <div className="offset-2 col-md-8">
              <div className="text-center">
                <h3>Payments</h3>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="offset-2 col-md-8">
              <h5 className="text-muted mt-3">Saved Credit Cards</h5>
              <div className="card mt-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        src={`/assets/imgs/icons/visa@2x.png`}
                        alt="ic_mastercard-2@2x"
                        width={50}
                      />
                      <div className="pull-right">
                        <button className="btn btn-basic">Default</button>
                      </div>
                      <p className="mt-4 fw-bold fs-6 mb-0">
                        <i>{`#### #### ####`}</i> 4242
                      </p>
                      <div className="mt-4 fw-bold fs-6 mb-0">
                        <strong><span className="uppercase">test</span></strong>&nbsp;<span className="bullet">&bull;</span>&nbsp;
                        <span className="">02/25</span>
                        <div className="pull-right">
                          <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic" className="dot-icon">
                              <i className="fa fa-ellipsis-v"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#" onClick={() => editCardHandler()}>
                                <i className="fa fa-pencil"></i>&nbsp;Edit
                              </Dropdown.Item>
                              <Dropdown.Item href="#" onClick={() => deleteCardHandler()}>
                                <i className="fa fa-trash"></i>&nbsp;Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="mt-3">No Credit Card added yet</h4>
              <div className="mt-4">
                <button className="btn btn-card w-100" type="button" onClick={cardHandler}>
                  <i className="fa fa-plus"></i>&nbsp; Add Credit Card
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal size="lg" show={forCreditCardShow} onHide={handleForCreditCardClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enter Card Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TapCard
            scope={Scope.TOKEN}
            operator={{
              publicKey: tap_public
            }}
            transaction={{
              reference: 'transaction_xxx',
              paymentAgreement: {
                id: '',
                contract: {
                  id: ''
                }
              }
            }}
            order={{
              id: '',
              amount: 1,
              currency: Currencies.KWD,
              description: 'description',
              reference: '',
              metadata: { key: 'value' }
            }}
            invoice={{
              id: 'invoice_xxx'
            }}
            customer={{
              // id: 'cus_TS01A1120241425Ju991206409',
              id: userProfileData?.user_profile.tap_customer_id,
              name: [
                {
                  lang: Locale.EN,
                  first: userProfileData?.user_profile.name_eng ? userProfileData?.user_profile.name_eng.split(' ')[0] : '',
                  last: userProfileData?.user_profile.name_eng ? userProfileData?.user_profile.name_eng.split(' ')[1] : '',
                  middle: ''
                }
              ],
              nameOnCard:  userProfileData?.user_profile.name_eng,
              editable: true,
              contact: {
                email: userProfileData?.user_profile.user_email,
                phone: {
                  countryCode: userProfileData?.user_profile.country_code,
                  number: userProfileData?.user_profile.mobileno
                }
              }
            }}
            merchant={{
              id: ''
            }}
            interface={{
              locale: Locale.EN,
              cardDirection: Direction.DYNAMIC,
              edges: Edges.CURVED,
              theme: Theme.DYNAMIC,
              powered: true,
              colorStyle: ColorStyle.COLORED,
              loader: true
            }}
            features={{
              acceptanceBadge: true,
              customerCards: {
                saveCard: true,
                autoSaveCard: false,
              }
            }}
            fieldsVisibility={{
              card: {
                cardHolder: true
              }
            }}
            acceptance={{
              supportedSchemes: ['AMEX', 'VISA', 'MASTERCARD', 'AMERICAN_EXPRESS'],
              supportedFundSource: ['CREDIT', 'DEBIT'],
              supportedPaymentAuthentications: ['3DS']
            }}
            post={{
              url: "https://tap.company"
            }}
            onReady={() => console.log('onReady')}
            onFocus={() => console.log('onFocus')}
            onBinIdentification={(data) => console.log('onBinIdentification', data)}
            onValidInput={(data) => console.log('onValidInputChange', data)}
            onError={(data) => console.log('onError', data)}
            onChangeSaveCardLater={(data) => console.log('onChangeSaveCardLater', data)}
            // onChangeSaveCardLater={handleSaveCardChange}
            onSuccess={(data) => console.log('onSuccess', data)}
          />
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default Payments;
