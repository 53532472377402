import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const TaxPage = () => {
  return (
    <main className="main">
      {/* eslint-disable jsx-a11y/anchor-is-valid */}

      <section className="section mt-40 mb-100 me-5" id="tax-page">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-md-1 col-lg-1 "></div>
            <div className="col-md-2 col-lg-2 ">
              <button href="#" className="btn text-profile activ">
                My Rides
              </button>
            </div>
            <div className="offset-1 col-md-6 col-lg-6">
              <div className="row">
                <div className="col-lg-12">
                  <div id="tax_profile_section">
                    <Tabs
                      defaultActiveKey="personal_tax"
                      id="tax-profile-tab"
                      className="mb-3"
                      fill
                    >
                      <Tab eventKey="personal_tax" title="Past Rides">
                        <div className="row">
                          <div className="col-md-12">
                            <h3 className="fw-bold my-2 mb-20">Past Rides</h3>
                            <div id="past-rides">
                              <div className="card">
                                <div className="card-body">
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="business_tax" title="Upcomming Rides">
                        <h3 className="fw-bold my-2 mb-20">Upcomming Rides</h3>
                        <form className="" method="post">
                          <div className="form-group">
                            <label for="name" className="fw-bolder">
                              Company / Legal Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder=""
                            />
                          </div>
                          <div className="form-group">
                            <label for="pan" className="fw-bolder">
                              PAN
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="pan"
                              placeholder="AAAAA9999A"
                            />
                            <p className="text-muted">e.g. AAAAA9999A</p>
                          </div>
                          <div className="form-group">
                            <label for="street_address" className="fw-bolder">
                              Street Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="street_address"
                              placeholder=""
                            />
                          </div>
                          <div className="form-group">
                            <label for="city" className="fw-bolder">
                              City
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="city"
                              placeholder=""
                            />
                          </div>
                          <div className="form-group">
                            <label for="state" className="fw-bolder">
                              State
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="state"
                              placeholder=""
                            />
                          </div>
                          <div className="form-group">
                            <label for="pin_code" className="fw-bolder">
                              Pin Code
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="pin_code"
                              placeholder=""
                            />
                          </div>
                          <div className="form-group">
                            <label for="country" className="fw-bolder">
                              Country
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="country"
                              disabled="disabled"
                              value="India"
                              placeholder=""
                            />
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="check1"
                              name="option1"
                              value="1"
                            />
                            <label className="form-check-label">
                              Disclaimer
                            </label>
                          </div>
                          <div className="">
                            <ol className="list-group list-group-numbered">
                              <li className="list-group-item1">
                                <p>
                                  1. I confirm that the information I have
                                  provided to Rove regarding my local address,
                                  State and PAN is correct. I consent expressly
                                  to Rove to issue GST compliant invoice on my
                                  behalf, for the services I provides through
                                  Rove Platform
                                </p>
                              </li>
                              <li className="list-group-item1">
                                <p>
                                  2. I understand and agree that in case there
                                  is any change in information I have provided
                                  to Rove, it is my obligation and
                                  responsibility to inform such changes to Rove.
                                  I understand that I shall be liable to any tax
                                  liability arising on account of furnishing
                                  incorrect information and / or not informing
                                  any change in information to Rove
                                </p>
                              </li>
                              <li className="list-group-item1">
                                <p>
                                  3. I acknowledge and agree that Rove does not
                                  undertake any responsibility if I provide
                                  false information, including but not limited
                                  to information related to my local address &
                                  PAN
                                </p>
                              </li>
                            </ol>
                          </div>
                          <div className="form-group w-100">
                            <button
                              type="button"
                              className="btn btn-primary w-100"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 px-5"></div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default TaxPage;
