import { getToken } from "firebase/messaging";
import { messaging } from "../firebase/firebase";

export function requestNotificationPermission() {
  return Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
      return getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPIDKEY,
      });
    } else if (permission === "denied") {
      console.log("Notification permission denied.");
      throw new Error("Notification permission denied.");
    } else {
      console.log("Notification permission not granted.");
      throw new Error("Notification permission not granted.");
    }
  });
}
