import { useState, useEffect, useRef } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  TrafficLayer,
  Polyline,
} from "react-google-maps";
const custom_style = require("../../custom_style.json");

const options = {
  mapTypeId: "terrain",
  disableDefaultUI: true,
  zoomControl: true,
  styles: custom_style,
};

const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");
const {
  MarkerClusterer,
} = require("react-google-maps/lib/components/addons/MarkerClusterer");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
const {
  DrawingManager,
} = require("react-google-maps/lib/components/drawing/DrawingManager");

const google = window.google;

const MapWithAMarker = withGoogleMap(
  ({
    handlePickupDrag,
    handleDestinationDrag,
    zoom,
    pickupPosition,
    position,
    destinationPosition,
    routeDirections,
    driverPositions,
    path,
  }) => {
    const mapRef = useRef(null);
     useEffect(() => {
      if (path && mapRef.current) {
        const bounds = new google.maps.LatLngBounds();
        path.forEach((point) => {
          bounds.extend(new google.maps.LatLng(point.lat, point.lng));
        });
        mapRef.current.fitBounds(bounds);
      }
    }, [path]);
    return (
    <GoogleMap defaultZoom={zoom} center={position} options={options} ref={mapRef}>
      <>
        {/* {console.log(typeof pickupPosition.lat)} */}
        {/* {console.log("MAP position",position)} */}
          {/* {console.log(zoom)}  */}
        {/* {console.log(path)} */}
        {driverPositions &&
          Array.isArray(driverPositions) &&
          driverPositions.map((v, i) => (
            <MarkerWithLabel
              key={i}
              position={{
                lat: parseFloat(v.latitude),
                lng: parseFloat(v.longitude),
              }}
              cursor="pointer"
              icon={{
                url: "/assets/imgs/icons/black_car_marker.png",
                scaledSize: new google.maps.Size(15, 30),
              }}
              labelAnchor={new google.maps.Point(-10, 65)}
            >
              <div></div>
            </MarkerWithLabel>
          ))}

        {pickupPosition !== undefined && pickupPosition.text !== undefined ? (
          <MarkerWithLabel
            position={{
              lat: parseFloat(pickupPosition.lat),
              lng: parseFloat(pickupPosition.lng),
            }}
            cursor="hand"
            icon="/assets/imgs/icons/pickupmarker.png"
            draggable={true}
            onDragEnd={handlePickupDrag}
            labelAnchor={new google.maps.Point(0, 0)}
            labelStyle={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fff",
              fontSize: "15px",
              fontWeight: "700",
              padding: "10px",
              whiteSpace: "nowrap",
              transform: "translateX(-50%)",
              zIndex: "1",
            }}
          >
            <div>
              {pickupPosition.text}&nbsp;&nbsp;
              {/* <i className="fa fa-chevron-right"></i> */}
            </div>
          </MarkerWithLabel>
        ) : (
          ""
        )}

        {destinationPosition !== undefined &&
        destinationPosition.text !== undefined ? (
          <MarkerWithLabel
            position={destinationPosition}
            cursor="hand"
            icon="/assets/imgs/icons/destination.png"
            draggable={true}
            onDragEnd={handleDestinationDrag}
            labelAnchor={new google.maps.Point(0, 0)}
            labelStyle={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fff",
              fontSize: "15px",
              fontWeight: "700",
              padding: "10px",
              whiteSpace: "nowrap",
              transform: "translateX(-50%)",
              zIndex: "1",
            }}
          >
            <div>{destinationPosition.text}&nbsp;&nbsp;</div>
          </MarkerWithLabel>
        ) : (
          ""
        )}
        {routeDirections && (
          <DirectionsRenderer
            directions={routeDirections}
            defaultOptions={{
              suppressMarkers: true,
              polylineOptions: { strokeColor: "#213d4a" },
            }}
          />
        )}
        {path && (
          <>
            <Polyline
              path={path}
              options={{
                strokeColor: "#213d4a",
                strokeOpacity: 1.0,
                strokeWeight: 3,
              }}
            />
            {/* <Polyline
            path={path.slice(8,13)}
            options={{
              strokeColor: '#213d4a',
              strokeOpacity: 1.0,
              strokeWeight: 3,
              }}
              /> */}
          </>
        )}

        {/*<TrafficLayer autoUpdate />*/}
      </>
    </GoogleMap>
  )}
);

export default MapWithAMarker;
