import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Link,
  createSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import DatePicker from "react-datepicker";
import { UserContext } from "../../utils/userContext";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment-timezone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  TrafficLayer,
} from "react-google-maps";
import MapWithAMarker from "../common/MapDashboard";
import useChangeBackButtonPath from "../../utils/hooks/useChangeBackHistory";
import {
  driverRequestApi,
  enableFeaturesApi,
  fareBreakDownApi,
  getDriverApi,
  getProfileDataApi,
  getRequestDriverApi,
  nearSearchLocationApi,
  priceCalculateApi,
  validateCouponApi,
  homeScreenNotificationApi,
  getLongRentalCarApi,
  getFavPlaceApi,
  createCustomerIdApi,
} from "../../api/api";
import MapComponent from "../common/DragMap";
// import { data } from "jquery";
import $, { data } from "jquery";
import e from "cors";

const custom_style = require("../../custom_style.json");

const options = {
  mapTypeId: "terrain",
  disableDefaultUI: true,
  zoomControl: true,
  styles: custom_style,
};

const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");
const {
  MarkerClusterer,
} = require("react-google-maps/lib/components/addons/MarkerClusterer");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
const {
  DrawingManager,
} = require("react-google-maps/lib/components/drawing/DrawingManager");

const google = window.google;

const BASE_IMG_URL = "https://www.riderove.com/dev_rove/uploads/";

const tap_secret = process.env.REACT_APP_TAP_PAYMENT_LIVE == "0" ? process.env.REACT_APP_TAP_PAYMENT_SECRET_TEST : process.env.REACT_APP_TAP_PAYMENT_SECRET;

const Dashboard = ({ enableFeatures }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const ctx = useContext(UserContext);
  const [showRideDetail, setShowRideDetail] = useState(false);
  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const autoCompleteRef2 = useRef();
  const inputRef2 = useRef();

  const [couponCode, setCouponCode] = useState("");

  const [searchDisable, setSearchDisable] = useState(true);
  const [htmlFileString, setHtmlFileString] = useState();
  useChangeBackButtonPath("/dashboard");
  Number.prototype.padLeft = function (base, chr) {
    var len = String(base || 10).length - String(this).length + 1;
    return len > 0 ? new Array(len).join(chr || "0") + this : this;
  };

  async function fetchHtml() {
    setHtmlFileString(await (await fetch(`termsandcondition.html`)).text());
  }
  var d = new Date(),
    today = [
      d.getFullYear(),
      (d.getMonth() + 1).padLeft(),
      d.getDate().padLeft(),
    ].join("-");
  var time = [
    d.getHours().padLeft(),
    d.getMinutes().padLeft(),
    d.getSeconds().padLeft(),
  ].join(":");
  // {
  //   lat: 29.2618672,
  //   lng: 47.8766367,
  //   text: "",
  // }
  const [position, setPosition] = useState();
  const [currantPosition, setCurrantPosition] = useState();
  const [pickupBlank, setPickupBlank] = useState(false);
  const [zoom, setZoom] = useState(13);
  // {
  //   lat: 29.24182011471483,
  //   lng: 47.972080036997795,
  //   text: "King Faisal Motorway, Airport",
  // }
  const [pickupPosition, setPickupPosition] = useState();
  const [destinationPosition, setDestinationPosition] = useState({});
  const [routeDirections, setRouteDirections] = useState({});
  const [requestRide, setRequestRide] = useState(false);
  const [forMeShow, setForMeShow] = useState(false);
  const [guestShow, setGuestShow] = useState(false);
  const [carDetailShow, setCarDetailShow] = useState(false);
  const [carType, setCarType] = useState({ eco: true, lux: false, any: false });
  const [startDate, setStartDate] = useState(new Date());
  const [noOfCars, setNoOfCars] = useState(false);
  const [longRide, setLongRide] = useState(false);
  const [airportRide, setAirportRide] = useState(false);
  const [showPickupLater, setShowPickupLater] = useState(false);
  const [noOfPerson, setNoOfPerson] = useState({ 1: true, 2: false, 3: false });

  const [estimatedprice, setEstimatedprice] = useState("Loading...");

  const [noOfCarForRide, setNoOfCarForRide] = useState(1);
  const [noOfBags, setNoOfBags] = useState({
    0: true,
    1: false,
    2: false,
    3: false,
  });
  const [rideType, setRideType] = useState({
    0: true, // pickup now
    1: false, // pickup later
    2: false, // longride
    3: false, // airport
  });

  let initial = {
    4: true,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
    12: false,
    13: false,
    14: false,
    15: false,
    16: false,
    17: false,
    18: false,
    19: false,
    20: false,
    21: false,
    22: false,
    23: false,
    24: false,
  };

  const [Hours, setHours] = useState([]);

  const [noOfHours, setNoOfHours] = useState(initial);
  // const { user_data } =  location.state;

  const [userDetail, setUserDetail] = useState(() => {
    var user = localStorage.getItem("user_data");
    return user ? JSON.parse(user) : null;
  });
  const [userProfileData, setUserProfileData] = useState();
  const [enableFeaturesList, setEnableFeaturesList] = useState();
  const [nearSearchLocation, setNearSearchLocation] = useState();
  const [getDriverDetail, setGetDriverDetail] = useState();
  const [carFareBreakdown, setCarFareBreakdown] = useState();
  const [priceCalculation, setPriceCalculation] = useState();
  const [homeScreenData, setHomeScreenData] = useState();
  const [path, setPath] = useState();

  const [userBlock, setUserBlock] = useState(false);
  const [userBlockModal, setUserBlockModal] = useState(false);
  const [userBlockMessage, setUserBlockMessage] = useState();

  const [addWalletShow, setAddWalletShow] = useState(false);
  const [addWalletModal, setAddWalletModal] = useState(false);
  const [walletDiff, setWalletDiff] = useState(0);

  const [addEmailShow, setAddEmailShow] = useState(false);
  const [addEmailModal, setAddEmailModal] = useState(false);
  const [addNameShow, setAddNameShow] = useState(false);

  const [rebook, setRebook] = useState();
  const intervalRef = useRef(null);
  const getDriverRef = useRef(null);

  const [cardDetail, setCardDetail] = useState(null);

  const [paymentModal, setPaymentModal] = useState(false);
  const [advancePaymentModal, setAdvancePaymentModal] = useState(false);

  const [longRidePopup, setLongRidePopup] = useState();
  const [longRideHours, setLongRideHours] = useState();
  const [longRideCars, setLongRideCars] = useState();
  const [selectedCar, setSelectedCar] = useState();
  const [longRidePriceEst, setLongRidePriceEst] = useState();
  const [selelectPayMode, setSelectPayMode] = useState(0); // 0 card 1 knet 2 apple pay
  const [favPlaces, setFavPlaces] = useState();
  const [combinePlaces, setCombinePlaces] = useState();

  const [searchPickup, setSearchPickup] = useState(false);
  const [searchDestination, setSearchDestination] = useState(false);
  const [recentPlaces, setRecentPlaces] = useState(() => {
    var rp = localStorage.getItem("recent-places");
    return rp ? JSON.parse(rp) : null;
  })

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 6);
  // window.onpopstate = () => {
  //   navigate("/dashboard");
  // }

  
  useEffect(() => {
    const rebookData = localStorage.getItem("rebookdata");
    if (rebookData) {
      var rebook_data = JSON.parse(rebookData);
      var pickup_data = rebook_data.pickup;
      var destination_data = rebook_data.destination;
      var pickup_lat = pickup_data.split(",")[0];
      var pickup_long = pickup_data.split(",")[1];
      var destination_lat = destination_data.split(",")[0];
      var destination_long = destination_data.split(",")[1];
      setPosition({
        lat: parseFloat(pickup_lat),
        lng: parseFloat(pickup_long),
      });
      setDestinationPosition({
        lat: parseFloat(destination_lat),
        lng: parseFloat(destination_long),
      });
      setPickupPosition({
        lat: parseFloat(pickup_lat),
        lng: parseFloat(pickup_long),
      });
      near_search_location(destination_lat, destination_long).then((data) => {
        var userLocation = data.user_location_data;
        var des = document.querySelector("#Location2");
        des.value =  userLocation.title_address;
        // inputRef2.current.value = userLocation.title_address;
      });
      near_search_location(pickup_lat, pickup_long).then((data) => {
        var userLocation = data.user_location_data;

        var pic = document.querySelector("#Location1");
        pic.value =  userLocation.title_address;
        // inputRef.current.value = userLocation.title_address;
        var p = {
          lat: parseFloat(userLocation.latitude),
          lng: parseFloat(userLocation.longitude),
          text: near_search_condition(data),
        };
        setPickupPosition(p);
        get_driver(pickup_lat, pickup_long);
        var dest_position = {
          lat: parseFloat(destination_lat),
          lng: parseFloat(destination_long),
        };
        if (Object.keys(dest_position).length && p) {
          price_calculation(
            pickup_lat,
            pickup_long,
            destination_lat,
            destination_long
          ).then((p_r) => {
            var esti_pr =
              parseFloat(p_r.estimatedprice) - parseFloat(p_r.discount_price);
            esti_pr = esti_pr.toFixed(3);
            setDestinationPosition({
              ...dest_position,
              text: "KD " + esti_pr,
            });
          });
          aftersetDestinationPosition(p, dest_position);
        }
      });
      localStorage.removeItem("rebookdata");
    } else {
      fetchLocation();
    }
  }, []);

  useEffect(() => {
    if (!htmlFileString) {
      fetchHtml();
    }
    if (Object.keys(destinationPosition).length) {
      setSearchDisable(false);
    } else {
      setSearchDisable(true);
    }
  }, [pickupPosition, destinationPosition]);

  useEffect(() => {
    if (userDetail) {
      // homeScreenNotification();
      if (!favPlaces) {
        fetchFavoriteLocations()
      }
      if (enableFeatures) {
        setEnableFeaturesList(enableFeatures);
        findBlockUserMessage(enableFeatures);
      } else {
        if (ctx.enableFeaturesList) {
          setEnableFeaturesList(ctx.enableFeaturesList);
          findBlockUserMessage(ctx.enableFeaturesList);
        } else {
          get_enable_features_list();
        }
      }
      if (!userProfileData && enableFeaturesList) {
        get_profile_data();
      }
      // near_search_location();
      // get_driver();
    }
    intervalRef.current = setInterval(() => {
      if (userDetail && pickupPosition) {
        near_search_location();
      }
    }, 1000 * 60 * 2);
    getDriverRef.current = setInterval(() => {
      if (userDetail && pickupPosition) {
        get_driver();
      }
    }, 6000);

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalRef.current);
      clearInterval(getDriverRef.current);
    };
  }, [userDetail, pickupPosition]);

  useEffect(() => {
    const rebookData = localStorage.getItem("rebookdata");
    if (rebookData) {
      var rebook_data = JSON.parse(rebookData);
      var pickup_data = rebook_data.pickup;
      var destination_data = rebook_data.destination;
      var pickup_lat = pickup_data.split(",")[0];
      var pickup_long = pickup_data.split(",")[1];
      var destination_lat = destination_data.split(",")[0];
      var destination_long = destination_data.split(",")[1];
      setPickupPosition({
        lat: pickup_lat,
        lng: pickup_long,
        text: "test",
      });
    }
  }, []);

  const findBlockUserMessage = (data) => {
    var block_msg = data.all_static_message.find((e) => e["you_are_blocked"]);
    if (block_msg) {
      // console.log(block_msg.you_are_blocked.you_are_blocked_en);
      setUserBlockMessage(block_msg.you_are_blocked.you_are_blocked_en);
    }
  };

  const options1 = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
    componentRestrictions: { country: ["IN", "KW"] },
  };

  const handleAddEmailModalClose = () => {
    setAddEmailModal(false);
  };

  const handleUserBlockModalClose = () => {
    setUserBlockModal(false);
  };

  const handleDestinationClose = () => {
    setSearchDestination(false);
    setCombinePlaces()
  }

  const handlePickupClose = () => {
    setSearchPickup(false);
    setCombinePlaces()
  }

  const handleAddWalletModalClose = () => {
    setAddWalletModal(false);
  };

  const paymentModalHandler = () => {
    setPaymentModal(false);
  };

  const advancePaymentModalClose = () => {
    setAdvancePaymentModal(false);
  };

  const selectedDestination = async data => {
   /*  var des = document.querySelector("#Location2");
    des.value = data.address; */
    // des.value = data.address.split(",")[0];
    saveRecentPalces(data);
    handleDestinationClose();
    var p_r = await price_calculation(
      pickupPosition.lat,
      pickupPosition.lng,
      data.latitude,
      data.longitude
    );
    var  esti_pr =
      parseFloat(p_r.estimatedprice) - parseFloat(p_r.discount_price);
    esti_pr = esti_pr.toFixed(3);
    near_search_location(data.latitude, data.longitude).then((data) => {
      var userLocation = data.user_location_data;
      var des = document.querySelector("#Location2");
      des.value = userLocation.title_address;
      // inputRef.current.value = userLocation.title_address;
      var dp = {
        lat: parseFloat(userLocation.latitude),
        lng: parseFloat(userLocation.longitude),
        text: "KD " + esti_pr,
        add: userLocation.title_address,
      };
      setPickupPosition({
        ...pickupPosition,
        text: p_r.pickup_destination_time + " Minutes",
      });
      setDestinationPosition(dp);
      if (!pickupPosition) {
        setPosition(dp);
      }
      setZoom(13);
      aftersetDestinationPosition(pickupPosition, dp);
    });
    // if (enableFeaturesList && enableFeaturesList.is_nextbillion_all) {
    //   add = await getAddressFromNextBillion(
    //     enableFeaturesList.nextbillion_api_key,
    //     lat,
    //     lng
    //   );
    //   add = add.places[0].address;
    // } else {
    //   add = await getAddressFromLat(lat, lng);
    // }
    // var latlng = { lat: lat, lng: lng, text: place.name, add: add };
    // console.log(latlng)
    // setDestinationPosition(latlng);
    // if (!pickupPosition) {
    //   setPosition(latlng);
    // }
    // document.querySelector(".btn-search").classList.add("fill");
    // aftersetDestinationPosition(pickupPosition, dp);
  }

  const selectedPickupLoc = data => {
    var pic = document.querySelector("#Location1");
    pic.value = data.address;
    saveRecentPalces(data);
    handlePickupClose();
    near_search_location(data.latitude, data.longitude).then((data) => {
      var userLocation = data.user_location_data;
      // inputRef.current.value = userLocation.title_address;
      var pp = {
        lat: parseFloat(userLocation.latitude),
        lng: parseFloat(userLocation.longitude),
        text: near_search_condition(data),
        /*  data.status == "1"
            ? "Closest ride to you " + userLocation.time_duration + " Minutes"
            : "" */
        add: userLocation.title_address,
      };
      setPickupPosition(pp);
      setPosition({
        lat: parseFloat(userLocation.latitude),
        lng: parseFloat(userLocation.longitude),
      });
      setZoom(13);
      if (Object.keys(destinationPosition).length) {
        aftersetDestinationPosition(pp, destinationPosition);
      }
    });

    // if (enableFeaturesList && enableFeaturesList.is_nextbillion_all) {
    //   add = await getAddressFromNextBillion(
    //     enableFeaturesList.nextbillion_api_key,
    //     lat,
    //     lng
    //   );
    //   add = add.places[0].address;
    // } else {
    //   add = await getAddressFromLat(lat, lng);
    // }
    // var latlng = { lat: lat, lng: lng, text: place.name, add: add };
    // console.log(latlng)
    // setPickupPosition(latlng);
    // setPosition({ ...position, lat, lng, text: place.name, add: add });
    
  }

  const selectDestinationFromMap = () => {
    setDestinationPosition({...pickupPosition,text:""});
    handleDestinationClose();
  }

  const handleSuggestedPickup = e => {
    selectedPickupLoc(JSON.parse(e.target.value));
  }
  
  const handleSuggestedDestination = e => {
    selectedDestination(JSON.parse(e.target.value));
  }

  const saveRecentPalces = (data) => {
    var rp = localStorage.getItem("recent-places");
    if (rp) {
      rp = JSON.parse(rp)
      rp = rp.filter(pl=>pl.address.split(",")[0] != data.address.split(",")[0])
      setRecentPlaces([data,...rp ]);
      localStorage.setItem("recent-places", JSON.stringify([...rp,data]));
    } else {
      setRecentPlaces([data]);
      localStorage.setItem("recent-places", JSON.stringify([data]));
    }
  }

  const handleClearRecentPlaces = () => {
    localStorage.removeItem("recent-places");
    setRecentPlaces()
  }

  const DirectionsService = new google.maps.DirectionsService();

  function formatDate(date) {
    const formattedDate = `${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${date
      .getDate()
      .toString()
      .padStart(2, "0")}/${date.getFullYear()} ${date.toLocaleTimeString(
      "en-US",
      { hour: "numeric", minute: "numeric", hour12: true }
    )}`;
    return formattedDate;
  }

  const requestRideHandler = async () => {
    /* localStorage.setItem("pickupPosition", JSON.stringify(pickupPosition));
    localStorage.setItem(
      "destinationPosition",
      JSON.stringify(destinationPosition)
    ); */
    // console.log("isDriverAvailable", getDriverDetail.isDriverAvailable);
    if (parseInt(getDriverDetail.isDriverAvailable) > 0) {
      var data = await driver_request(0, couponCode);
      // console.log(data);
      if (data.status == "1") {
        localStorage.setItem("request_id", data.request_id);
        navigate("/ride-detail/" + data.request_id);
      } else {
        toast(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } else {
      toast("There are no driver available in this area. Please try again later or use pickup later.", {
        position: "top-center",
        autoClose: 1000,
      });
    }

    // setShowRideDetail(true);
  };

  const fetchFavoriteLocations = async () => {
    try {
      let form = new FormData();
      form.append("customer_id", userDetail.user_profile_id);
      // form.append("user_type", userDetail.user_type);
      var res = await getFavPlaceApi(form);
      var data = res.data;
      if (data.status == "1") {
        setFavPlaces(data);
      } else {
        console.log("fetchFavoriteLocations Dashboard", data);
      }

    } catch (error) {
      console.log("fetchFavoriteLocations Dashboard", error);
    }
  }

  const driver_request = async (
    ride_type = 0,
    promo = "",
    pickup_destination_time = 0,
    time_duration,
    date_time = ""
  ) => {
    try {
      var timeZone = moment.tz.guess();
      let form = new FormData();
      form.append("Service_id", "0");
      form.append("discount_price", "0.000");
      form.append("is_new_design_app", "1");
      form.append("user_current_latitude", pickupPosition.lat);
      form.append("user_current_longitude", pickupPosition.lng);
      form.append("pickup_address", pickupPosition.add);
      form.append("pickup_latitude", pickupPosition.lat);
      form.append("pickup_longitude", pickupPosition.lng);
      form.append("near_location", pickupPosition.add);
      form.append("promo_code", promo);
      form.append("Payment_Type", "2");
      form.append("car_type", "17");
      form.append("user_id", userDetail.user_profile_id);
      form.append("user_type", userDetail.user_type);
      form.append("place_id", "");
      form.append("destination_latitude", destinationPosition.lat);
      form.append("destination_longitude", destinationPosition.lng);
      form.append("destination_address", destinationPosition.add);
      form.append("area", "Kuwait");
      form.append("rental_total_car", "1");
      form.append("pnr_number", "");
      form.append("airline_name", "");
      form.append("offer_price", "0");
      form.append("is_rental_car", "0");
      form.append("pickup_date_time", date_time);
      form.append("ride_base_fare", priceCalculation.ride_fare);
      form.append("estimated_price", priceCalculation.estimatedprice); // price calculation
      form.append(
        "estimatedprice_second",
        // priceCalculation.estimatedprice_second     //   undefined
        priceCalculation.estimatedprice
      );
      form.append("is_suv_car_assign", "0");
      form.append("timezone", timeZone);
      form.append("no_luggage_bags", "0");
      form.append("who_is_ride_id", "0");
      form.append("airport_ride_pickup_destination", "0");
      form.append("multiple_destination", "");
      form.append("no_person", "0");
      form.append("flight_time_of_arrival", "0");
      form.append("rental_car_brand_type", "0");
      form.append("flight_name", "");
      form.append("base_fare_discount", "0.000");
      form.append("discount_price_second", "0.000");
      form.append("wallet", userProfileData.user_profile.wallet);
      form.append("rental_car_hour", "0");
      form.append("flight_number", "");
      form.append("is_airport_ride", "0");
      form.append("pickup_later", ride_type || 0);
      var pickup_later_total_car = 0;
      if (ride_type == 1) {
        pickup_later_total_car = 1;
        form.append("pickup_destination_time", pickup_destination_time);
        form.append("app_eta_mins", time_duration);
      }
      form.append("pickup_later_total_car", pickup_later_total_car);
      form.append("car_selection_id", "0");
      form.append("is_user_multiple_ride", "0");
      form.append("multiple_destination_address", "");

      var res = await driverRequestApi(form);
      console.log("driver_request", res.data);
      var data = res.data;
      return data;
    } catch (error) {
      console.log("driver_request", error);
    }
  };

  const selectHours = (h) => {
    for (const key of Hours) {
      if (key == parseInt(h.hours_value)) {
        initial[key] = true;
      } else {
        initial[key] = false;
      }
    }

    var update_price = longRideCars.map((car, index) => {
      car.price = h.multi_car_data[index];
      car.price.hp = parseFloat(car.price.hours_price) * noOfCarForRide;
      car.price.hp = car.price.hp.toFixed(3);
      car.price.adp =
        parseFloat(car.price.advance_payment_amount) * noOfCarForRide;
      car.price = { ...car.price, hours: h.hours_value };
      return car;
    });

    if (selectedCar) {
      var car = h.multi_car_data.find(
        (item) => item.multi_car_type_id == selectedCar.id
      );

      setSelectedCar({
        ...selectedCar,
        price: {
          ...selectedCar.price,
          hp: (parseFloat(car.hours_price) * noOfCarForRide).toFixed(3),
          adp: (
            parseFloat(car.advance_payment_amount) * noOfCarForRide
          ).toFixed(3),
        },
      });
    }

    setNoOfHours(initial);
    setLongRideCars(update_price);
  };

  const selectLongrideCars = (car) => {
    setSelectedCar(car);
    setLongRidePriceEst(car.price.hp);
  };

  const bookLonhRide = async () => {
    if (
      Object.keys(pickupPosition).length &&
      Object.keys(destinationPosition).length &&
      rideType[1] == true
    ) {
      const dateObj = new Date(startDate);

      const istMoment = moment.tz(dateObj, "Asia/Kolkata");

      // Convert IST time to UTC
      const utcMoment = istMoment.clone().utc();

      // Format the time in Kuwait time zone
      const formattedKuwaitTime = utcMoment.format("YYYY-MM-DD HH:mm:ss");

      var price_res = await price_calculation(
        pickupPosition.lat,
        pickupPosition.lng,
        destinationPosition.lat,
        destinationPosition.lng,
        couponCode,
        1
      );
      var near_serch_res = await near_search_location(
        pickupPosition.lat,
        pickupPosition.lng
      );
      var time_duration = near_serch_res.user_location_data.time_duration;
      var data = await driver_request(
        1,
        couponCode,
        price_res.pickup_destination_time,
        time_duration,
        formattedKuwaitTime
      );
      if (data && data.status == "0") {
        toast.error(data.message, {
          position: "top-center",
          autoClose: 2000,
        });
      } else {
        setLongRide(false);
        navigate({
          pathname: "/my-trips",
          search: `?${createSearchParams({ upcoming: "1" })}`,
        });
      }
    } else if (Object.keys(pickupPosition).length && rideType[2] == true) {
      if (!selectedCar) {
        toast.error("Please select car type.", {
          position: "top-center",
          autoClose: 1000,
        });
        return false;
      }
      console.log(selectedCar); // hours is in this price.hours;
      // console.log(noOfCarForRide);  // no of CARS

      var isAdvance = selectedCar.price.is_advance_payment == "1"; // make Boolean
      if (isAdvance) {
        setAdvancePaymentModal(true);
      }
    } else {
      toast.error("Please select pickup - destination location.", {
        position: "top-center",
        autoClose: 1000,
      });
      return false;
    }

    // console.log(data);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (userBlock) {
      setUserBlockModal(true);
    } else {
      if (addEmailShow || addNameShow) {
        setAddEmailModal(true);
      } else if (addWalletShow) {
        setAddWalletModal(true);
      } else {
        if (pickupPosition && destinationPosition) {
          try {
            near_search_location();
            price_calculation();
            setRequestRide(true);
          } catch (error) {
            console.log("search ride", error);
          }
        }
      }
    }
    return false;
  };

  const get_profile_data = async (e) => {
    try {
      var data1 = {
        user_type: userDetail.user_type, // 0 android, 1 ios
        user_id: userDetail.user_profile_id,
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await getProfileDataApi(form);
      var data = res.data;
      if (data.status == "1") {
        if( data.user_profile.tap_customer_id == '' ) {
          if( data.user_profile.user_email != '' && data.user_profile.name_eng != '' ) {

            const myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer sk_test_LNRtwzjdyFM1UnEmuipT4rJv");
            myHeaders.append("accept", "application/json");
            myHeaders.append("content-type", "application/json");

            const raw = JSON.stringify({
              "first_name": "test",
              "middle_name": "test",
              "last_name": "test",
              "email": "test@test.com",
              "phone": {
                "country_code": "965",
                "number": "51234567"
              },
              "description": "test",
              "metadata": {
                "sample string 1": "string1",
                "sample string 3": "string2"
              },
              "currency": "KWD"
            });

            const requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow"
            };

            fetch("https://api.tap.company/v2/customers", requestOptions)
              .then((response) => response.text())
              .then((result) => console.log(result))
              .catch((error) => console.error(error));
            return false;
            
            const options = {
              method: 'POST',
              headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                Authorization: 'Bearer '+tap_secret
              },
              body: JSON.stringify({
                first_name: 'test',
                middle_name: 'test',
                last_name: 'test',
                email: 'test@test.com',
                phone: {country_code: '965', number: '51234567'},
                description: 'test',
                metadata: {'sample string 1': 'string1', 'sample string 3': 'string2'},
                currency: 'KWD'
              })
            };
            
            fetch('https://api.tap.company/v2/customers', options)
              .then(response => response.json())
              .then(async response => {
                var customer_id = response.id
                var customer_form = new FormData();
                customer_form.append("tap_customer_id", customer_id);
                customer_form.append("user_id", data1.user_id);
                customer_form.append("user_type", data1.user_type);

                var customer_res = await createCustomerIdApi(customer_form);
                var customer_data = customer_res.data;
                if (customer_data.status == "1") {
                }
              })
              .catch(err => console.error(err));
          }
        }
        setUserProfileData(data);
        var email = data.user_profile.user_email;
        var name = data.user_profile.name_eng;
        var wallet = data.user_profile.wallet;
        var card_detail = data.user_card_info;
        var skip_user = data.is_skip_upfront_card_wallet;

        if (card_detail) {
          var exp = new Date(card_detail.expire_date.slice(0, 7) + "-01");
          var M = exp.getMonth() + 1;
          var Y = exp.getFullYear();
          card_detail.expire_date = (M < 10 ? "0" : "") + M + "/" + Y;
        }

        setCardDetail(card_detail);

        wallet = parseFloat(wallet);
        var is_block = data.user_profile.is_red_flag;
        var minAmount = parseFloat(enableFeaturesList.defualt_wallet);

        var diff = minAmount - wallet;
        setWalletDiff(diff.toFixed(3));

        if (is_block == "1") {
          setUserBlockModal(true);
          setUserBlock(true);
        } else {
          setUserBlockModal(false);
          setUserBlock(false);
          if (!email) {
            setAddEmailShow(true);
            setAddEmailModal(true);
          } else {
            setAddEmailShow(false);
            setAddEmailModal(false);
            if (!name) {
              setAddNameShow(true);
              setAddEmailModal(true);
            } else {
              setAddEmailModal(false);
              setAddNameShow(false);
              if (!card_detail || skip_user == "1") {
                if (wallet < minAmount) {
                  setAddWalletModal(true);
                  setAddWalletShow(true);
                } else {
                  setAddWalletShow(false);
                  setAddWalletModal(false);
                }
              }
            }
          }
        }
      } else {
        console.log("Error : get profile data", data);
        return data;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const get_enable_features_list = async (e) => {
    try {
      var data1 = {
        user_type: userDetail.user_type, // 0 android, 1 ios
        user_id: userDetail.user_profile_id,
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await enableFeaturesApi(form);
      var data = res.data;
      if (data.status == "1") {
        setEnableFeaturesList(data.enable_features);
        findBlockUserMessage(data.enable_features);
      } else {
        console.log("Error : get_enable_features_list", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const near_search_location = async (lat, lng) => {
    try {
      var data1 = {
        isFromDestination: 0,
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        // latitude: pickupPosition ? pickupPosition.lat : position.lat,
        // longitude:pickupPosition ?  pickupPosition.lng : position.lng,
        latitude: lat ? lat : pickupPosition.lat,
        longitude: lng ? lng : pickupPosition.lng,
        title_adress: "",
        car_selection_id: 0,
      };
      let form = new FormData();
      form.append("isFromDestination", data1.isFromDestination);
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("latitude", data1.latitude);
      form.append("title_adress", data1.title_adress);
      form.append("longitude", data1.longitude);
      form.append("car_selection_id", data1.car_selection_id);

      var res = await nearSearchLocationApi(form);
      var data = res.data;
      if (data.status == "1") {
        setNearSearchLocation(data);
        return data;
        // pickupPosition.text =
        //   data?.user_location_data?.time_duration + " minutes";
      } else {
        setNearSearchLocation(data);
        console.log("near_search_location", data);
        return data;
      }
    } catch (error) {
      console.log("Error near_search_location", error);
    }
  };

  const get_driver = async (lat, lng) => {
    try {
      var data1 = {
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        pickup_latitude: lat ? lat : pickupPosition.lat,
        pickup_longitude: lng ? lng : pickupPosition.lng,
      };
      let form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("pickup_latitude", data1.pickup_latitude);
      form.append("pickup_longitude", data1.pickup_longitude);

      var res = await getDriverApi(form);
      var data = res.data;
      if (data.status == "1") {
        setGetDriverDetail(data);
      } else {
        if (data.message == "Invalid Auth token") {
          localStorage.clear();
          navigate("/login");
        }
        setGetDriverDetail({ ...data, isDriverAvailable: 0 });
        console.log("get_driver", data);
        /*  toast("No driver found", {
          position: "top-center",
          autoClose: 2000,
        }); */
      }
    } catch (error) {
      console.log(error);
    }
  };

  const get_fare_break_down = async () => {
    try {
      var data1 = {
        pickup_latitude: pickupPosition.lat,
        pickup_longitude: pickupPosition.lng,
        destination_latitude: destinationPosition.lat || 0.0,
        destination_longitude: destinationPosition.lng || 0.0,
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        no_of_car: noOfCarForRide || 1,
        pickup_later: rideType[1] ? 1 : 0,
        is_airport_ride: rideType[3] ? 1 : 0,
        is_rental_car: rideType[2] ? 1 : 0,
        rental_hours_id: rideType[2] ? selectedCar.price.hours :  0,
        car_selection_id:rideType[2] ? selectedCar.price.multi_car_type_id :  0 ,
        promo_code: couponCode,   
        car_type: 17,    // get car type
        ride_fare: nearSearchLocation.pickup_base_price ?? 0,
        surchages_value:nearSearchLocation.is_surcharge=="1"? 1 : 0,
      };
      console.log(selectedCar)

      let form = new FormData();
      form.append("no_of_car", data1.no_of_car);
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("pickup_latitude", data1.pickup_latitude);
      form.append("is_airport_ride", data1.is_airport_ride);
      form.append("pickup_longitude", data1.pickup_longitude);
      form.append("car_selection_id", data1.car_selection_id);
      form.append("destination_latitude", data1.destination_latitude);
      form.append("destination_longitude", data1.destination_longitude);
      form.append("promo_code", data1.promo_code);
      form.append("car_type", data1.car_type);
      form.append("pickup_later", data1.pickup_later);
      form.append("surchages_value", data1.surchages_value);
      form.append("is_rental_car", data1.is_rental_car);
      form.append("ride_fare", data1.ride_fare);
      form.append("rental_hours_id", data1.rental_hours_id);

      var res = await fareBreakDownApi(form);
      var data = res.data;
      if (data.status == "1") {
        setCarFareBreakdown(data.car_fare_breakdown);
      } else {
        console.log("get_fare_break_down", data);
      }
    } catch (error) {
      console.log("get_fare_break_down" + error);
    }
  };

  const price_calculation = async (
    p_lat,
    p_lng,
    d_lat,
    d_lng,
    c_p,
    pickup_type = 0
  ) => {
    try {
      var data1 = {
        is_new_app: 1,
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        pickup_latitude: p_lat ?? pickupPosition.lat,
        pickup_longitude: p_lng ?? pickupPosition.lng,
        car_selection_id: 0,
        is_get_eta: 0,
        waiting_charge: 0,
        destination_latitude: d_lat ?? destinationPosition.lat,
        destination_longitude: d_lng ?? destinationPosition.lng,
        promo_code: c_p ?? "",
        car_type: 17,
        offer_price: 0,
        pickup_later: pickup_type,
      };

      let form = new FormData();
      form.append("is_new_app", data1.is_new_app);
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("pickup_latitude", data1.pickup_latitude);
      form.append("pickup_longitude", data1.pickup_longitude);
      form.append("car_selection_id", data1.car_selection_id);
      form.append("is_get_eta", data1.is_get_eta);
      form.append("waiting_charge", data1.waiting_charge);
      form.append("destination_latitude", data1.destination_latitude);
      form.append("destination_longitude", data1.destination_longitude);
      form.append("promo_code", data1.promo_code);
      form.append("car_type", data1.car_type);
      form.append("offer_price", data1.offer_price);
      form.append("pickup_later", data1.pickup_later);

      var res = await priceCalculateApi(form);
      var data = res.data;
      if (data.status == "1") {
        setPriceCalculation(data);
        var esti_pr =
          parseFloat(data.estimatedprice) - parseFloat(data.discount_price);
        esti_pr = esti_pr.toFixed(3);
        setEstimatedprice(esti_pr);
        return data;
      } else {
        console.log("price_calculation", data);
        setPriceCalculation();
        setEstimatedprice("Loading...");
      }
    } catch (error) {
      console.log("price_calculation", error);
    }
  };
  const pickupAddressChange = async (e) => {
    if (e.target.value == "") {
      // setPickupBlank(true)
      setCombinePlaces()
      setPickupPosition();
      setRouteDirections();
      setPath();
      setZoom(13);
      if (currantPosition) {
        setPosition({
          lat: currantPosition.lat,
          lng: currantPosition.lng,
          text: "",
        });
      }
    } else { 
      // inputRef.current.addEventListener('input',()=> handleInputChange(inputRef.current.value));
    }
    setTimeout(() => {
      // console.log('dest htipo', inputRef2.current.value)
      if (inputRef.current.value != '') {
        inputRef.current.addEventListener('input',()=> handleInputChange(inputRef.current.value));
      }
      else {
        setCombinePlaces();
      }
    },700);
  };

  const destinationAddressChange = (e) => {
    if (e.target.value == "") {
      // setCombinePlaces()
      setDestinationPosition({});
      setRouteDirections();
      setPath();
      if (currantPosition) {
        setPosition({
          lat: currantPosition.lat,
          lng: currantPosition.lng,
          text: "",
        });
      }
      setZoom(13);
      setRequestRide(false);
    } else {
      // inputRef2.current.addEventListener('input',()=> handleInputChange(inputRef2.current.value));
    }

    setTimeout(() => {
      // console.log('dest htipo', inputRef2.current.value)
      if (inputRef2.current.value != '') {
        inputRef2.current.addEventListener('input',()=> handleInputChange(inputRef2.current.value));
      }
      else {
        setCombinePlaces();
      }
    },700);
  };

   


  const homeScreenNotification = async () => {
    try {
      var data1 = {
        user_type: userDetail.user_type ? userDetail.user_type : "", // 0 android, 1 ios
        user_id: userDetail.user_profile_id ? userDetail.user_profile_id : "",
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);

      var res = await homeScreenNotificationApi(form);
      var data = res.data;
      if (data.status == "1") {
        setHomeScreenData(data);
      } else {
        console.log("Error : get profile data", data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePickupDrag = (event) => {
    var lat = event.latLng.lat();
    var lng = event.latLng.lng();

    near_search_location(lat, lng).then((data) => {
      var userLocation = data.user_location_data;

      var pic = document.querySelector("#Location1");
      pic.value =  userLocation.title_address;
      // inputRef.current.value = userLocation.title_address;
      var p = {
        lat: parseFloat(userLocation.latitude),
        lng: parseFloat(userLocation.longitude),
        // text: userLocation.address.suburb,
        text: near_search_condition(data),
        /* data.status == "1"
            ? "Closest ride to you " + userLocation.time_duration + " Min"
            : "" */ add: userLocation.title_address,
      };
      setPickupPosition(p);
      get_driver();
      if (Object.keys(destinationPosition).length && p) {
        price_calculation(
          pickupPosition.lat,
          pickupPosition.lng,
          lat,
          lng
        ).then((p_r) => {
          var esti_pr =
            parseFloat(p_r.estimatedprice) - parseFloat(p_r.discount_price);
          esti_pr = esti_pr.toFixed(3);
          setDestinationPosition({
            ...destinationPosition,
            text: "KD " + esti_pr,
          });
        });
        aftersetDestinationPosition(p, destinationPosition);
      }
    });

    if (pickupPosition && Object.keys(destinationPosition).length) {
      // near_search_location();
      // price_calculation();
    }
    try {
      // const geocoder = new window.google.maps.Geocoder();
      // const latlng = {
      //   lat: parseFloat(lat),
      //   lng: parseFloat(lng),
      // };
      /*  geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            console.log(results[0]);
            var add = results[0].formatted_address;
            inputRef.current.value = add;
            var p = { lat: lat, lng: lng, text: add, add: add };
            setPickupPosition(p);
            if (destinationPosition && p) {
              aftersetDestinationPosition(p, destinationPosition);
            }
          } else {
            console.log("No results found");
          }
        } else {
          console.log("Geocoder failed due to: " + status);
        }
      }); */
    } catch (error) {
      console.log(error);
    }
  };

  const handleDestinationDrag = (event) => {
    var lat = event.latLng.lat();
    var lng = event.latLng.lng();

    near_search_location(lat, lng).then((data) => {
      var userLocation = data.user_location_data;

      var d = {
        address: userLocation.title_address,
        latitude: parseFloat(userLocation.latitude),
        longitude:parseFloat(userLocation.longitude)
      }
      selectedDestination(d)
     /*  var des = document.querySelector("#Location2");
      des.value =  userLocation.title_address; */
      // inputRef.current.value = userLocation.title_address;
      // var p = {
      //   lat: parseFloat(userLocation.latitude),
      //   lng: parseFloat(userLocation.longitude),
      //   // text: userLocation.address.suburb,
      //   text: near_search_condition(data),
      //   /* data.status == "1"
      //       ? "Closest ride to you " + userLocation.time_duration + " Min"
      //       : "" */ add: userLocation.title_address,
      // };
      // setDestinationPosition(p);
      // get_driver();
      // if (Object.keys(destinationPosition).length && p) {
      //   price_calculation(
      //     pickupPosition.lat,
      //     pickupPosition.lng,
      //     lat,
      //     lng
      //   ).then((p_r) => {
      //     var esti_pr =
      //       parseFloat(p_r.estimatedprice) - parseFloat(p_r.discount_price);
      //     esti_pr = esti_pr.toFixed(3);
      //     setDestinationPosition({
      //       ...destinationPosition,
      //       text: "KD " + esti_pr,
      //     });
      //   });
      //   aftersetDestinationPosition(p, destinationPosition);
      // }
    });

    // if (pickupPosition && Object.keys(destinationPosition).length) {
    //   // near_search_location();
    //   // price_calculation();
    // }
    try {
      // const geocoder = new window.google.maps.Geocoder();
      // const latlng = {
      //   lat: parseFloat(lat),
      //   lng: parseFloat(lng),
      // };
      /*  geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            console.log(results[0]);
            var add = results[0].formatted_address;
            inputRef.current.value = add;
            var p = { lat: lat, lng: lng, text: add, add: add };
            setPickupPosition(p);
            if (destinationPosition && p) {
              aftersetDestinationPosition(p, destinationPosition);
            }
          } else {
            console.log("No results found");
          }
        } else {
          console.log("Geocoder failed due to: " + status);
        }
      }); */
    } catch (error) {
      console.log(error);
    }
  };

  const handleFarePriceClick = () => {
    get_fare_break_down();
    setCarDetailShow(true);
  };

  const handleForMeClose = () => setForMeShow(false);
  const handleForMeShow = () => setForMeShow(true);
  const handleGuestClose = () => {
    setGuestShow(false);
    setForMeShow(true);
  };
  const handleGuestShow = () => {
    setGuestShow(true);
    setForMeShow(false);
  };
  // const handleForMeShow = () => setForMeShow(true);
  // const exampleModalClick = () => {
  //   $('#exampleModal').modal('show')
  // }

  const aftersetDestinationPosition = async (pickup, destination) => {
    if (Object.keys(pickup).length && Object.keys(destination).length) {
      if (enableFeaturesList && enableFeaturesList.is_nextbillion_all) {
        console.log("nextbillion");
        var data = await getDirectionFromNextBillion(
          enableFeaturesList.nextbillion_api_key,
          pickup,
          destination
        );
        const route = data.routes[0].legs[0];
        const path = route.steps.map((step) => ({
          lat: step.start_location.latitude,
          lng: step.start_location.longitude,
        }));
        setPath(path);
      } else {
        console.log("Google directions");
        DirectionsService.route(
          {
            origin: new google.maps.LatLng(pickup),
            destination: new google.maps.LatLng(destination),
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            console.log(status);
            if (status === google.maps.DirectionsStatus.OK) {
              console.log("ok", result);
              setRouteDirections(result);
              // setRequestRide(true);
            } else {
              console.log("err", result);
            }
            // console.log(routeDirections);
          }
        );
      }
    }
  };

  const riderSectionHover = (e) => {
    e.preventDefault();
    var element = e.target;
    if (document.querySelector(".rider_section.active") != null) {
      document
        .querySelector(".rider_section.active")
        .classList.remove("active");
    }
    element.classList.add("active");
    return false;
  };

  const pickupLater = (e) => {
    document.getElementById("main").style.display = "none";
    document.getElementById("flip").style.display = "block";
  };

  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        if (result.state === "granted") {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              var lat = position.coords.latitude;
              var long = position.coords.longitude;
              near_search_location(lat, long).then((data) => {
                var userLocation = data.user_location_data;
                var pic = document.querySelector("#Location1");
                pic.value =  userLocation.title_address;
                  // inputRef.current.value = userLocation.title_address;

                  setPickupPosition({
                    lat: parseFloat(userLocation.latitude),
                    lng: parseFloat(userLocation.longitude),
                    // text: userLocation.address.suburb,
                    text: near_search_condition(data),
                    /* "Closest ride to you " +
                      userLocation.time_duration +
                      " Min" */ add: userLocation.title_address,
                  });
                  setPosition({
                    lat: parseFloat(userLocation.latitude),
                    lng: parseFloat(userLocation.longitude),
                    // text: userLocation.address.suburb,
                    // add: userLocation.display_name,
                  });
                  setCurrantPosition({
                    lat: parseFloat(userLocation.latitude),
                    lng: parseFloat(userLocation.longitude),
                    text: userLocation.title_address,
                    add: userLocation.title_address,
                  });
                  setZoom(13);
                });
                // var url = "http://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lang + "&sensor=true&key=AIzaSyD7ajC4AJpRr3_Yz8e48FsIxHVqvP1RNkQ";
                // var url =
                //   "https://geocode.maps.co/reverse?lat=" +
                //   lat +
                //   "&lon=" +
                //   long +
                //   // "&api_key=AIzaSyDskRkPZZjm3j9Trw55LGhJEe4AOA1I_fg";
                //   "&api_key=6617797c87997345174567fer0b83a3";
                // fetch(url)
                //   .then((result) => {
                //     if (result.ok) {
                //       return result.json();
                //     } else {
                //       // alert('something went wrong while fetching location....')
                //       var res = {};
                //       res.display_name = "";
                //       return res;
                //     }
                //   })
                //   .then((result) => {
                //     if (result !== undefined) {
                //       // console.log(result)
                //       inputRef.current.value = result.display_name;

                //       setPickupPosition({
                //         lat: parseFloat(result.lat),
                //         lng: parseFloat(result.lon),
                //         // text: result.address.suburb,
                //         text: result.display_name,
                //         add: result.display_name,
                //       });
                //       setPosition({
                //         lat: parseFloat(result.lat),
                //         lng: parseFloat(result.lon),
                //         text: result.address.suburb,
                //         add: result.display_name,
                //       });
                //       setCurrantPosition({
                //         lat: parseFloat(result.lat),
                //         lng: parseFloat(result.lon),
                //         text: result.address.suburb,
                //         add: result.display_name,
                //       })
                //       // setFormData({
                //       //   ...formData,
                //       //   pick_up: result.display_name,
                //       // });
                //     }
                //   })
                //   .catch((err) => console.log(err));
              },
              (error) => {
                console.error("Error getting geolocation:", error);
              },
              { enableHighAccuracy: true, timeout: 1000, maximumAge: 1000 }
            );
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                var lat = position.coords.latitude;
                var long = position.coords.longitude;
                near_search_location(lat, long).then((data) => {
                  var userLocation = data.user_location_data;

                  var pic = document.querySelector("#Location1");
                  pic.value =  userLocation.title_address;
                  // inputRef.current.value = userLocation.title_address;

                  setPickupPosition({
                    lat: parseFloat(userLocation.latitude),
                    lng: parseFloat(userLocation.longitude),
                    // text: userLocation.address.suburb,
                    text: near_search_condition(data),
                    /* "Closest ride to you " +
                      userLocation.time_duration +
                      " Min" */ add: userLocation.title_address,
                  });
                  setPosition({
                    lat: parseFloat(userLocation.latitude),
                    lng: parseFloat(userLocation.longitude),
                    // text: userLocation.address.suburb,
                    // add: userLocation.display_name,
                  });
                  setCurrantPosition({
                    lat: parseFloat(userLocation.latitude),
                    lng: parseFloat(userLocation.longitude),
                    text: userLocation.title_address,
                    add: userLocation.title_address,
                  });
                 
                });
                // var url = "http://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lang + "&sensor=true&key=AIzaSyD7ajC4AJpRr3_Yz8e48FsIxHVqvP1RNkQ";
                // var url =
                //   "https://geocode.maps.co/reverse?lat=" +
                //   lat +
                //   "&lon=" +
                //   long +
                //   "&api_key=6617797c87997345174567fer0b83a3";
                // "&api_key=AIzaSyDskRkPZZjm3j9Trw55LGhJEe4AOA1I_fg";
                // fetch(url)
                //   .then((result) => {
                //     if (result.ok) {
                //       console.log(result);
                //       return result.json();
                //     } else {
                //       // alert('something went wrong while fetching location....')
                //       var res = {};
                //       res.display_name = "";
                //       return res;
                //     }
                //   })
                //   .then((result) => {
                //     if (result !== undefined) {
                //       setPickupPosition({
                //         lat: parseFloat(result.lat),
                //         lng: parseFloat(result.lon),
                //         // text: result.address.suburb,
                //         text: result.display_name,
                //         add: result.display_name,
                //       });
                //       setPosition({
                //         lat: parseFloat(result.lat),
                //         lng: parseFloat(result.lon),
                //         text: result.address.suburb,
                //         add: result.display_name,
                //       });
                //       setCurrantPosition({
                //         lat: parseFloat(result.lat),
                //         lng: parseFloat(result.lon),
                //         text: result.address.suburb,
                //         add: result.display_name,
                //       });
                //       // setFormData({
                //       //   ...formData,
                //       //   pick_up: result.display_name,
                //       // });
                //     }
                //   })
                //   .catch((err) => console.log(err));
              },
              (error) => {
                console.error("Error getting geolocation:", error);
              },
              { enableHighAccuracy: true, timeout: 1000, maximumAge: 1000 }
            );
            alert('Location permisson issue "prompt"....');
          } else if (result.state === "denied") {
            alert('Location permisson  "denied"....');
          }
          /* result.onchange = function () {
            console.log(result.state);
          }; */
        });
    } else {
      alert("Sorry location not available!");
    }
  };

  const pickupLaterClose = (e) => {
    document.getElementById("flip").style.display = "none";
    document.getElementById("main").style.display = "block";
  };

  const near_search_condition = (data) => {
    if (data.status == "1") {
      if (data.isDisableArea == "1") {
        return "Rove doesn't operate here";
      } else if (data.is_user_waiting_ride_available == "1") {
        return "Waiting";
      } else if (data.is_vip_waiting_ride == "1") {
        return "Waiting";
      } else {
        return (
          "Closest ride to you " +
          data.user_location_data.time_duration +
          " Min"
        );
      }
    } else if (data.status == "0") {
      if (data.isDisableArea == "1") {
        return "Rove doesn't operate here";
      } else if (data.is_user_waiting_ride_available == "1") {
        return "Waiting";
      } else if (data.is_vip_waiting_ride == "1") {
        return "Waiting";
      } else if (
        !(parseInt(data.driver_login_count) > 0) &&
        data.near_by_car_count == "0"
      ) {
        // console.log("hello",!(parseInt(data.driver_login_count) > 0))
        return "Rove doesn't operate here";
      } else if (
        !(parseInt(data.driver_login_count) > 0) &&
        data.free_driver_count == "0"
      ) {
        return "All drivers are busy";
      } else {
        return "Driver not available";
      }
    }
  };
  

  const handleInputChange = (inputval) => {
    const autocompleteService = new google.maps.places.AutocompleteService();
    const options = {
      input: inputval,
      fields: options1.fields,
      types: options1.types,
      componentRestrictions: options1.componentRestrictions,
    };
    /* autocompleteService.getPlacePredictions(options, (predictions) => {
      if (predictions) {
        const detailedPredictions = predictions.map((prediction, index) => {
          console.log(prediction.geometry);
          return {
          ...prediction,
          latitude: prediction.geometry.location.lat(),
          longitude: prediction.geometry.location.lng(),
          address: prediction.description,
          title: prediction.description,
          name: "",
          favorite_id: index + ""
        }
        });

        setCombinePlaces([...detailedPredictions, ...favPlaces]);
      }
    }); */
    console.log('dest value ', inputval)
    console.log('dest result ',inputval == '')
    if (inputval == '') {
      console.log('dest hii empty value')
      setCombinePlaces();
    }
    else {
      autocompleteService.getPlacePredictions(options, (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const placesService = new google.maps.places.PlacesService(document.createElement('div'));
          
          // Get place details for each prediction to get the geometry (latitude and longitude)
          const detailedPredictions = predictions.map(prediction => {
            return new Promise((resolve, reject) => {
              placesService.getDetails({ placeId: prediction.place_id, fields: options1.fields }, (placeDetails, placeStatus) => {
                if (placeStatus === google.maps.places.PlacesServiceStatus.OK) {
                  const detailedPlace = {
                    ...placeDetails,
                    description: prediction.description
                  };
                  resolve(detailedPlace);
                } else {
                  reject(placeStatus);
                }
              });
            });
             });
          Promise.all(detailedPredictions)
            .then(places => {
              if (places) {
                const detailedPredictions = places.map((place,index) => {
                  return {
                    ...place,
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng(),
                    address: place.description,
                    title: place.description,
                    name: "",
                    favorite_id: index.toString()
                  }
                })
                console.log('dest prediction', detailedPredictions);
                setCombinePlaces(detailedPredictions);
             }
            })
            .catch(error => {
              console.error('dest Error fetching place details:', error);
            });
        }
      });
    }
  };


  setTimeout(function () {
    /* autoCompleteRef.current = new google.maps.places.Autocomplete(
      inputRef.current,
      options1
    ); */

    /*autoCompleteRef.current.setComponentRestrictions({
      country: ["us", "pr", "vi", "gu", "mp"],
    });*/
   

    /* autoCompleteRef2.current = new google.maps.places.Autocomplete(
      inputRef2.current,
      options1
    ); */

    // autoCompleteRef.current.addListener("place_changed", async function (e) {
      
    //   var place = this.getPlace();
    //   var geometry = place.geometry;
    //   var lat = geometry.location.lat();
    //   var lng = geometry.location.lng();
    //   // console.log("pickuplat", lat)
    //   // console.log("pickuplan",lng)
    //   var add;
    //   // var add = place.name + ","
    //   // place.address_components.forEach(element => {
    //   //   add = add + element.long_name + ", "
    //   // });
    //   near_search_location(lat, lng).then((data) => {
    //     var userLocation = data.user_location_data;
    //     // inputRef.current.value = userLocation.title_address;
    //     var pp = {
    //       lat: parseFloat(userLocation.latitude),
    //       lng: parseFloat(userLocation.longitude),
    //       text: near_search_condition(data),
    //       /*  data.status == "1"
    //           ? "Closest ride to you " + userLocation.time_duration + " Minutes"
    //           : "" */
    //       add: userLocation.title_address,
    //     };
    //     setPickupPosition(pp);
    //     setPosition({
    //       lat: parseFloat(userLocation.latitude),
    //       lng: parseFloat(userLocation.longitude),
    //     });
    //     setZoom(13);
    //     if (Object.keys(destinationPosition).length) {
    //       aftersetDestinationPosition(pp, destinationPosition);
    //     }
    //   });

    //   // if (enableFeaturesList && enableFeaturesList.is_nextbillion_all) {
    //   //   add = await getAddressFromNextBillion(
    //   //     enableFeaturesList.nextbillion_api_key,
    //   //     lat,
    //   //     lng
    //   //   );
    //   //   add = add.places[0].address;
    //   // } else {
    //   //   add = await getAddressFromLat(lat, lng);
    //   // }
    //   // var latlng = { lat: lat, lng: lng, text: place.name, add: add };
    //   // console.log(latlng)
    //   // setPickupPosition(latlng);
    //   // setPosition({ ...position, lat, lng, text: place.name, add: add });
    // });

    // autoCompleteRef2.current.addListener("place_changed", async function () {
    //   var place = this.getPlace();
    //   var geometry = place.geometry;
    //   var lat = geometry.location.lat();
    //   var lng = geometry.location.lng();
    //   // console.log("enableFeaturesList", enableFeaturesList);
    //   // console.log(lat)
    //   // console.log(lng)
    //   var add;
    //   var p_r = await price_calculation(
    //     pickupPosition.lat,
    //     pickupPosition.lng,
    //     lat,
    //     lng
    //   );
    //   var esti_pr =
    //     parseFloat(p_r.estimatedprice) - parseFloat(p_r.discount_price);
    //   esti_pr = esti_pr.toFixed(3);
    //   near_search_location(lat, lng).then((data) => {
    //     var userLocation = data.user_location_data;
    //     // inputRef.current.value = userLocation.title_address;
    //     var dp = {
    //       lat: parseFloat(userLocation.latitude),
    //       lng: parseFloat(userLocation.longitude),
    //       text: "KD " + esti_pr,
    //       add: userLocation.title_address,
    //     };
    //     setPickupPosition({
    //       ...pickupPosition,
    //       text: p_r.pickup_destination_time + " Minutes",
    //     });
    //     setDestinationPosition(dp);
    //     if (!pickupPosition) {
    //       setPosition(dp);
    //     }
    //     setZoom(13);
    //     aftersetDestinationPosition(pickupPosition, dp);
    //   });
    //   // if (enableFeaturesList && enableFeaturesList.is_nextbillion_all) {
    //   //   add = await getAddressFromNextBillion(
    //   //     enableFeaturesList.nextbillion_api_key,
    //   //     lat,
    //   //     lng
    //   //   );
    //   //   add = add.places[0].address;
    //   // } else {
    //   //   add = await getAddressFromLat(lat, lng);
    //   // }
    //   // var latlng = { lat: lat, lng: lng, text: place.name, add: add };
    //   // console.log(latlng)
    //   // setDestinationPosition(latlng);
    //   // if (!pickupPosition) {
    //   //   setPosition(latlng);
    //   // }
    //   // document.querySelector(".btn-search").classList.add("fill");
    //   // aftersetDestinationPosition(pickupPosition, dp);
    // });
  }, 100);

  const onSelectChange = async (v) => {
    var val = v;
    // inputRef.current.value = null
    // inputRef2.current.value = null
    // setPickupPosition();
    // setDestinationPosition({});

    if (val == "0") {
      setShowPickupLater(false);
      setNoOfCars(false);
      setAirportRide(false);
      setLongRide(false);
    }
    if (val == "1") {
      // later
      setShowPickupLater(true);
      setNoOfCars(false);
      setAirportRide(false);
      setLongRide(true);
      setRequestRide(false);
      // pickupLater();
    }
    if (val == "2") {
      //long
      await get_rental_car();
      setLongRide(true);
      setNoOfCars(true);
      setRouteDirections();
      setPath();
      setShowPickupLater(true);
      // setPickupPosition();
      // inputRef.current.value = null;
      var des = document.querySelector("#Location2");
      des.value =  null;
      // inputRef2.current.value = null;
      setDestinationPosition({});
      setAirportRide(false);
      setRequestRide(false);
      // pickupLater();
    }
    if (val == "3") {
      //airport
      setLongRide(true);
      setShowPickupLater(true);
      setAirportRide(true);
      setNoOfCars(false);
      setRequestRide(false);
      // pickupLater();
    }
  };

  const get_rental_car = async () => {
    try {
      var form = new FormData();
      form.append("user_type", userDetail.user_type);
      form.append("user_id", userDetail.user_profile_id);

      var res = await getLongRentalCarApi(form);
      var data = res.data;
      if (data.status == "1") {
        setLongRidePopup(data);
        var rental_car = data.rental_car; // []
        var rental_car_hours = data.rental_car_hours;
        setLongRideHours(rental_car_hours); // []

        rental_car = rental_car.map((car, index) => {
          car.price = rental_car_hours[0].multi_car_data[index];
          car.price.adp = car.price.advance_payment_amount;
          car.price.hp = car.price.hours_price;
          car.price = { ...car.price, hours: rental_car_hours[0].hours_value };
          return car;
        });

        setLongRideCars(rental_car);
        var hours = rental_car_hours.map((h) => h.hours_value);
        var ho = {};
        rental_car_hours.map((h, index) => {
          if (index == 0) {
            ho[parseInt(h.hours_value)] = true;
          } else {
            ho[parseInt(h.hours_value)] = false;
          }
        });
        setNoOfHours(ho);
        setHours(hours);
        return data;
      } else {
        console.log("dashbord get_rental_car", data);
      }
    } catch (error) {
      console.log("dashbord get_rental_car", error);
    }
  };

  const getAddressFromLat = async (lat, lng) => {
    try {
      const geocoder = new window.google.maps.Geocoder();
      const latlng = {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      };

      const results = await new Promise((resolve, reject) => {
        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              resolve(results[0].formatted_address);
            } else {
              console.log("No results found");
              resolve(null); // Resolve with null if no results found
            }
          } else {
            console.log("Geocoder failed due to: " + status);
            reject(status); // Reject the promise with the status
          }
        });
      });

      return results;
    } catch (error) {
      console.log("getAddressFromLat", error);
      return null; // Return null in case of an error
    }
  };

  const validate_coupon = async () => {
    try {
      if (!couponCode.trim().length) {
        toast.error("Please enter coupon code", {
          position: "top-center",
          autoClose: 1000,
        });
        return false;
      }

      var data1 = {
        user_type: userDetail.user_type,
        user_id: userDetail.user_profile_id,
        coupon_code: couponCode,
        pickup_latitude: pickupPosition.lat,
        pickup_longitude: pickupPosition.lng,
        destination_latitude: destinationPosition.lat,
        destination_longitude: destinationPosition.lng,
        car_type: 17,
        pickup_later: 0, // make condition start
        timezone: "",
        no_of_ride: 1, //condition over
        pickup_date_time: "",
      };

      var form = new FormData();
      form.append("user_type", data1.user_type);
      form.append("user_id", data1.user_id);
      form.append("coupon_code", data1.coupon_code);
      form.append("pickup_latitude", data1.pickup_latitude);
      form.append("pickup_longitude", data1.pickup_longitude);
      form.append("destination_latitude", data1.destination_latitude);
      form.append("destination_longitude", data1.destination_longitude);
      form.append("car_type", data1.car_type);
      form.append("pickup_later", data1.pickup_later);
      form.append("timezone", data1.timezone);
      form.append("no_of_ride", data1.no_of_ride);
      form.append("pickup_date_time", data1.pickup_date_time);

      var res = await validateCouponApi(form);
      var data = res.data;
      if (data.status == "1") {
        toast.success(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
        var remove_coupon = document.querySelector(".bi.bi-x-square");
        remove_coupon.classList.remove("d-none");
        await price_calculation(
          pickupPosition.lat,
          pickupPosition.lng,
          destinationPosition.lat,
          destinationPosition.lng,
          couponCode
        );
      } else {
        toast.error(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
        setCouponCode("");
      }
    } catch (error) {
      console.log("validate_coupon", error);
    }
  };

  const cancelCouponCodeHandle = async () => {
    try {
      var remove_coupon = document.querySelector(".bi.bi-x-square");
      remove_coupon.classList.add("d-none");
      setCouponCode("");
      await price_calculation(
        pickupPosition.lat,
        pickupPosition.lng,
        destinationPosition.lat,
        destinationPosition.lng,
        ""
      );
    } catch (error) {
      console.log("cancelCouponCodeHandle", error);
    }
  };

  const getAddressFromNextBillion = async (key, lat, lan) => {
    try {
      var api = "https://api.nextbillion.io/postalcode?key=" + key;
      var b = {
        at: { lat: lat, lng: lan },
      };
      var res = await fetch(api, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(b),
      });
      var data = await res.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const getDirectionFromNextBillion = async (key, pickup, destination) => {
    try {
      var origin = pickup.lat + "," + pickup.lng;
      var des = destination.lat + "," + destination.lng;
      var api =
        "https://api.nextbillion.io/directions/json?key=" +
        key +
        "&origin=" +
        origin +
        "&destination=" +
        des +
        "&mode=car&steps=true&overview=simplified";

      var res = await fetch(api, {
        method: "GET",
      });
      var data = await res.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleMinusClick = () => {
    if (Object.keys(enableFeaturesList).length) {
      var min_car = 1;
      if (noOfCarForRide > min_car) {
        var update = longRideCars.map((car) => {
          var new_price =
            parseFloat(car.price.hours_price) * (noOfCarForRide - 1);
          new_price = new_price.toFixed(3);

          var new_ad_price =
            parseFloat(car.price.advance_payment_amount) * (noOfCarForRide - 1);
          new_ad_price = new_ad_price.toFixed(3);

          car.price = {
            ...car.price,
            hp: new_price,
            adp: new_ad_price,
          };
          return car;
        });
        if (selectedCar) {
          setSelectedCar({
            ...selectedCar,
            price: {
              ...selectedCar.price,
              hp: (
                parseFloat(selectedCar.price.hours_price) *
                (noOfCarForRide - 1)
              ).toFixed(3),
              adp: (
                parseFloat(selectedCar.price.advance_payment_amount) *
                (noOfCarForRide - 1)
              ).toFixed(3),
            },
          });
        }
        setLongRideCars(update);
        setNoOfCarForRide((prev) => prev - 1);
      }
    }
  };

  const handlePlusClick = () => {
    if (Object.keys(enableFeaturesList).length) {
      var max_car;
      if (rideType[2]) {
        max_car = parseInt(enableFeaturesList.max_rental_car_selection);
      } else if (rideType[1]) {
        max_car = parseInt(
          enableFeaturesList.max_car_pickup_later_car_selection
        );
      } else if (rideType[3]) {
        max_car = parseInt(
          enableFeaturesList.max_car_airport_ride_car_selection
        );
      } else {
        max_car = 3;
      }
      if (noOfCarForRide < max_car) {
        var update = longRideCars.map((car) => {
          var new_price =
            parseFloat(car.price.hours_price) * (noOfCarForRide + 1);
          new_price = new_price.toFixed(3);

          var new_ad_price =
            parseFloat(car.price.advance_payment_amount) * (noOfCarForRide + 1);
          new_ad_price = new_ad_price.toFixed(3);

          car.price = {
            ...car.price,
            hp: new_price,
            adp: new_ad_price,
          };
          return car;
        });

        if (selectedCar) {
          setSelectedCar({
            ...selectedCar,
            price: {
              ...selectedCar.price,
              hp: (
                parseFloat(selectedCar.price.hours_price) *
                (noOfCarForRide + 1)
              ).toFixed(3),
              adp: (
                parseFloat(selectedCar.price.advance_payment_amount) *
                (noOfCarForRide + 1)
              ).toFixed(3),
            },
          });
        }

        setLongRideCars(update);
        setNoOfCarForRide((prev) => prev + 1);
      }
    }
  };

  const openLowWalletPopupForLongride = () => {
    if (userProfileData && userProfileData.user_profile.wallet) {
      var wallet = userProfileData.user_profile.wallet;
      var card_detail = userProfileData.user_card_info;
      var skip_user = userProfileData.is_skip_upfront_card_wallet;

      var price_for_advance_pay = selectedCar.price.advance_payment_amount;
      price_for_advance_pay = parseFloat(price_for_advance_pay).toFixed(3);

      var total = parseFloat(noOfCarForRide) * price_for_advance_pay;
      total = total.toFixed(3);
      wallet = parseFloat(wallet).toFixed(3);
      if (wallet - total < 0) {
        setWalletDiff((total - wallet).toFixed(3));
        setAdvancePaymentModal(false);
        setPaymentModal(true);
      }
    }
  };

  const advancePayAccept = () => {
    openLowWalletPopupForLongride();
  };

  const cancleRideInfo = () => {
    setRequestRide(false);
    setPickupPosition();
    setDestinationPosition({});
    setRouteDirections();
    setPath();
    var pic = document.querySelector("#Location1");
    pic.value = "";
    var des = document.querySelector("#Location2");
    des.value =  "";
    // inputRef.current.value = "";
    // inputRef2.current.value = ""; 
    fetchLocation();
  }

  // console.log(routeDirections,"RouteDirection")
  // console.log(destinationPosition)
  return (
    <main>
      <ToastContainer />
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <section className="section mt-40 mb-100">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 col-lg-3">
              <div className="sidebar sidebar-dashboard ">
                <form
                  className="box-search-ride box-search-ride-style-3 wow fadeInUp"
                  // onSubmit={handleFormSubmit}
                  method="post"
                >
                  <div id="main" className="w-100">
                    <h4 className="mb-3"> Request a Ride</h4>
                    <div className="box-form-search">
                      <div className="mb-10">
                        <div className="pickup-later pickup-button">
                          <div className="row ride-type">
                            <div className="col-md-6 col-lg-6 mb-10 w-50">
                              <div
                                className={`d-flex align-items-center justify-content-center ${
                                  rideType[2] ? "select" : ""
                                }`}
                                onClick={() => {
                                  if (userBlock) {
                                    setUserBlockModal(true);
                                  } else {
                                    if (addEmailShow || addNameShow) {
                                      setAddEmailModal(true);
                                    } else if (addWalletShow) {
                                      setAddWalletModal(true);
                                    } else {
                                      onSelectChange(2);
                                      setRideType({
                                        0: false,
                                        1: false,
                                        2: true,
                                        3: false,
                                      });
                                    }
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Long Ride
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-6 mb-10 w-50">
                              <div
                                className={`d-flex align-items-center justify-content-center ${
                                  rideType[3] ? "select" : ""
                                }`}
                                onClick={() => {
                                  if (userBlock) {
                                    setUserBlockModal(true);
                                  } else {
                                    if (addEmailShow || addNameShow) {
                                      setAddEmailModal(true);
                                    } else if (addWalletShow) {
                                      setAddWalletModal(true);
                                    } else {
                                      onSelectChange(3);
                                      setRideType({
                                        0: false,
                                        1: false,
                                        2: false,
                                        3: true,
                                      });
                                    }
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Airport Ride
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-6 mb-10 w-50">
                              {" "}
                              <div
                                className={`d-flex align-items-center justify-content-center ${
                                  rideType[0] ? "select" : ""
                                }`}
                                onClick={() => {
                                  if (userBlock) {
                                    setUserBlockModal(true);
                                  } else {
                                    if (addEmailShow || addNameShow) {
                                      setAddEmailModal(true);
                                    } else if (addWalletShow) {
                                      setAddWalletModal(true);
                                    } else {
                                      onSelectChange(0);
                                      setRideType({
                                        0: true,
                                        1: false,
                                        2: false,
                                        3: false,
                                      });
                                    }
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Pickup Now
                              </div>{" "}
                            </div>
                            <div className="col-md-6 col-lg-6 mb-10 w-50">
                              <div
                                className={`d-flex align-items-center justify-content-center ${
                                  rideType[1] ? "select" : ""
                                }`}
                                onClick={() => {
                                  if (userBlock) {
                                    setUserBlockModal(true);
                                  } else {
                                    if (addEmailShow || addNameShow) {
                                      setAddEmailModal(true);
                                    } else if (addWalletShow) {
                                      setAddWalletModal(true);
                                    } else {
                                      onSelectChange(1);
                                      setRideType({
                                        0: false,
                                        1: true,
                                        2: false,
                                        3: false,
                                      });
                                    }
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Pickup Later
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="search-item search-from "
                        id="location-pickup"
                      >
                        <label>
                          <input
                            type="text"
                            readOnly={true}
                            className="form-control"
                            id="Location1"
                            placeholder="Pickup location"
                            // ref={inputRef}
                            // value={pickupPosition.text}
                            // name="pickup"
                            // onChange={pickupAddressChange}
                            // autoComplete="off"
                            onClick={()=>setSearchPickup(true)}
                          />
                        </label>
                      </div>
                      <div
                        className={`search-item search-from ${
                          noOfCars ? "d-none" : ""
                        }`}
                        id="location-destination"
                      >
                        <label>
                          <input
                            type="text"
                            readOnly={true}
                            className="form-control"
                            id="Location2"
                            placeholder="Dropoff location"
                            // ref={inputRef2}
                            // name="destination"
                            onClick={()=>setSearchDestination(true)}
                          />
                        </label>
                      </div>
                      {showPickupLater && (
                        <>
                          <h6 className="mt-20 mb-20">
                            When do you want to be picked up?
                          </h6>
                          <div className="form-group">
                            <DatePicker
                              showIcon
                              icon="fa fa-calendar"
                              toggleCalendarOnIconClick
                              // isClearable
                              selected={startDate}
                              minDate={startDate}
                              maxDate={maxDate}
                              withPortal
                              className="form-control"
                              showTimeSelect
                              timeFormat="p"
                              timeIntervals={10}
                              // timeInputLabel="Time:"
                              dateFormat="MM/dd/yyyy h:mm aa"
                              // showTimeInput
                              placeholderText="Enter date & time"
                              onChange={(date) => setStartDate(date)}
                            />
                          {/* <input
                              type="datetime-local"
                              id="date-picker"
                              name="datetime"
                              className="form-control"
                              onChange={(date) => setStartDate(date)}
                              selected={startDate}
                            /> */}
                          </div>
                        </>
                      )}

                      {(noOfCars || rideType[1]) && (
                        <div className="mb-20">
                          <div className="pickup-later pickup-button">
                            <h6>Number of cars: </h6>
                            <div className="row noofcars justify-content-evenly">
                              <div className="col-md-3 col-lg-3 w-25">
                                {" "}
                                <div
                                  className={`d-flex align-items-center justify-content-center`}
                                  style={{ padding: "0", cursor: "pointer" }}
                                  onClick={handleMinusClick}
                                >
                                  <i
                                    className="bi bi-dash"
                                    style={{ fontSize: "26px" }}
                                  ></i>
                                </div>{" "}
                              </div>
                              <div className="col-md-3 col-lg-3 w-25">
                                <div
                                  className={`d-flex align-items-center justify-content-center select`}
                                >
                                  {noOfCarForRide}
                                </div>
                              </div>
                              <div className="col-md-3 col-lg-3 w-25">
                                <div
                                  className={`d-flex align-items-center justify-content-center`}
                                  style={{ padding: "0", cursor: "pointer" }}
                                  onClick={handlePlusClick}
                                >
                                  <i
                                    className="bi bi-plus"
                                    style={{ fontSize: "0px" }}
                                  ></i>
                                </div>
                              </div>
                              {/* <div className="col-md-3 col-lg-3 w-25">
                                  <div
                                    className={`d-flex align-items-center justify-content-center ${
                                      noOfCarForRide[4] ? "select" : ""
                                    }`}
                                    onClick={() =>
                                      setNoOfCarForRide({
                                        1: false,
                                        2: false,
                                        3: false,
                                        4: true,
                                      })
                                    }
                                  >
                                    4
                                  </div>
                                </div> */}
                            </div>
                            {/* <select name="noofcars" id="noofcars">
                                <option value="">Number of cars</option>
                                <option value="0">1</option>
                                <option value="1">2</option>
                                <option value="2">3</option>
                              </select> */}
                          </div>
                        </div>
                      )}
                      {noOfCars && (
                        <>
                          <div className="mb-20">
                            <div className="pickup-later pickup-button">
                              {/* <select name="hours" id="hours">
                                <option value="">Number of hours</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                              </select> */}
                              <h6>Number of hours: </h6>
                              <div className="hours d-flex  mb-10 pb-3">
                                {longRidePopup &&
                                  longRidePopup.rental_car_hours.map((h) => (
                                    <div
                                      className="col-md-3 col-lg-3 px-2"
                                      key={h.id}
                                    >
                                      {" "}
                                      <div
                                        style={{
                                          minWidth: "50px",
                                          cursor: "pointer",
                                        }}
                                        className={`d-flex align-items-center justify-content-center ${
                                          noOfHours[parseInt(h.hours_value)]
                                            ? "select"
                                            : ""
                                        }`}
                                        onClick={() => selectHours(h)}
                                      >
                                        {h.hours_name}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {airportRide && (
                        <>
                          <div className="mb-20">
                            <div className="pickup-later pickup-button">
                              {/* <select name="noofperson" id="noofperson">
                                <option value="">Number of person</option>
                                <option value="0">1</option>
                                <option value="1">2</option>
                                <option value="2">3</option>
                              </select> */}
                              <h6>Number of person: </h6>
                              <div className="row noofperson">
                                <div className="col-md-3 col-lg-3 w-25">
                                  {" "}
                                  <div
                                    className={`d-flex align-items-center justify-content-center ${
                                      noOfPerson[1] ? "select" : ""
                                    }`}
                                    onClick={() =>
                                      setNoOfPerson({
                                        1: true,
                                        2: false,
                                        3: false,
                                      })
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    1
                                  </div>{" "}
                                </div>
                                <div className="col-md-3 col-lg-3 w-25">
                                  <div
                                    className={`d-flex align-items-center justify-content-center ${
                                      noOfPerson[2] ? "select" : ""
                                    }`}
                                    onClick={() =>
                                      setNoOfPerson({
                                        1: false,
                                        2: true,
                                        3: false,
                                      })
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    2
                                  </div>
                                </div>
                                <div className="col-md-3 col-lg-3 w-25">
                                  <div
                                    className={`d-flex align-items-center justify-content-center ${
                                      noOfPerson[3] ? "select" : ""
                                    }`}
                                    onClick={() =>
                                      setNoOfPerson({
                                        1: false,
                                        2: false,
                                        3: true,
                                      })
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    3
                                  </div>
                                </div>
                                {/* <div className="col-md-3 col-lg-3 w-25">
                                  <div className="d-flex align-items-center justify-content-center">
                                    4
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                          <div className="mb-20">
                            <div className="pickup-later pickup-button">
                              {/* <select name="luggueage" id="luggueage">
                                <option value="">Number of bags</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                              </select> */}
                              <h6>Number of bags: </h6>
                              <div className="row luggueage">
                                <div className="col-md-3 col-lg-3 w-25">
                                  {" "}
                                  <div
                                    className={`d-flex align-items-center justify-content-center ${
                                      noOfBags[0] ? "select" : ""
                                    }`}
                                    onClick={() =>
                                      setNoOfBags({
                                        0: true,
                                        1: false,
                                        2: false,
                                        3: false,
                                      })
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    0
                                  </div>{" "}
                                </div>
                                <div className="col-md-3 col-lg-3 w-25">
                                  <div
                                    className={`d-flex align-items-center justify-content-center ${
                                      noOfBags[1] ? "select" : ""
                                    }`}
                                    onClick={() =>
                                      setNoOfBags({
                                        0: false,
                                        1: true,
                                        2: false,
                                        3: false,
                                      })
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    1
                                  </div>
                                </div>
                                <div className="col-md-3 col-lg-3 w-25">
                                  <div
                                    className={`d-flex align-items-center justify-content-center ${
                                      noOfBags[2] ? "select" : ""
                                    }`}
                                    onClick={() =>
                                      setNoOfBags({
                                        0: false,
                                        1: false,
                                        2: true,
                                        3: false,
                                      })
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    2
                                  </div>
                                </div>
                                <div className="col-md-3 col-lg-3 w-25">
                                  <div
                                    className={`d-flex align-items-center justify-content-center ${
                                      noOfBags[3] ? "select" : ""
                                    }`}
                                    onClick={() =>
                                      setNoOfBags({
                                        0: false,
                                        1: false,
                                        2: false,
                                        3: true,
                                      })
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    3
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {showPickupLater && (
                        <>
                          <div>
                            <div className="d-flex">
                              <div className="pr-20">
                                <i className="fa fa-hourglass"></i>
                              </div>
                              <div>
                                <p>
                                  Extra wait time included to meet your ride
                                </p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="d-flex">
                              <div className="pr-20">
                                <i className="fa fa-credit-card"></i>
                              </div>
                              <div>
                                <p>
                                  Cancel at no charge up to 60 minutes in
                                  advance
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mb-30">
                            <button
                              type="button"
                              className="btn text-muted text-underline"
                              data-bs-toggle="modal"
                              data-bs-target="#termsModal"
                            >
                              See Terms
                            </button>
                          </div>
                        </>
                      )}

                      <div
                        className="search-item search-button mb-0 "
                        style={{ maxWidth: "100%" }}
                      >
                        {!showPickupLater && (
                          <button
                            className="btn btn-search"
                            disabled={searchDisable}
                            type="button"
                            onClick={(e) => handleFormSubmit(e)}
                          >
                            {" "}
                            Search
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {longRide ? (
              <div className="col-md-4 col-lg-4 mb-3">
                <div id="choose_ride_section">
                  <h3 className="fw-bold mb-10 text-center">Ride Detail</h3>
                  <div className="row d-flex justify-content-between mb-3">
                    <div className="col-md-6 d-flex align-items-center w-50">
                      <img
                        src="/assets/imgs/icons/wallet@2x.png"
                        alt="wallet@2x"
                        width={25}
                      />
                      <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                        Wallet
                      </p>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-end w-50">
                      <p className="fw-bold fs-6 mb-0">
                        KD{" "}
                        {userProfileData && userProfileData.user_profile.wallet}
                      </p>
                    </div>
                  </div>
                  {cardDetail && (
                    <div className="row d-flex justify-content-between mb-3">
                      <div className="col-md-6 d-flex align-items-center">
                        <img
                          src={
                            cardDetail && cardDetail.card_brand == "VISA"
                              ? "/assets/imgs/icons/visa@2x.png"
                              : "/assets/imgs/icons/ic_mastercard-2.png"
                          }
                          alt="ic_mastercard-2@2x"
                          width={25}
                        />
                        <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                          {cardDetail
                            ? `#### ${cardDetail.card_number}`
                            : "No card added"}
                        </p>
                      </div>
                      <div className="col-md-6 d-flex align-items-center justify-content-end">
                        <p className=" me-2 fw-bold fs-6 mb-0">
                          Expires in : {cardDetail && cardDetail.expire_date}
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="car-selection">
                    {rideType[2] &&
                      longRideCars &&
                      longRideCars.map((car, index) => {
                        var image = car.multi_car_image.replace(
                          "https://rovemain.rovesolution.com/",
                          "https://www.riderove.com/dev_rove/"
                        );
                        return (
                          <div
                            key={car.id}
                            className={`card ${
                              selectedCar &&
                              selectedCar.display_name == car.display_name
                                ? "active"
                                : ""
                            }`}
                            onClick={() => selectLongrideCars(car)}
                          >
                            <div className="card-body ">
                              <div className="row justify-content-center align-items-center">
                                <div className="col-md-3 w-30">
                                  <img src={image} alt="" width="100px" />
                                </div>
                                <div className="col-md-6">
                                  <h5
                                    className="mb-0 fw-bold"
                                    // onClick={handleFarePriceClick}
                                  >
                                    {car.display_name}&nbsp;
                                    <span title="Seating capacity">
                                      
                                    <img src={"/assets/imgs/icons/Selected_SeatingCapicity_cartype.png"}
                                      alt=""
                                      width="15px"
                                    />&nbsp;
                                      {car.seating_capacity}
                                    </span>
                                  </h5>
                                  <p className="mb-0">
                                    {car.car_brands} &nbsp;
                                    <i className="bi bi-info-circle" style={{ cursor: "pointer" }} onClick={handleFarePriceClick}></i>
                                  </p>
                                </div>
                                <div className="col-md-3">
                                  <div className="">
                                    <p className="text-end fw-bold mb-0 fs-6">
                                      KD{" "}
                                      {car.price ? car.price.hp : "loading..."}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                    {rideType[1] && (
                      <div
                        className={`card ${carType.eco ? "active" : ""}`}
                        onClick={() =>
                          setCarType({
                            ...carType,
                            eco: true,
                            lux: false,
                            any: false,
                          })
                        }
                      >
                        <div className="card-body ">
                          <div className="row justify-content-center align-items-center">
                            <div className="col-md-3 w-30">
                              <img
                                src={
                                  enableFeaturesList &&
                                  enableFeaturesList.is_car_selection == 0
                                    ? BASE_IMG_URL +
                                      enableFeaturesList.request_any_img
                                    : "/assets/imgs/ride_icons/car_type_luxury.png"
                                }
                                alt=""
                                width="100px"
                              />
                            </div>
                            <div className="col-md-5">
                              <h5
                                className="mb-0 fw-bold"
                                style={{ cursor: "pointer" }}
                                onClick={handleFarePriceClick}
                              >
                                {enableFeaturesList &&
                                  enableFeaturesList.fastest_car_name}
                                &nbsp;<i className="fa fa-user"></i>&nbsp;
                                {nearSearchLocation &&
                                  nearSearchLocation.free_driver_count}
                              </h5>
                              <p
                                style={{ cursor: "pointer" }}
                                className="mb-0"
                                onClick={handleFarePriceClick}
                              >
                                BMW 735i &nbsp;
                                <i className="bi bi-info-circle"></i>
                              </p>
                            </div>
                            <div className="col-md-4">
                              <div className="">
                                <p className="text-end fw-bold mb-0 fs-6">
                                  {priceCalculation
                                    ? `KD ${estimatedprice}`
                                    : "No estimated price"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row d-flex justify-content-between my-3">
                    <div className="col-md-6 d-flex align-items-center w-50">
                      <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                        Total Estimated Price
                      </p>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-end w-50">
                      <p className="fw-bold fs-6 mb-0">
                        {rideType[1] &&
                          (priceCalculation
                            ? `KD ${parseFloat(
                                estimatedprice * noOfCarForRide
                              ).toFixed(3)}`
                            : "No estimated price")}
                        {rideType[2] &&
                          (selectedCar
                            ? `KD ${selectedCar.price.hp}`
                            : "No estimated price")}
                      </p>
                    </div>
                  </div>

                  <div className="my-3">
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "50px" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: "70%", height: "45px" }}
                        placeholder="Enter coupon code"
                        onChange={(e) => setCouponCode(e.target.value)}
                        value={couponCode}
                      />
                      <button
                        type="button"
                        onClick={validate_coupon}
                        className="btn btn-primary apply-coupon mx-3"
                        style={{ height: "45px" }}
                      >
                        Apply
                      </button>
                      <i
                        className="bi bi-x-square fs-1 d-none"
                        onClick={cancelCouponCodeHandle}
                      ></i>
                    </div>
                  </div>

                  <div className="fix-bottom">
                    <div className="d-flex">
                      <div className="d-flex justify-content-evenly w-100">
                        <button
                          type="button"
                          className="btn btn-outline-danger w-45 "
                          onClick={() => {
                            setLongRide(false);
                            // pickupLaterClose();
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary w-45"
                          onClick={bookLonhRide}
                        >
                          Book
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {requestRide && !showPickupLater ? (
              <div className="col-md-5 col-lg-5 mb-3">
                <div id="choose_ride_section">
                  <h3 className="fw-bold mb-10 text-center">Ride Detail</h3>
                  <div className="row d-flex justify-content-between mb-3">
                    <div className="col-md-6 d-flex align-items-center w-50">
                      <img
                        src="/assets/imgs/icons/wallet@2x.png"
                        alt="wallet@2x"
                        width={25}
                      />
                      <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                        Wallet
                      </p>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-end w-50">
                      <p className="fw-bold fs-6 mb-0">
                        KD{" "}
                        {userProfileData && userProfileData.user_profile.wallet}
                      </p>
                    </div>
                  </div>
                  {cardDetail && (
                    <div className="row d-flex justify-content-between mb-3">
                      <div className="col-md-6 d-flex align-items-center">
                        <img
                          src={
                            cardDetail && cardDetail.card_brand == "VISA"
                              ? "/assets/imgs/icons/visa@2x.png"
                              : "/assets/imgs/icons/ic_mastercard-2.png"
                          }
                          alt="ic_mastercard-2@2x"
                          width={25}
                        />
                        <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                          {cardDetail
                            ? `#### ${cardDetail.card_number}`
                            : "No card added"}
                        </p>
                      </div>
                      <div className="col-md-6 d-flex align-items-center justify-content-end">
                        <p className=" me-2 fw-bold fs-6 mb-0">
                          Expires in : {cardDetail && cardDetail.expire_date}
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="car-selection">
                    <div className={`card `}>
                      <div className="card-body ">
                        <div className="row justify-content-center align-items-center">
                          <div className="col-md-3 w-30">
                            <img
                              src={
                                enableFeaturesList &&
                                enableFeaturesList.is_car_selection == 0
                                  ? BASE_IMG_URL +
                                    enableFeaturesList.request_any_img
                                  : "/assets/imgs/ride_icons/car_type_luxury.png"
                              }
                              alt="car type"
                              width="100px"
                            />
                          </div>
                          <div className="col-md-5">
                            <h5
                              className="mb-0 fw-bold"
                              style={{ cursor: "pointer" }}
                              onClick={handleFarePriceClick}
                            >
                              {enableFeaturesList &&
                                enableFeaturesList.fastest_car_name}
                              &nbsp;<i className="fa fa-user"></i>&nbsp;
                              {nearSearchLocation &&
                                nearSearchLocation.free_driver_count}
                            </h5>
                            {/* <p
                              style={{ cursor: "pointer" }}
                              onClick={handleFarePriceClick}
                              className="mb-0"
                            >
                              BMW 735i &nbsp;{" "}
                              <i className="bi bi-info-circle"></i>
                            </p> */}
                          </div>
                          <div className="col-md-4">
                            <div className="">
                              <p className="text-end fw-bold mb-0 fs-6">
                                KD{" "}
                                {priceCalculation
                                  ? estimatedprice
                                  : "loading..."}
                                {/* {nearSearchLocation &&
                                  nearSearchLocation.pickup_base_price} */}
                              </p>
                              <p className="text-end fw-bold mb-0 fs-6">
                                ETA :
                                <span>
                                  {nearSearchLocation &&
                                    nearSearchLocation.user_location_data
                                      .time_duration + "min"}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      className={`card ${carType.eco ? "active" : ""}`}
                      onClick={() =>
                        setCarType({
                          ...carType,
                          eco: true,
                          lux: false,
                          any: false,
                        })
                      }
                    >
                      <div className="card-body ">
                        <div className="row justify-content-center align-items-center">
                          <div className="col-md-3 w-30">
                            <img
                              src="/assets/imgs/ride_icons/car_type_economy.png"
                              alt=""
                              width="100px"
                            />
                          </div>
                          <div className="col-md-5">
                            <h5 className="mb-0 fw-bold">
                              Economy <i className="fa fa-user"></i>&nbsp;3
                            </h5>
                            <p
                              style={{ cursor: "pointer" }}
                              className="mb-0"
                              onClick={() => setCarDetailShow(true)}
                            >
                              BMW 735i &nbsp;{" "}
                              <i className="bi bi-info-circle"></i>
                            </p>
                          </div>
                          <div className="col-md-4">
                            <div className="">
                              <p className="text-end fw-bold mb-0 fs-6">
                                KD 5.050 - 6.050{" "}
                              </p>
                              <p className="text-end fw-bold mb-0 fs-6">
                                ETA : <span>8 min</span>{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`card ${carType.lux ? "active" : ""}`}
                      onClick={() =>
                        setCarType({
                          ...carType,
                          lux: true,
                          eco: false,
                          any: false,
                        })
                      }
                    >
                      <div className="card-body">
                        <div className="row justify-content-center align-items-center">
                          <div className="col-md-3">
                            <img
                              src="/assets/imgs/ride_icons/car_type_luxury.png"
                              alt=""
                              width="100px"
                            />
                          </div>
                          <div className="col-md-5">
                            <h5 className="mb-0 fw-bold">
                              Luxury <i className="fa fa-user"></i>&nbsp;5
                            </h5>
                            <p
                              style={{ cursor: "pointer" }}
                              className="mb-0"
                              onClick={() => setCarDetailShow(true)}
                            >
                              Rang Rover sport &nbsp;
                              <i className="bi bi-info-circle"></i>
                            </p>
                          </div>
                          <div className="col-md-4">
                            <div className="">
                              <p className="mb-0 text-end fw-bold mb-0 fs-6">
                                KD 8.005 - 9.005
                              </p>
                              <p className="mb-0 text-end fw-bold mb-0 fs-6">
                                ETA : <span>6 min</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`card ${carType.any ? "active" : ""} mb-4`}
                      onClick={() =>
                        setCarType({
                          ...carType,
                          any: true,
                          lux: false,
                          eco: false,
                        })
                      }
                    >
                      <div className="card-body">
                        <div className="row justify-content-center align-items-center">
                          <div className="col-md-3">
                            <img
                              src="/assets/imgs/ride_icons/carType-any.png"
                              alt="carType-any.png"
                              width="100px"
                            />
                          </div>
                          <div className="col-md-5">
                            <h5 className="mb-0 fw-bold">
                              Request Any <i className="fa fa-user"></i>
                              &nbsp;8
                            </h5>
                            <p
                              style={{ cursor: "pointer" }}
                              className="mb-0"
                              onClick={() => setCarDetailShow(true)}
                            >
                              Toyota, BMW &nbsp;
                              <i className="bi bi-info-circle"></i>
                            </p>
                          </div>
                          <div className="col-md-4">
                            <div className="">
                              <p className="mb-0 text-end fw-bold mb-0 fs-6">
                                KD 7.050 - 8.050
                              </p>
                              <p className="mb-0 text-end fw-bold mb-0 fs-6">
                                ETA : <span>8 min</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="mb-3">
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "50px" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        style={{ width: "70%", height: "45px" }}
                        placeholder="Enter coupon code"
                        onChange={(e) => setCouponCode(e.target.value)}
                        value={couponCode}
                      />
                      <button
                        className="btn btn-primary apply-coupon mx-3"
                        style={{ height: "45px" }}
                        type="button"
                        onClick={validate_coupon}
                      >
                        Apply
                      </button>
                      <i
                        className="bi bi-x-square fs-1 d-none"
                        onClick={cancelCouponCodeHandle}
                      ></i>
                    </div>
                  </div>

                  <div className="fix-bottom">
                    <div className="d-flex">
                      <div className="d-flex justify-content-evenly w-100">
                        <button
                          type="button"
                          className="btn btn-outline-danger w-45 "
                          onClick={cancleRideInfo}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary w-45"
                          onClick={requestRideHandler}
                        >
                          Request Ride
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div
              className={
                requestRide
                  ? `col-md-4 col-lg-4`
                  : longRide
                  ? `col-md-5 col-lg-5`
                  : `col-md-9 col-lg-9`
              }
            >
              <div id="map" className="">
                {/* <MapComponent /> */}

                {/* <marquee className="marquee_dashboard"> */}
                <h6 className="marquee_text " id="homeScreenNotificationbtn">
                  Tap to pay your unpaid ride (0.500 KD).
                </h6>
                {/* </marquee> */}
                {position && (
                  <MapWithAMarker
                    containerElement={<div style={{ height: `80vh` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    zoom={zoom}
                    pickupPosition={pickupPosition}
                    position={position}
                    destinationPosition={destinationPosition}
                    handlePickupDrag={handlePickupDrag}
                    handleDestinationDrag={handleDestinationDrag}
                    routeDirections={routeDirections}
                    driverPositions={
                      getDriverDetail ? getDriverDetail.drivers : null
                    }
                    path={path}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <Modal show={carDetailShow} onHide={() => setCarDetailShow(false)} centered >
          <Modal.Header closeButton>
            <Modal.Title>Fare Breakdown</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center my-3">
              <img
                src={
                  enableFeaturesList && enableFeaturesList.is_car_selection == 0
                    ? BASE_IMG_URL + enableFeaturesList.request_any_img
                    : "/assets/imgs/ride_icons/car_type_luxury.png"
                }
                alt="car type"
                width="100px"
              />
              <h6 className="mt-3 mb-0 fw-bold">
                {enableFeaturesList && enableFeaturesList.fastest_car_name}
                &nbsp;<i className="fa fa-user"></i>&nbsp;
                {nearSearchLocation && nearSearchLocation.free_driver_count}
              </h6>
              {/* <p className="mb-0">Rang Rover sport &nbsp;</p> */}
            </div>

            <div className="my-2">
              {carFareBreakdown && carFareBreakdown ? (
                carFareBreakdown.map((item, index) => (
                  <div className="row" key={index}>
                    <div className="col-md-9">
                      <h6 className="fw-bold">{item.title}</h6>
                    </div>
                    <div className="col-md-3">
                      <p className="text-end mb-0">KD {item.price}</p>
                    </div>
                  </div>
                ))
              ) : (
                <h4>Price detail is not available</h4>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn btn-primary w-100"
              variant="primary"
              onClick={() => setCarDetailShow(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={paymentModal} onHide={paymentModalHandler} centered>
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold">Top up your wallet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="row d-flex justify-content-between mb-3 p-3 "
              style={{
                background: "rgb(233 192 196 / 49%)",
                borderRadius: "5px",
              }}
            >
              <div className="col-md-12  ">
                {/* <img
                  mailto:src="/assets/imgs/icons/wallet@2x.png"
                  alt="wallet@2x"
                  width={25}
                /> */}
                <p
                  className=" ms-2 fw-bold fs-6 mb-0"
                  style={{ color: "#e3666" }}
                >
                  Please top up you wallet by KD {walletDiff} to process with
                  this booking.
                </p>
              </div>
            </div>
            <div className="my-3">
              <h6 className="text-rove my-3 fw-bold">Select Payment Method</h6>

              <div
                className={`row d-flex justify-content-between mb-3 p-2 ${
                  selelectPayMode == 0 ? "select" : ""
                }`}
                onClick={() => setSelectPayMode(0)}
              >
                <div className="col-md-6 d-flex align-items-center">
                  <img
                    src={
                      cardDetail && cardDetail.card_brand == "VISA"
                        ? "/assets/imgs/icons/visa@2x.png"
                        : "/assets/imgs/icons/ic_mastercard-2.png"
                    }
                    alt="ic_mastercard-2@2x"
                    width={30}
                  />
                  <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">
                    {cardDetail
                      ? `#### ${cardDetail.card_number}`
                      : "Add Credit Card"}
                  </p>
                </div>
                <div className="col-md-6 d-flex align-items-center justify-content-end">
                  <p className=" me-2 fw-bold fs-6 mb-0">
                    Expires in : {cardDetail && cardDetail.expire_date}
                  </p>
                </div>
              </div>
              <div
                className={`row d-flex justify-content-between mb-3 p-2 ${
                  selelectPayMode == 1 ? "select" : ""
                }`}
                onClick={() => setSelectPayMode(1)}
              >
                <div className="col-md-12 d-flex align-items-center">
                  <p className="ms-2 fw-bold fs-6 mb-0">KNET</p>
                </div>
              </div>
              {/* <div className={`row d-flex justify-content-between mb-3 p-2 ${selelectPayMode==2?"select":""}`}  onClick={()=>setSelectPayMode(2)}>
                <div className="col-md-12 d-flex align-items-center">
                  <p className="ms-2 fw-bold fs-6 mb-0">
                    Apple Pay
                  </p>
               </div>
            </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button
              className="btn btn-outline-danger"
              onClick={paymentModalHandler}
              type="button"
              style={{ padding: "8px 24px" }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary mx-3"
              type="button"
              onClick={() => {
                navigate("/wallet");
              }}
            >
              Top up KD {walletDiff}
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={forMeShow} onHide={handleForMeClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Switch rider</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="d-flex rider_section active"
              onMouseEnter={riderSectionHover}
            >
              <div className="pr-30">
                <button type="button" className="btn">
                  <i className="fa fa-user"></i>
                </button>
              </div>
              <div className="">
                <p>Me</p>
              </div>
            </div>
            <div
              className="d-flex rider_section"
              onMouseEnter={riderSectionHover}
            >
              <div className="pr-30">
                <button type="button" className="btn">
                  <i className="fa fa-user-plus"></i>
                </button>
              </div>
              <div className="" onClick={handleGuestShow}>
                <p>Order ride for someone else</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn btn-primary w-100"
              variant="primary"
              onClick={handleForMeClose}
            >
              Done
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal size="lg" show={searchPickup} onHide={handlePickupClose} centered>
          <Modal.Header closeButton>
            {/* <Modal.Title>Switch rider</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className="container" style={{minHeight:"600px"}} >
              <div className="row">
                <div className="col-md-10">
                  <div
                    className="search-item search-from "
                    id="location-pickup"
                  >
                    <label>
                      <input
                        type="text"
                        className="form-control"
                        // id="Location1"
                        placeholder="Pickup location"
                        ref={inputRef}
                        // value={pickupPosition.text}
                        name="pickup"
                        autoComplete="off"
                        onChange={pickupAddressChange}
                        // autoComplete="off"
                      />
                    </label>
                  </div>
                  <div className="my-2">
                    <button className="btn btn-locationmap w-100" onClick={handlePickupClose}>
                      Select location from map
                    </button>
                  </div>
                  {console.log('dest place ',combinePlaces)}
                  {combinePlaces ?
                  <div className="my-3">
                    <h6 className="fw-bold mb-4 mt-5">
                        Search Places
                    </h6>
                    {combinePlaces.length && combinePlaces.map((pl,index) =>
                      <div className="my-3 d-flex align-items-center" style={{cursor:"pointer"}} key={index} onClick={()=>selectedPickupLoc(pl)}>
                        <img
                          src="/assets/imgs/icons/search.png"
                          alt="wallet@2x"
                          width={30}
                        />
                        <div className="mx-2">
                          <h6 className="text-rove ms-2 fw-bold fs-6 mb-0">{pl.address.split(",")[0]}</h6> 
                          <p className="ms-2 mb-0">{pl.address}</p> 
                        </div>
                      </div>
                      )
                    }
                  </div>
                    :
                  <div className="my-3">
                   {favPlaces && favPlaces.suggested_places &&
                        <>
                          <h6 className="fw-bold mb-3 mt-5 text-secondary">
                            Suggested Places
                          </h6>
                          {favPlaces.suggested_places.map(sp =>
                          {
                            if (sp.is_sublocation == "1") {   
                            return (<>
                              <div className="d-flex align-items-center">
                              {/* <h6 className="fw-bold my-2 ">
                              {sp.address_title}
                            </h6> */}
                                <img
                                  src="/assets/imgs/icons/Login_Logo.png"
                                  alt="wallet@2x"
                                  width={40}
                                />
                              <select name="suggested-place" id="suggested-place" className="form-select ms-3" onChange={handleSuggestedPickup}>
                                  <option value="0" defaultValue={true}>{sp.address_description }</option>
                                  {
                                    sp.sublocation.map((sl, index) =>
                                    {
                                      sl.address = sl.name;
                                      return <option value={JSON.stringify(sl)} key={index} >{sl.name}
                                      </option>
                                    }
                                    )
                                  }
                              </select>
                            </div>
                            </>)
                            }
                          }
                        )}
                        </>
                      
                    }
                    {favPlaces && favPlaces.requests.length && 
                        <>
                          <h6 className="fw-bold mb-4 mt-5 text-secondary">
                            Saved Places
                          </h6>
                       { favPlaces.requests.map((sp, index) =>
                        <div className="my-3 d-flex align-items-center" style={{cursor:"pointer"}} key={index} onClick={()=>selectedPickupLoc(sp)}>
                        <img
                          src={sp.address_type == "Home" ?  "/assets/imgs/icons/ic_home.png": (sp.address_type == "Work" ? "/assets/imgs/icons/ic_work.png" :"/assets/imgs/icons/star.png")}
                          alt="search@2x"
                          width={30}
                        />
                        <div className="mx-2">
                          <h6 className="text-rove ms-2 fw-bold fs-6 mb-0">{sp.name}</h6> 
                          <p className="ms-2 mb-0">{sp.address}</p> 
                        </div>
                          
                      </div>
                        )}
                        </>
                      }
                      {recentPlaces &&
                        <>
                        <div className="d-flex justify-content-between">
                          <h6 className="fw-bold mb-4 mt-5 text-secondary">
                            Recent Places
                          </h6>
                          <h6 className="fw-bold mb-4 mt-5" style={{cursor:"pointer"}} onClick={handleClearRecentPlaces}>
                            Clear All
                          </h6>
                        </div>
                    <div>
                    {recentPlaces.length && recentPlaces.map((pl, index) =>
                      <div
                        className="my-3 d-flex align-items-center"
                        key={index}
                        style={{cursor:"pointer"}}
                        onClick={() => selectedPickupLoc(pl)}>
                        <img
                          src="/assets/imgs/icons/icn_recent.png"
                          alt="search@2x"
                          width={30}
                        />
                        <div className="mx-2">
                          <h6 className="text-rove ms-2 fw-bold fs-6 mb-0">{pl.address.split(",")[0]}</h6> 
                          <p className="ms-2 mb-0">{pl.address}</p> 
                        </div>
                      </div>
                      )
                    }
                    </div>
                    </> }

                  </div>}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>

        <Modal size="lg" show={searchDestination} onHide={handleDestinationClose} centered>
          <Modal.Header closeButton>
            {/* <Modal.Title>Switch rider</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className="container" style={{minHeight:"600px"}}>
              <div className="row">
                <div className="col-md-10">
                      <div
                        className={`search-item search-from ${
                          noOfCars ? "d-none" : ""
                        }`}
                        id="location-destination"
                      >
                        <label>
                          <input
                            type="text"
                            className="form-control"
                            // id="Location2"
                            placeholder="Dropoff location"
                            ref={inputRef2}
                            name="destination"
                            autoComplete="off"
                            onChange={destinationAddressChange}
                          />
                        </label>
                      </div>
                  <div className="my-2">
                    <button className="btn btn-locationmap w-100" onClick={selectDestinationFromMap}>
                      Select location from map
                    </button>
                  </div>
                  {combinePlaces ?
                  <div className="my-3">
                    <h6 className="fw-bold mb-4 mt-5">
                        Search Places
                    </h6>

                    {combinePlaces.length && combinePlaces.map(pl =>
                      <div className="my-3 d-flex align-items-center" style={{cursor:"pointer"}} key={pl.favorite_id} onClick={()=>selectedDestination(pl)}>
                        <img
                          src="/assets/imgs/icons/search.png"
                          alt="wallet@2x"
                          width={30}
                        />
                        <div className="mx-2">
                          <h6 className="text-rove ms-2 fw-bold fs-6 mb-0">{pl.address.split(",")[0]}</h6> 
                          <p className="ms-2 mb-0">{pl.address}</p> 
                        </div>
                          
                      </div>
                      )
                    }
                  </div>
                    :
                  <div className="my-3">
                      {favPlaces && favPlaces.suggested_places &&
                        <>
                        
                          <h6 className="fw-bold mb-3 mt-5 text-secondary">
                            Suggested Places
                          </h6>
                          {favPlaces.suggested_places.map(sp =>
                          {
                            if (sp.is_sublocation == "1") {   
                            return (<>
                              <div className="d-flex align-items-center">
                              {/* <h6 className="fw-bold my-2 ">
                              {sp.address_title}
                            </h6> */}
                                <img
                                  src="/assets/imgs/icons/Login_Logo.png"
                                  alt="wallet@2x"
                                  width={40}
                                />
                              <select name="suggested-place" id="suggested-place"  className="form-select ms-3" onChange={handleSuggestedDestination}>
                                  <option value="0" defaultValue={true}>{sp.address_description }</option>
                                  {
                                    sp.sublocation.map((sl, index) =>
                                    {
                                      sl.address = sl.name;
                                      return <option value={JSON.stringify(sl)} key={index} >{sl.name}</option>
                                    }
                                    )
                                  }
                              </select>
                            </div>
                            </>)
                            }
                          }
                        )}
                        </>
                      
                      }
                      {favPlaces && favPlaces.requests.length && 
                        <>
                          <h6 className="fw-bold mb-4 mt-5 text-secondary">
                            Saved Places
                          </h6>
                       {favPlaces.requests.map((sp, index) =>
                        <div className="my-3 d-flex align-items-center" key={index} style={{cursor:"pointer"}} onClick={()=>selectedDestination(sp)}>
                        <img
                          src={sp.address_type == "Home" ?  "/assets/imgs/icons/ic_home.png": (sp.address_type == "Work" ? "/assets/imgs/icons/ic_work.png" :"/assets/imgs/icons/star.png")}
                          alt="search@2x"
                          width={30}
                        />
                        <div className="mx-2">
                          <h6 className="text-rove ms-2 fw-bold fs-6 mb-0">{sp.name}</h6> 
                          <p className="ms-2 mb-0">{sp.address}</p> 
                        </div>
                          
                      </div>
                        )}
                        </>
                      }
                      {recentPlaces &&
                        <>
                        <div className="d-flex justify-content-between">
                          <h6 className="fw-bold mb-4 mt-5 text-secondary" >
                            Recent Places
                          </h6>
                          <h6 className="fw-bold mb-4 mt-5" style={{cursor:"pointer"}} onClick={handleClearRecentPlaces}>
                            Clear All
                          </h6>
                        </div>
                    <div>
                    {recentPlaces.length && recentPlaces.map((pl, index) =>
                      <div
                        className="my-3 d-flex align-items-center"
                        key={index}
                        style={{cursor:"pointer"}}
                        onClick={() => selectedDestination(pl)}>
                        <img
                          src="/assets/imgs/icons/icn_recent.png"
                          alt="search@2x"
                          width={30}
                        />
                        <div className="mx-2">
                          <h6 className="text-rove ms-2 fw-bold fs-6 mb-0">{pl.address.split(",")[0]}</h6> 
                          <p className="ms-2 mb-0">{pl.address}</p> 
                        </div>
                      </div>
                      )
                    }
                    </div>
                    </> }

                  </div>}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>


        <Modal show={userBlockModal} onHide={handleUserBlockModalClose} centered>
          <Modal.Header closeButton>
            {/* <Modal.Title>Switch rider</Modal.Title>  */}
          </Modal.Header>
          <Modal.Body>
            <div className="my-3">
              <h4 className="text-center text-rove my-2">
                {userBlockMessage ? userBlockMessage : ""}
              </h4>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button
              className="btn btn-outline-danger"
              onClick={handleUserBlockModalClose}
              type="button"
              style={{ padding: "8px 24px" }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary mx-3"
              onClick={() => {
                navigate("/profile");
              }}
            >
              Ok
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={advancePaymentModal} onHide={advancePaymentModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Long Rides</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="my-3">
              <h4 className="text-rove my-2">
                Advance payment required to facilitate long rides.
              </h4>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button
              className="btn btn-outline-danger"
              onClick={advancePaymentModalClose}
              type="button"
              style={{ padding: "8px 24px" }}
            >
              Cancel
            </button>
            <button className="btn btn-primary mx-3" onClick={advancePayAccept}>
              Accept
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={addEmailModal} onHide={handleAddEmailModalClose} centered>
          {/*   <Modal.Header closeButton>
             <Modal.Title>Switch rider</Modal.Title> 
          </Modal.Header> */}
          <Modal.Body>
            <div className="my-3">
              <h4 className="text-center text-rove my-2">
                {addEmailShow
                  ? "Please add your email address."
                  : "Your profile is incomplete."}
              </h4>
              {addEmailShow ? (
                <p className="text-center mt-2 fw-bold">
                  To be able to send payment receipts,
                  <br />
                  we need your email address.
                </p>
              ) : (
                <p className="text-center mt-2 fw-bold">
                  Please, set up your profile.
                </p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button
              className="btn btn-outline-danger"
              onClick={handleAddEmailModalClose}
              type="button"
              style={{ padding: "8px 24px" }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary mx-3"
              onClick={() => {
                navigate("/profile");
              }}
            >
              Ok
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={addWalletModal} onHide={handleAddWalletModalClose} centered>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div
              className="row d-flex justify-content-between mb-3 p-3"
              style={{
                background: "rgb(233 192 196 / 49%)",
                borderRadius: "5px",
              }}
            >
              <div className="col-md-12 d-flex align-items-center w-50">
                <img
                  src="/assets/imgs/icons/wallet@2x.png"
                  alt="wallet@2x"
                  width={25}
                />
                <p className="d-inline-block ms-2 fw-bold fs-6 mb-0">Wallet</p>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-end w-50">
                <p className="fw-bold fs-6 mb-0">
                  KD {userProfileData && userProfileData.user_profile.wallet}
                </p>
              </div>
            </div>
            <div className="my-3">
              <h5 className="text-center fw-bold text-rove my-2">
                NO CREDIT CARD SAVED
              </h5>
              <p className="text-center mt-2 fw-bold">
                You can't book a ride, because you have no credit card saved on
                your account and your balance is less than <br />
                {enableFeaturesList ? (
                  <h5 className="fw-bold text-rove my-3">
                    KD {enableFeaturesList.defualt_wallet}
                  </h5>
                ) : (
                  <h5 className="fw-bold text-rove my-3">KD 5.000</h5>
                )}
              </p>
              <p className="text-center mt-2 fw-bold">
                You need to top up your balance or have a credit card saved on
                your account.
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <button
              className="btn btn-primary mx-3 w-100"
              onClick={() => {
                navigate("/wallet", { state: { topUp: walletDiff } });
              }}
            >
              Top up KD {walletDiff ? walletDiff : "5.000"}
            </button>
            <button
              className="btn btn-primary mx-3 w-100"
              onClick={() => {
                navigate("/wallet");
              }}
            >
              Add Credit Card
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={guestShow} onHide={handleGuestClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Guest rides</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <img src="/assets/imgs/img5.jpg" /> */}
            <img src="/assets/imgs/bg/img5.jpg" alt="" />
            <h4 className="model_title">Booking for someone else?</h4>
            <p>
              Now both you and your guest will get trip information in the app
              or by text.
            </p>
            <ul style={{ margin: "0", padding: "0" }}>
              <li>
                <div className="d-flex ">
                  <div className="mr-25">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M1 1v22h1l5-5h16V1H1Zm17 10H6V8h12v3Z"
                        fill="currentColor"
                      />
                      <title>Speech bubble</title>
                    </svg>
                  </div>
                  <div>
                    <p>Make pickup easy</p>
                    <p>
                      Guests can easily find the right vehicle or contact their
                      driver without you.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex ">
                  <div className="mr-25">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <title>Shield check</title>
                      <path
                        d="M12 1C6.68 1 2 3.04 2 3.04v11.9c0 4.84 5.57 7.15 10 9.06 4.4-1.89 10-4.2 10-9.06V3.04S17.5 1 12 1Zm-1 16.12-5.56-5.56 2.12-2.12L11 12.88l5.94-5.94 2.12 2.12L11 17.12Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div>
                    <p>Follow their ride</p>
                    <p>
                      You can follow your guest's ride from start to finish.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex ">
                  <div className="mr-25">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <title>Credit card</title>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1 4h22v4H1V4Zm0 7h22v9H1v-9Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div>
                    <p>Pay with your account</p>
                    <p>Pay for your guest's trip when you request the ride.</p>
                  </div>
                </div>
              </li>
            </ul>
          </Modal.Body>
        </Modal>
        <div
          className="modal fade"
          id="termsModal"
          aria-labelledby="termsModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content ">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="termsModalLabel">
                  Rove Reserve Terms and Conditions
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div>
              </div>
              <div className="modal-footer d-block">
                <button
                  type="button"
                  className="btn btn-primary btn-block w-100"
                  data-bs-dismiss="modal"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Dashboard;
