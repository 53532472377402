import React from "react";

import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
} from "react-google-maps";

const custom_style = require("../../custom_style.json");

const options = {
  mapTypeId: "terrain",
  disableDefaultUI: true,
  draggable: false,
  scrollwheel: false,
  zoomControl: false,
  styles: custom_style,
};

const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");
const {
  MarkerClusterer,
} = require("react-google-maps/lib/components/addons/MarkerClusterer");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
const {
  DrawingManager,
} = require("react-google-maps/lib/components/drawing/DrawingManager");

const google = window.google;

const MapWithAMarker = withGoogleMap(({pickupPosition,position,destinationPosition,routeDirections}) => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={pickupPosition ? pickupPosition : position}
      options={options}
    >
      <>
        {pickupPosition !== undefined &&
        pickupPosition.text !== undefined &&
        pickupPosition.text != "" ? (
          <MarkerWithLabel
            position={pickupPosition}
            cursor="hand"
            icon="/assets/imgs/icons/pickupmarker.png"
            labelAnchor={new google.maps.Point(-10, 40)}
            labelStyle={{
              backgroundColor: "#fff",
              fontSize: "10px",
              fontWeight: "700",
              padding: "5px",
            }}
          >
            <div>
              From {pickupPosition.text}&nbsp;&nbsp;
              <i className="fa fa-chevron-right"></i>
            </div>
          </MarkerWithLabel>
        ) : (
          ""
        )}
        {destinationPosition !== undefined &&
        destinationPosition.text !== undefined &&
        destinationPosition.text != "" ? (
          <MarkerWithLabel
            position={destinationPosition}
            cursor="hand"
            icon="/assets/imgs/icons/destination.png"
            labelAnchor={new google.maps.Point(-10, 40)}
            labelStyle={{
              backgroundColor: "#fff",
              fontSize: "10px",
              fontWeight: "700",
              padding: "5px",
            }}
          >
            <div>
              To {destinationPosition.text}&nbsp;&nbsp;
              <i className="fa fa-chevron-right"></i>
            </div>
          </MarkerWithLabel>
        ) : (
          ""
        )}
      </>
      {routeDirections && (
        <DirectionsRenderer
          directions={routeDirections}
          defaultOptions={{
            suppressMarkers: true,
            polylineOptions: { strokeColor: "#213d4a" },
          }}
        />
      )}

      {/* <TrafficLayer autoUpdate /> */}
    </GoogleMap>
));
  

export default MapWithAMarker