import React from "react";

const BusinessPage = () => {
  return (
    <main>
      <h1
        style={{
          minHeight: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Coming Soon...
      </h1>
    </main>
  );
};

export default BusinessPage;
